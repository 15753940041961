import styled from "styled-components";

export const ButtonCommon = styled.button<{ disabled?: boolean }>`
  width: 100%;
  display: flex;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1.5px solid var(--Neutral-900, #e84142);
  background: var(--Neutral-800, #e84142);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 3px 0px 0px rgba(186, 186, 186, 0.33) inset;
  color: ${({ disabled }) =>
    disabled ? "#fff" : "var(--Neutral-White, #fff)"};
  font-family: Syne;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  opacity: ${({ disabled }) => disabled && "0.6"};
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};
  &.light {
    background: #b32222;
    border: none;
    box-shadow: none;
  }
`;
