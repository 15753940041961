import { IDOLaunchContainer, IDOLaunchWrapper, IDOList } from "./styled";
import { useNavigate } from "react-router-dom";
import ItemIDO from "./itemIDO";
import { useEffect, useState } from "react";
import { AVAX_API } from "../../../services/api/indexV2";
import dayjs from "dayjs";
import _ from "lodash";

export const DATA_IDO = [
  {
    max_buy: 0,
    banner: "/assets/images/ido/img-private-r.png?v5",
    total_supply: 1000000,
    name: "Private Round",
    token_address: "EQB3cK4id8ETiweRUJ6uo6rHCncPFJOFN7IpCy2eWOCSjuWQ",
    description:
      "Kibble - your premier DeFi Hub on AVAX, provides all you need for the best trading experience and optimal advances on Avalanche C-Chain (AVAX)",
    join_address: null,
    token_symbol: "USDT",
    end_date: 1738586579,
    claim_at: null,
    id: 1000000,
    total_saled: 300000,
    total_raise: 300000,
    rate: 0.0325,
    contract_address: null,
    join_token_symbol: "KIB",
    start_date: 1738576579,
    vesting_info: "15% per month",
    is_onchain: false,
    color: "#00341D",
    background: "#34F497",
    project_name: "Kibble Exchange",
  },
  {
    total_supply: 1000000,
    token_address: "EQB3cK4id8ETiweRUJ6uo6rHCncPFJOFN7IpCy2eWOCSjuWQ",
    banner: "/assets/images/ido/img-whitelist-r.png?v5",
    rate: 0.0275,
    token_name: "Kibble Token",
    claim_at: null,
    name: "Whitelist Round",
    contract_address: null,
    token_decimal: 9,
    is_onchain: false,
    project_name: "Kibble Exchange",
    join_address: "EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs",
    start_date: 1742911200,
    is_required_whitelist: true,
    total_saled: 0,
    join_token_symbol: "USDT",
    end_date: null,
    max_buy: 20000,
    join_token_name: "Tether USD",
    vesting_info:
      "Users who purchase in this round can refund within 24 hours of opening the claim.",
    id: 1,
    min_buy: 0,
    join_token_decimal: 6,
    description:
      "Kibble is an innovative platform that seamlessly blends artificial intelligence (AI) with decentralized finance (DeFi), offering users an optimized trading experience.",
    total_raise: 50000,
    token_symbol: "KIB",
    color: "#251263",
    background: "#7C5FF3",
  },
  {
    total_supply: 1000000,
    token_address: "EQB3cK4id8ETiweRUJ6uo6rHCncPFJOFN7IpCy2eWOCSjuWQ",
    banner: "/assets/images/ido/img-fn-r.png?v5",
    rate: 0.0275,
    token_name: "Kibble Token",
    claim_at: null,
    name: "FCFS Round",
    contract_address: null,
    token_decimal: 9,
    is_onchain: false,
    project_name: "Kibble Exchange",
    join_address: "EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs",
    start_date: 1742911200,
    is_required_whitelist: false,
    total_saled: 0,
    join_token_symbol: "USDT",
    end_date: null,
    max_buy: 500,
    join_token_name: "Tether USD",
    vesting_info:
      "Users who purchase in this round can refund within 24 hours of opening the claim.",
    id: 2,
    min_buy: 0.01,
    join_token_decimal: 6,
    description:
      "Kibble is an innovative platform that seamlessly blends artificial intelligence (AI) with decentralized finance (DeFi), offering users an optimized trading experience.",
    total_raise: 150000,
    token_symbol: "KIB",
    color: "#3E1D0A",
    background: "#EAB325",
  },
];

const IDOLaunch = () => {
  const navigate = useNavigate();
  const [data, setData]: any = useState();
  const handleGetPool = async () => {
    try {
      const { data } = await AVAX_API.getPools();
      const dataSortByDate = _.orderBy(data, "start_date");
      const dataIDO = _.orderBy(dataSortByDate, "start_date").map(
        (item: any, index: number) => {
          return {
            ...item,
            name: dataSortByDate[index]?.round_name,
            describe: dataSortByDate[index]?.description,
            total_saled: dataSortByDate[index]?.total_saled_usd,
            total_raise: dataSortByDate[index]?.total_raise_usd,
            start_date: dayjs(dataSortByDate[index]?.start_date),
            rate: dataSortByDate[index]?.price_usd,
            end_date: dayjs(dataSortByDate[index]?.end_date),
            id: dataSortByDate[index]?.id,
            banner: dataSortByDate[index]?.image,
          };
        }
      );

      setData(dataIDO);
    } catch (error) {
      console.log("view detai", error);
    }
  };
  const handleClaimReward = async (data: any) => {
    try {
      navigate(`/launchpad/avax/${data.id}`);
    } catch (error) {
      console.log("view detai", error);
    }
  };

  useEffect(() => {
    handleGetPool();
    const intervalGetPool = setInterval(() => {
      handleGetPool();
    }, 5000);
    return () => {
      clearInterval(intervalGetPool);
    };
  }, []);

  return (
    <IDOLaunchContainer>
      <IDOLaunchWrapper>
        <IDOList>
          {data?.map((item: any, index: any) => {
            return (
              <ItemIDO
                key={index}
                data={item}
                onClaimReward={() => {
                  handleClaimReward(item);
                }}
              />
            );
          })}
        </IDOList>
      </IDOLaunchWrapper>
    </IDOLaunchContainer>
  );
};

export default IDOLaunch;
