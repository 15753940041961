import { useContext, useEffect } from "react";
import { useChainId, useConnect } from "wagmi";
import { ContextProviderWrapper } from "../components/Context";

export const useConnectWallet = () => {
  const { connect, connectors } = useConnect();
  const chainId = useChainId();
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const onConnectWallet = async () => {
    try {
      let connector: any = connectors.find(
        (obj) =>
          (isMobile && obj.id === "walletConnect") ||
          (!isMobile && obj.id === "metaMask")
      );
      await connect({
        connector,
        chainId,
      });
    } catch (error) {
      
      console.log("onConnectWallet err", error);
      
    }
  };

  return { onConnectWallet };
};
