import TitleCommon from "../../components/Common/Title";
import {
  SwapBenifitTag,
  SwapBg,
  SwapChart,
  SwapChartFilter,
  SwapChartHeader,
  SwapChartTitle,
  SwapContainer,
  SwapExtension,
  SwapHeader,
  SwapInner,
  SwapTop,
  SwapWrapper,
  SwapWrapperModal,
} from "./styled";
import chart from "../../assets/Dashboard/Swap/chart.svg";
import doc from "../../assets/Dashboard/Swap/doc.svg";
import doc_active from "../../assets/Dashboard/Swap/doc_active.svg";
import doc_active_light from "../../assets/Dashboard/Swap/doc_active_light.svg";
import doc_light from "../../assets/Dashboard/Swap/doc_light.svg";
import settings from "../../assets/Dashboard/Swap/settings.svg";
import chart_active from "../../assets/Dashboard/Swap/chart_active.svg";
import settings_active from "../../assets/Dashboard/Swap/settings_active.svg";
import chart_light from "../../assets/Dashboard/Swap/chart_light.svg";
import settings_light from "../../assets/Dashboard/Swap/settings_light.svg";
import { useTonConnectUI } from "@tonconnect/ui-react";
import ModalOverlay from "../../components/Common/ModalOverlay";
import ChooseToken from "./ChooseToken";
import { useContext, useEffect, useState } from "react";
import SettingSwap from "./Setting";
import { useSearchParams } from "react-router-dom";
import { useSimulateSwap } from "../../hooks/useSimulateSwap";
import { Coins } from "ton3-core";
import { useForm } from "react-hook-form";
import { usePairBalances } from "../../hooks/usePairBalances";
import { fieldNormalizer } from "../../utils/fieldNormalizer";
import default_token_image from "../../assets/Dashboard/Common/default-token-image.png";
import ConfirmSwap from "./Confirm";
import change_icon from "../../assets/Dashboard/Swap/change_icon.png";
import change_icon_light from "../../assets/Dashboard/Swap/change_icon_light.png";
import SwapChartTable from "./Chart";
import SwapModal from "./Tabs/Modal";
import "./Chart/loading.css";
import { Loader } from "../../components/Loader";
import History from "./History";
import { ContextProviderWrapper } from "../../components/Context";
import { debounce } from "lodash";
import { TabSelect } from "../Liquidity/styled";
import HistoryList from "./History/List";
import { useSelector } from "react-redux";
import { useAppKitAccount } from "@reown/appkit/react";
import Value from "../../components/Value";

const TON_ADDRESS: any = process.env.REACT_APP_TON_ADDRESS;
const KIBBLE_ADDRESS: any = process.env.REACT_APP_USDC_ADDRESS;

export type SwapAction = "offer" | "ask";

const Swap = () => {
  const [isShowHistory, setIsShowHistory] = useState(false);
  const { theme, isMobile } = useContext(ContextProviderWrapper)!;
  const [searchParams] = useSearchParams();
  let from = searchParams.get("from");
  let to = searchParams.get("to");

  const { listTokens }: any = useSelector((state: any) => state.pool);

  const [tonConnectUI] = useTonConnectUI();

  const [fromAssetKey, setFromAssetKey] = useState(from || TON_ADDRESS);

  const [toAssetKey, setToAssetKey] = useState(to || KIBBLE_ADDRESS);
  if (from === to || !from || !to) {
    from = TON_ADDRESS;
    to = KIBBLE_ADDRESS;
  }

  useEffect(() => {
    if (
      !tonConnectUI.connector.connected &&
      (fromAsset === undefined || toAsset === undefined)
    ) {
      setFromAssetKey(TON_ADDRESS);
      setToAssetKey(KIBBLE_ADDRESS);
    }
  }, [tonConnectUI.connector.connected]);

  const [fromAmount, setFromAmount] = useState(0);
  const [toAmount, setToAmount] = useState(0);
  const [slippageTolerance, setSlippageTolerance] = useState(0.5);

  useEffect(() => {
    from = fromAssetKey;
    to = toAssetKey;
    const url = new URL(window.location.toString());
    url.searchParams.set("from", from || TON_ADDRESS);
    url.searchParams.set("to", to || KIBBLE_ADDRESS);
    window.history.pushState({}, "", url);
  }, [fromAssetKey, toAssetKey]);

  const [openFromModal, setOpenFromModal] = useState(false);
  const [openToModal, setOpenToModal] = useState(false);

  // Count decimal
  const countDecimals = (value: any) => {
    const valueAsString = String(value);
    const splitValue = valueAsString.split(".");
    return splitValue.length > 1 ? splitValue[1].length : 0;
  };

  const handleSendValue = (e: any) => {
    const decimalsCount = countDecimals(e.target.value);
    fieldNormalizer(
      "fromAmount",
      e.target.value || "0",
      setValue,
      fromAsset?.decimals
    );
    if (fromAsset && decimalsCount <= fromAsset?.decimals) {
      debouncedUpdateFromAmount(parseFloat(e.target.value));
    }
  };

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { setValue, control } = useForm({ mode: "onChange" });

  const handleReceiveValue = (e: any) => {
    const decimalsCount = countDecimals(e.target.value);
    fieldNormalizer(
      "toAmount",
      e.target.value || "0",
      setValue,
      toAsset?.decimals
    );
    if (toAsset && decimalsCount <= toAsset?.decimals) {
      debouncedUpdateToAmount(parseFloat(e.target.value));
    }
  };

  const fromAsset =
    listTokens?.length > 0 &&
    listTokens?.find((item: any) => item.address === fromAssetKey);
  const toAsset =
    listTokens?.length > 0 &&
    listTokens?.find((item: any) => item.address === toAssetKey);
  const { address } = useAppKitAccount();
  const [isRefreshBalance, setIsRefreshBalance] = useState(false);
  const { token0Balance: fromAssetBalance, token1Balance: toAssetBalance } =
    usePairBalances({
      token0Address: fromAssetKey,
      token1Address: toAssetKey,
      isLoading: isRefreshBalance,
      address,
    });

  const [swapAction, setSwapAction] = useState<SwapAction>("offer");

  const fromAmountNano =
    fromAsset && fromAmount
      ? new Coins(Number(fromAmount).toFixed(fromAsset?.decimals), {
          decimals: fromAsset?.decimals,
        }).toNano()
      : "0";

  const toAmountNano =
    toAsset && toAmount
      ? new Coins(Number(toAmount).toFixed(toAsset?.decimals), {
          decimals: toAsset?.decimals,
        }).toNano()
      : "0";

  const simulateState = useSimulateSwap({
    swapAction: swapAction,
    srcToken: fromAsset?.address,
    srcDecimals: fromAsset?.decimals,
    destToken: toAsset?.address,
    destDecimals: toAsset?.decimals,
    amount:
      Number(fromAmountNano) !== 0 && Number(toAmountNano) === 0
        ? fromAmountNano
        : Number(fromAmountNano) === 0 && Number(toAmountNano) !== 0
        ? toAmountNano
        : 0,
    side: Number(toAmountNano) !== 0 ? "BUY" : "SELL",
    network: process.env.REACT_APP_CHAIN_ID,
  });

  const realPrice = simulateState.swapRate > 0 ? 1 / simulateState.swapRate : 0;

  const minAskUnits = Number(
    Coins.fromNano(simulateState.minAskUnits, toAsset?.decimals)
  );
  const offerUnits = Number(
    Coins.fromNano(simulateState.offerUnits, fromAsset?.decimals)
  );
  const rate = Number(minAskUnits) / Number(offerUnits) || 0;

  const minReceived = toAsset
    ? Coins.fromNano(simulateState.minAskUnits, toAsset?.decimals)
    : new Coins(0);

  const updateFromAmount = (amount: number) => {
    setToAmount(0);
    setSwapAction("offer");
    const valuePer: any = !isNaN(amount)
      ? Number(amount + Number.EPSILON).toFixed(2)
      : 0;
    setFromAmount(
      valuePer > amount ? Number(Number(valuePer) - 0.01).toFixed(2) : valuePer
    );
  };

  const updateToAmount = (amount: number) => {
    setFromAmount(0);
    const valuePer: any = !isNaN(amount)
      ? Number(amount + Number.EPSILON).toFixed(2)
      : 0;
    setSwapAction("ask");
    setToAmount(
      valuePer > amount ? Number(Number(valuePer) - 0.01).toFixed(2) : valuePer
    );
  };

  const debouncedUpdateToAmount = debounce(updateToAmount, 500);

  const debouncedUpdateFromAmount = debounce(updateFromAmount, 500);

  useEffect(() => {
    if (!toAsset || !fromAsset || simulateState?.timestamp === 0) {
      return;
    }
    if (swapAction === "offer") {
      const amount = parseFloat(
        Coins.fromNano(simulateState?.askUnits, toAsset?.decimals).toString()
      );
      const valuePer: any = Number(Number(amount) + Number.EPSILON).toFixed(4);
      setToAmount(valuePer ? valuePer : 0);
    } else {
      const amount = parseFloat(
        Coins.fromNano(
          simulateState?.offerUnits,
          fromAsset?.decimals
        ).toString()
      );
      const valuePer: any = Number(Number(amount) + Number.EPSILON).toFixed(4);

      setFromAmount(valuePer ? valuePer : 0);
    }
  }, [simulateState?.timestamp]);

  useEffect(() => {
    setValue(
      "toAmount",
      new Coins(toAmount, { decimals: toAsset?.decimals }).toString()
    );
  }, [toAmount]);

  useEffect(() => {
    setValue(
      "fromAmount",
      new Coins(fromAmount, { decimals: fromAsset?.decimals }).toString()
    );
  }, [fromAmount]);

  const swapFromTo = () => {
    setFromAssetKey(toAssetKey);
    setToAssetKey(fromAssetKey);
    setFromAmount(0);
    setToAmount(0);
    setSwapAction((prev) => (prev === "offer" ? "ask" : "offer"));
  };

  useEffect(() => {
    if (simulateState.priceImpact > 30 && !showDetail) {
      setShowDetail(true);
    }
  }, [simulateState.priceImpact]);

  useEffect(() => {
    if (!openConfirmModal) {
      setTextModalTransaction("Confirm Swap");
      setFromAmount(0);
      setToAmount(0);
    }
    if (openConfirmModal) {
      setIsRefreshBalance(false);
    }
  }, [openConfirmModal]);

  const [activeFilter, setActiveFilter] = useState(1);
  const [showDetail, setShowDetail] = useState(false);
  const [timeTarget, setTimeTarget] = useState(0);

  const handleResetValueFrom = (e: any) => {
    let { value } = e.target;
    if (value === 0 || value === "0") {
      fieldNormalizer("fromAmount", "", setValue, fromAsset?.decimals);
      debouncedUpdateFromAmount(parseFloat(e.target.value));
    }
  };

  const handleResetValueTo = (e: any) => {
    let { value } = e.target;
    if (value === 0 || value === "0") {
      fieldNormalizer("toAmount", "", setValue, fromAsset?.decimals);
      debouncedUpdateFromAmount(parseFloat(e.target.value));
    }
  };

  // handle switch tabs
  const [switchState, setSwitchState] = useState(false);

  const handleSwitchTabs = () => {
    switch (activeFilter) {
      case 1:
        return (
          // TODO
          <SwapModal
            fromAssetKey={fromAssetKey}
            fromAssetBalance={fromAssetBalance}
            listTokens={listTokens}
            fromAsset={fromAsset}
            debouncedUpdateFromAmount={debouncedUpdateFromAmount}
            fromAmount={fromAmount}
            setOpenFromModal={setOpenFromModal}
            control={control}
            handleSendValue={handleSendValue}
            swapFromTo={swapFromTo}
            toAssetKey={toAssetKey}
            toAssetBalance={toAssetBalance}
            toAsset={toAsset}
            debouncedUpdateToAmount={debouncedUpdateToAmount}
            toAmount={toAmount}
            setOpenToModal={setOpenToModal}
            handleReceiveValue={handleReceiveValue}
            simulateState={simulateState}
            setOpenConfirmModal={setOpenConfirmModal}
            realPrice={rate}
            slippageTolerance={slippageTolerance}
            minReceived={minReceived}
            setShowDetail={setShowDetail}
            showDetail={showDetail}
            handleResetValueFrom={handleResetValueFrom}
            handleResetValueTo={handleResetValueTo}
          />
        );
      // case 2:
      //   return <SwapTopLevel setActiveFilter={setActiveFilter} />;
      case 2:
        return (
          <SettingSwap
            slippageTolerance={slippageTolerance}
            setSlippageTolerance={setSlippageTolerance}
            setActiveFilter={setActiveFilter}
            setSwitchState={setSwitchState}
            switchState={switchState}
            // setActiveTab={setActiveTab}
          />
        );
      default:
        return (
          // TODO
          <SwapModal
            fromAssetKey={fromAssetKey}
            fromAssetBalance={fromAssetBalance}
            listTokens={listTokens}
            fromAsset={fromAsset}
            debouncedUpdateFromAmount={debouncedUpdateFromAmount}
            fromAmount={fromAmount}
            setOpenFromModal={setOpenFromModal}
            control={control}
            handleSendValue={handleSendValue}
            swapFromTo={swapFromTo}
            toAssetKey={toAssetKey}
            toAssetBalance={toAssetBalance}
            toAsset={toAsset}
            debouncedUpdateToAmount={debouncedUpdateToAmount}
            toAmount={toAmount}
            setOpenToModal={setOpenToModal}
            handleReceiveValue={handleReceiveValue}
            simulateState={simulateState}
            setOpenConfirmModal={setOpenConfirmModal}
            realPrice={rate}
            slippageTolerance={slippageTolerance}
            minReceived={minReceived}
            setShowDetail={setShowDetail}
            showDetail={showDetail}
            handleResetValueFrom={handleResetValueFrom}
            handleResetValueTo={handleResetValueTo}
            checkingToken={true}
          />
        );
    }
  };

  const [valueChanges, setValueChanges] = useState(0);
  const [isPending, setIsPending] = useState<any>(false);

  useEffect(() => {
    if (isPending) {
      setTimeout(() => {
        setIsPending(false);
      }, 4000);
    }
  }, [isPending]);

  const [textModalTransaction, setTextModalTransaction] =
    useState("Confirm Swap");

  const [openChart, setOpenChart] = useState(false);
  const [listFavorite, setListFavorite] = useState<any>([]);

  const benifitTags = [
    // {
    //   title: "No Fee",
    //   color: theme === "light" ? "#01B85F" : "#0FF586",
    // },
    {
      title: "Lighting fast",
      color: theme === "light" ? "#E6A519" : "#F0CC54",
    },
    {
      title: "Best rate",
      color: theme === "light" ? "#DC622E" : "#DC622E",
    },
  ];

  const listExtensions = [
    {
      id: 3,
      icon:
        theme === "dark"
          ? "/assets/images/swap/doc.svg"
          : "/assets/images/swap/doc_light.svg",
      activeIcon:
        theme === "dark"
          ? "/assets/images/swap/doc.svg"
          : "/assets/images/swap/doc_light.svg",
    },
    {
      id: 1,
      icon:
        theme === "dark"
          ? "/assets/images/swap/chart.svg"
          : "/assets/images/swap/chart_light.svg",
      activeIcon: "/assets/images/swap/chart_active.svg",
    },
    {
      id: 2,
      icon:
        theme === "dark"
          ? "/assets/images/swap/settings.svg"
          : "/assets/images/swap/settings_light.svg",
      activeIcon: "/assets/images/swap/settings_active.svg",
    },
  ];

  const [activeTab, setActiveTab] = useState(1);

  const handleSwitchTabsMobile = () => {
    switch (activeTab) {
      case 1:
        return (
          <>
            {
              <SwapWrapperModal>
                {!fromAsset || !toAsset ? (
                  <>
                    <SwapWrapper className={theme}>
                      {!isMobile && (
                        <SwapHeader>
                          <TitleCommon text={"SWAP"} />
                        </SwapHeader>
                      )}
                      <Loader />
                    </SwapWrapper>
                  </>
                ) : (
                  // TODO
                  <SwapWrapper className={theme}>
                    {/* Content */}
                    <SwapModal
                      fromAssetKey={fromAssetKey}
                      fromAssetBalance={fromAssetBalance}
                      fromAsset={fromAsset}
                      debouncedUpdateFromAmount={debouncedUpdateFromAmount}
                      fromAmount={fromAmount}
                      setOpenFromModal={setOpenFromModal}
                      control={control}
                      handleSendValue={handleSendValue}
                      swapFromTo={swapFromTo}
                      toAssetKey={toAssetKey}
                      toAssetBalance={toAssetBalance}
                      toAsset={toAsset}
                      debouncedUpdateToAmount={debouncedUpdateToAmount}
                      toAmount={toAmount}
                      setOpenToModal={setOpenToModal}
                      handleReceiveValue={handleReceiveValue}
                      simulateState={simulateState}
                      setOpenConfirmModal={setOpenConfirmModal}
                      realPrice={rate}
                      slippageTolerance={slippageTolerance}
                      minReceived={minReceived}
                      setShowDetail={setShowDetail}
                      showDetail={showDetail}
                      handleResetValueFrom={handleResetValueFrom}
                      handleResetValueTo={handleResetValueTo}
                      checkingToken={true}
                    />
                  </SwapWrapper>
                )}
              </SwapWrapperModal>
            }
          </>
        );
      case 3:
        return (
          <SwapWrapperModal>
            <SwapWrapper className={theme}>
              <SettingSwap
                slippageTolerance={slippageTolerance}
                setSlippageTolerance={setSlippageTolerance}
                setActiveFilter={setActiveFilter}
                setSwitchState={setSwitchState}
                switchState={switchState}
                setActiveTab={setActiveTab}
              />
            </SwapWrapper>
          </SwapWrapperModal>
        );
      case 4:
        return (
          <SwapWrapperModal>
            <SwapWrapper
              style={{
                height: "calc(100vh - 450px)",
                overflowY: "auto",
              }}
              className={theme}
            >
              <HistoryList />
            </SwapWrapper>
          </SwapWrapperModal>
        );
      default:
        return (
          <>
            {openChart && (
              <SwapWrapperModal>
                {!fromAsset || !toAsset ? (
                  <>
                    <SwapWrapper className={theme}>
                      {!isMobile && (
                        <SwapHeader>
                          <TitleCommon text={"SWAP"} />
                        </SwapHeader>
                      )}
                      <Loader />
                    </SwapWrapper>
                  </>
                ) : (
                  <SwapWrapper className={theme}>
                    {/* Content */}
                    {/* TODO */}
                    <SwapModal
                      fromAssetKey={fromAssetKey}
                      fromAssetBalance={fromAssetBalance}
                      fromAsset={fromAsset}
                      debouncedUpdateFromAmount={debouncedUpdateFromAmount}
                      fromAmount={fromAmount}
                      setOpenFromModal={setOpenFromModal}
                      control={control}
                      handleSendValue={handleSendValue}
                      swapFromTo={swapFromTo}
                      toAssetKey={toAssetKey}
                      toAssetBalance={toAssetBalance}
                      toAsset={toAsset}
                      debouncedUpdateToAmount={debouncedUpdateToAmount}
                      toAmount={toAmount}
                      setOpenToModal={setOpenToModal}
                      handleReceiveValue={handleReceiveValue}
                      simulateState={simulateState}
                      setOpenConfirmModal={setOpenConfirmModal}
                      realPrice={rate}
                      slippageTolerance={slippageTolerance}
                      minReceived={minReceived}
                      setShowDetail={setShowDetail}
                      showDetail={showDetail}
                      handleResetValueFrom={handleResetValueFrom}
                      handleResetValueTo={handleResetValueTo}
                    />
                  </SwapWrapper>
                )}
              </SwapWrapperModal>
            )}
          </>
        );
    }
  };
  return (
    <SwapBg>
      {isShowHistory && <History onClose={setIsShowHistory} />}
      <SwapContainer>
        <SwapTop>
          <TitleCommon text={"Kibble Swap"} />
          <SwapBenifitTag className={theme}>
            <div>
              <h3>Tradable Tokens</h3>
              <p>
                <Value unitafter="+" value={1000} decimals={0} />
              </p>
            </div>
            <div>
              <h3>Weekly volume</h3>
              <p>$3.86M</p>
            </div>
            <div>
              <h3>Last Week Transactions</h3>
              <p>
                <Value unitafter="Tx" value={3826} decimals={0} />
              </p>
            </div>
            <div>
              <h3>Last Week's Traffic</h3>
              <p>
                <Value value={35829} decimals={0} />
              </p>
            </div>
            <div>
              <h3>Monthly Active Wallet</h3>
              <p>
                <Value value={5920} decimals={0} />
              </p>
            </div>
            <div>
              <h3>Monthly user growth</h3>
              <p>
                <Value unitafter="%" value={22} decimals={0} />
              </p>
            </div>
            <br />
          </SwapBenifitTag>
        </SwapTop>
        {isMobile && (
          <>
            <TabSelect className={theme} active={activeTab}>
              <button
                onClick={() => {
                  setActiveTab(1);
                  setOpenChart(true);
                }}
              >
                Swap
              </button>
              <button
                onClick={() => {
                  setActiveTab(2);
                  setOpenChart(false);
                }}
              >
                Statistics
              </button>
              <button
                onClick={() => {
                  setActiveTab(3);
                  setOpenChart(true);
                }}
              >
                Settings
              </button>
              <button
                onClick={() => {
                  setActiveTab(4);
                  setOpenChart(true);
                }}
              >
                History
              </button>
            </TabSelect>
          </>
        )}
        <SwapInner className={openChart ? `active` : ``}>
          <SwapChart
            className={
              openChart && !isMobile
                ? `active ${theme}`
                : isMobile && activeTab !== 2
                ? `active ${theme}`
                : `${theme}`
            }
          >
            <SwapChartHeader>
              <SwapChartTitle className={theme} isDown={valueChanges < 0}>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    swapFromTo();
                  }}
                  src={theme === "light" ? change_icon_light : change_icon}
                  alt="icon"
                />
                <div>
                  <figure>
                    <img
                      src={
                        fromAsset?.img ? fromAsset?.img : default_token_image
                      }
                      alt="icon"
                      onError={(e) =>
                        (e.currentTarget.src = default_token_image)
                      }
                    />
                  </figure>
                  <figure>
                    <img
                      src={toAsset?.img ? toAsset?.img : default_token_image}
                      alt="icon"
                      onError={(e) =>
                        (e.currentTarget.src = default_token_image)
                      }
                    />
                  </figure>
                </div>
                <div>
                  <p>{fromAsset?.symbol ? fromAsset?.symbol : "??"} / </p>
                  <p>{toAsset?.symbol ? toAsset?.symbol : "??"}</p>
                  <span>
                    {valueChanges > 0 ? "+" : ""}
                    {valueChanges?.toFixed(2)}%
                  </span>
                </div>
              </SwapChartTitle>
              <SwapChartFilter className={theme} ispending={isPending}>
                {chartFilter.map((item, index) => {
                  return (
                    <li
                      onClick={() => {
                        if (!isPending) {
                          setTimeTarget(index);
                          setIsPending(true);
                        }
                      }}
                      className={timeTarget === index ? "active" : ""}
                      key={index}
                    >
                      {item.text}
                    </li>
                  );
                })}
              </SwapChartFilter>
            </SwapChartHeader>
            {/* Chart */}
            {/* TODO */}
            <SwapChartTable
              setValueChanges={setValueChanges}
              valueChanges={valueChanges}
              fromSymbol={
                fromAsset?.symbol ||
                "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
              }
              toSymbol={
                toAsset?.symbol || "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E"
              }
              fromAssetKey={fromAssetKey}
              toAssetKey={toAssetKey}
              timeTarget={timeTarget}
            />
          </SwapChart>
          {isMobile && handleSwitchTabsMobile()}
          {!isMobile && (
            <SwapWrapperModal
              style={{
                height:
                  activeFilter === 1 || activeFilter === 2 || activeFilter === 3
                    ? "auto"
                    : "405px",
              }}
            >
              {!fromAsset || !toAsset ? (
                <>
                  <SwapWrapper className={theme}>
                    <SwapHeader>
                      <TitleCommon text={"SWAP"} />
                    </SwapHeader>
                    <Loader />
                  </SwapWrapper>
                </>
              ) : (
                <>
                  <SwapWrapper className={theme}>
                    <SwapHeader>
                      <TitleCommon
                        text={activeFilter === 1 ? "SWAP" : "SWAP SETTINGS"}
                      />
                      <SwapExtension className={theme}>
                        {listExtensions.map((item, index) => {
                          return (
                            <li
                              // style={{
                              //   pointerEvents: item.id === 1 ? "none" : "auto",
                              // }}
                              onClick={() => {
                                if (item.id !== 3) {
                                  setActiveFilter(item.id);
                                }
                                if (item.id === 1) {
                                  setOpenChart(!openChart);
                                }
                                if (item.id === 3) {
                                  setIsShowHistory(true);
                                }
                              }}
                              key={index}
                            >
                              <img
                                src={
                                  activeFilter === item.id
                                    ? item.icon
                                    : item.activeIcon
                                }
                                alt="icon"
                              />
                            </li>
                          );
                        })}
                      </SwapExtension>
                    </SwapHeader>
                    {!isMobile && handleSwitchTabs()}
                  </SwapWrapper>
                </>
              )}
            </SwapWrapperModal>
          )}
        </SwapInner>
      </SwapContainer>
      <ModalOverlay
        component={
          <ChooseToken
            isFromModal={openFromModal}
            otherCurrentAssetKey={fromAssetKey}
            otherToAssetKey={toAssetKey}
            setCurrentAsset={setFromAssetKey}
            setOtherCurrentAsset={setToAssetKey}
            setOpenFromModal={setOpenFromModal}
            // set Amount
            setAmountFrom={setFromAmount}
            setAmountTo={setToAmount}
            // favorite
            setListFavorite={setListFavorite}
            listFavorite={listFavorite}
            //Checking token
            isModal={1}
          />
        }
        open={openFromModal}
        setOpen={setOpenFromModal}
        title={"Select a token"}
        width="400px"
      />
      <ModalOverlay
        component={
          <ChooseToken
            isFromModal={true}
            otherCurrentAssetKey={toAssetKey}
            otherToAssetKey={fromAssetKey}
            setCurrentAsset={setToAssetKey}
            setOpenFromModal={setOpenToModal}
            // set Amount
            setAmountFrom={setFromAmount}
            setAmountTo={setToAmount}
            // favorite
            setListFavorite={setListFavorite}
            listFavorite={listFavorite}
            isModal={2}
          />
        }
        open={openToModal}
        setOpen={setOpenToModal}
        title={"Select a token"}
        width="400px"
      />
      <ModalOverlay
        component={
          <ConfirmSwap
            fromAsset={fromAsset}
            toAsset={toAsset}
            fromAmount={fromAmount}
            toAmount={toAmount}
            minReceived={minReceived}
            slippageTolerance={slippageTolerance}
            setOpenConfirmModal={setOpenConfirmModal}
            openConfirmModal={openConfirmModal}
            simulateState={simulateState}
            realPrice={realPrice}
            setFromAmount={setFromAmount}
            setToAmount={setToAmount}
            setTextModalTransaction={setTextModalTransaction}
            updateFromAmount={updateFromAmount}
            updateToAmount={updateToAmount}
            onRefresh={() => {
              setIsRefreshBalance(true);
            }}
          />
        }
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        title={textModalTransaction}
        width="500px"
      />
    </SwapBg>
  );
};

const chartFilter = [
  {
    id: 1,
    value: 86400000,
    text: "24H",
  },
];

const ListVolume = [
  {
    value: 1000,
    text: "Tradable Tokens",
  },
  {
    value: "$3.52M",
    text: "Weekly volume",
  },
  {
    value: "4539 tx",
    text: "Last Week Transactions",
  },
  {
    value: "19.36k",
    text: "Last Week's Traffic",
  },
  {
    value: "5260",
    text: "Monthly Active Wallet",
  },
  {
    value: "12%",
    text: "Monthly user growth",
  },
];

export default Swap;
