import styled from "styled-components";
import { ProgressBar, ProgressTitles, TotalRai } from "./styled";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Value from "../../../components/Value";
import dayjs from "dayjs";
import { formatUnitTime } from "../../../utils/formatUnitTime";
import { useAppKitAccount, useAppKit } from "@reown/appkit/react";
import { ContextProviderWrapper } from "../../../components/Context";
import web3 from "web3";
import abiContract from "../../../components/contract/ABI/abijoinpool.json";
import abiToken from "../../../components/contract/ABI/abitoken.json";
import { convertFromWei, convertToWei } from "../../../utils/convertNumber";
import { ethers } from "ethers";
import getTokenInfo from "../../../utils/checkInfoByAccount";
import { useDebouncedCallback } from "use-debounce";
import {
  BlockRight,
  BlockRightCard,
  BlockRightRow,
  BlockRightStatus,
  BlockSteps,
  BlockStepsTitle,
  BlockStepsTitleItem,
  BlockTableRow,
} from "../Detail/styled";
import { FairBox, FairProgress, FairProgressBar } from "../FAIR/styled";
import { AVAX_API } from "../../../services/api/indexV2";
import { useSelector } from "react-redux";
import { convertFixed } from "../../../utils/convertFormatNumber";
import Loading from "../../../components/Loading";
import { ButtonCommon } from "../../../components/Common/styled";
import { useAccount, useChainId, useSignMessage } from "wagmi";
import { useDispatch } from "../../../store/store";
import { getDataTokenLogin } from "../../../store/features/poolsSlice";
import ModalOverlay from "../../../components/Common/ModalOverlay";
import ClaimModal from "./claim";
import { modalAppkit } from "../../../App";
import { avalanche } from "@reown/appkit/networks";

const STEPS = [
  {
    title: "Waiting for pool start",
    description: "No one can purchase",
    key: null,
  },
  {
    title: "Pool Start",
    description: "TBA (UTC)",
    key: "start_date",
  },
  {
    title: "Pool Ended",
    description: "TBA (UTC)",
    key: "end_date",
  },
  {
    title: "Claim tokens",
    description: "TBA (UTC)",
    key: "claim_available_at",
  },
];

declare const window: Window & typeof globalThis & { ethereum: any };
const InputBox = ({ poolInfo, onRefresh, balanceInfo }: any) => {
  const Web3 = new web3(window.ethereum);
  const { address }: any = useAppKitAccount();
  const { chainId } = useAccount();
  const { id } = useParams();
  const [allowance, setAllowance] = useState<any>(0);
  const [decimalAllow, setDecimalAllow] = useState<any>(18);
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSecond] = useState(0);
  const [balanceToken, setBalanceToken] = useState(0);
  const [stepActive, setStepActive] = useState(1);
  const [isShowSchedule, setIsShowSchedule] = useState(false);
  const [isUseInWL, setIsUseInWL] = useState(true);
  const [dataSchedule, setDataSchedule] = useState(null);
  const { theme, isMobile } = useContext(ContextProviderWrapper)!;
  const { open } = useAppKit();
  const [reCallFunc, setReCallFunc] = useState(false);
  const { listTokens, tokenLogin }: any = useSelector(
    (state: any) => state.pool
  );
  const dispatch = useDispatch();

  const getUSDTBalance = async () => {
    try {
      const tokenContract = new Web3.eth.Contract(
        abiToken,
        process.env.REACT_APP_USDC_ADDRESS
      );
      listTokens.find(
        (item: any) => item.address === process.env.REACT_APP_USDC_ADDRESS
      );
      const balance: any = await tokenContract.methods
        .balanceOf(address)
        .call();
      const tokenDecimals: any = await tokenContract.methods.decimals().call();

      const formattedBalance: any = convertFromWei(balance, tokenDecimals);
      setBalanceToken(formattedBalance);
    } catch (error) {
      console.error("Error fetching USDT token balance:", error);
    }
  };

  const handleBuyIDO = async () => {
    try {
      if (isLoading || !isUseInWL || !poolInfo) return;
      const token = localStorage.getItem("TOKEN");
      if (!token) {
        dispatch(getDataTokenLogin("SIGN"));
        setReCallFunc(true);
        return;
      }
      setIsLoading(true);
      const contract = new Web3.eth.Contract(abiContract, poolInfo?.contract);
      const gasPrice = await Web3.eth.getGasPrice();
      const valueToWei: any = convertToWei(amount, decimalAllow);
      const gasEstimate = await contract.methods
        .joinPool(valueToWei)
        .estimateGas({
          from: address,
          gasPrice: gasPrice.toString(),
        });
      await contract.methods
        .joinPool(valueToWei)
        .send({
          from: address,
          gasPrice: gasPrice.toString(),
          gas: gasEstimate.toString(),
        })
        .then(async (res: any) => {
          let indexCreate = 0;
          const intervalCreateTx = setInterval(async () => {
            indexCreate++;
            try {
              await AVAX_API.createTxJoinPool({
                round_id: id,
                tx_hash: res?.transactionHash,
              });
              clearInterval(intervalCreateTx);
              await onRefresh();
              await getUSDTBalance();
              toast.success("Buy Successfully");
              setIsLoading(false);
              setAmount(0);
            } catch (error: any) {
              if (indexCreate === 5) {
                clearInterval(intervalCreateTx);
                toast.error(error.message || "Buy Failed");
                setIsLoading(false);
                setAmount(0);
              }
            }
          }, 2000);
        })
        .catch((err: any) => {
          toast.error(err.message || "Send Transaction Failed");
          setIsLoading(false);
        });
    } catch (error: any) {
      toast.error(error.message || "Buy Failed");
      setIsLoading(false);
    }
  };

  const approveBuy = async () => {
    try {
      if (isLoading || !poolInfo) return;
      setIsLoading(true);
      const gasPrice = await Web3.eth.getGasPrice();
      const contract = new Web3.eth.Contract(
        abiToken,
        process.env.REACT_APP_USDC_ADDRESS
      );
      await contract.methods
        .approve(poolInfo?.contract, ethers.MaxUint256.toString())
        .send({ from: address, gasPrice: gasPrice.toString() })
        .then(async () => {
          await getAllowance();
          setIsLoading(false);
        })
        .catch((err: any) => {
          toast.error(err.message || "Failed");
          setIsLoading(false);
        });
    } catch (error: any) {
      toast.error(error.message || "Failed");
      setIsLoading(false);
    }
  };

  const getAllowance = async () => {
    try {
      if (!address || !poolInfo) return;
      setIsLoading(true);
      const amountAllowance = await getTokenInfo(
        poolInfo?.contract,
        process.env.REACT_APP_USDC_ADDRESS,
        address
      );
      setAllowance(Number(amountAllowance.allowance));
      setDecimalAllow(amountAllowance?.decimals);
      setIsLoading(false);
    } catch (error) {
      setAllowance(0);
      console.error("error", error);
      setIsLoading(false);
    }
  };

  const countDownTime = (time: number) => {
    // Set the date we're counting down to
    const valueDay = dayjs(time).format("MM/DD/YYYY HH:mm");

    const countDownDate = new Date(`${valueDay}`).getTime();

    // Update the count down every 1 second
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      setDays(
        Math.floor(distance / (1000 * 60 * 60 * 24)) <= 0
          ? 0
          : Math.floor(distance / (1000 * 60 * 60 * 24))
      );
      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinutes(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      );
      setSecond(
        Math.floor((distance % (1000 * 60)) / 1000) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60)) / 1000)
      );

      if (distance < 0) {
        clearInterval(x);
      }
    }, 1000);
  };

  const handleMax = () => {
    debouncedGetAmountAllow();
    setAmount(balanceToken);
  };
  const handleShowSchedule = () => {
    const token = localStorage.getItem("TOKEN");
    if (!token) {
      dispatch(getDataTokenLogin("SIGN"));
      return;
    }
    setIsShowSchedule(true);
  };

  const handleVerifyUser = async () => {
    try {
      if (!tokenLogin) return;
      const { data } = await AVAX_API.checkUserInWhiteList({
        round_id: id,
      });
      setIsUseInWL(data?.can_join);
    } catch (error) {
      console.log("handleVerifyUser err", error);
    }
  };

  // Debounce callback
  const debouncedGetAmountAllow = useDebouncedCallback(
    // function
    () => {
      getAllowance();
    },
    // delay in ms
    300
  );

  const handleGetSchedule = async () => {
    try {
      if (!tokenLogin) return;
      const { data } = await AVAX_API.getClaimSchedule({
        round_id: id,
      });
      setDataSchedule(data);
    } catch (error) {
      console.log("handleGetSchedule err", error);
    }
  };

  useEffect(() => {
    if (reCallFunc) {
      const intervalCalFunc = setInterval(() => {
        const token = localStorage.getItem("TOKEN");
        if (token) {
          clearInterval(intervalCalFunc);
          handleBuyIDO();
          setReCallFunc(false);
        }
      }, 1500);
    }
  }, [reCallFunc]);

  useEffect(() => {
    if (poolInfo) {
      const isBeforeStart = dayjs().isBefore(poolInfo?.start_date);
      const isAfterStart = dayjs().isAfter(poolInfo?.start_date);
      const isAfterEnd = dayjs().isAfter(poolInfo?.end_date);
      if (isAfterEnd) {
        setStepActive(4);
      } else if (isAfterStart) {
        countDownTime(poolInfo?.end_date);
        setStepActive(2);
      } else if (isBeforeStart) {
        countDownTime(poolInfo?.start_date);
        setStepActive(1);
      }
    }
  }, [poolInfo]);

  useEffect(() => {
    if (address) {
      Promise.all([
        handleVerifyUser(),
        getAllowance(),
        getUSDTBalance(),
        handleGetSchedule(),
      ]);
    }
  }, [address, tokenLogin]);

  useEffect(() => {
    if (chainId && chainId !== 43114) {
      modalAppkit.switchNetwork(avalanche);
    }
  }, [chainId]);

  return (
    <BlockRight>
      <ModalOverlay
        component={
          <ClaimModal
            dataSchedule={dataSchedule}
            roundData={balanceInfo}
            poolInfo={poolInfo}
            onClaimed={() => {
              Promise.all([
                onRefresh(),
                getAllowance(),
                getUSDTBalance(),
                handleGetSchedule(),
              ]);
            }}
          />
        }
        open={isShowSchedule}
        setOpen={setIsShowSchedule}
        title={"Schedule Vesting"}
        width={isMobile ? "430px" : "550px"}
        top={isMobile ? "25%" : "-10%"}
      />
      <BoxContainer className={theme}>
        <Title>{poolInfo?.round_name || "Kibble IDO"}</Title>
        <SymbolToken> KIB / USDC </SymbolToken>
        <BoxDetail className={theme}>
          <ProgressTitles className={theme}>
            <h3>Min Sale</h3>
            <p className={theme}>
              <Value unitafter="USDC" value={50} />
            </p>
          </ProgressTitles>
          <ProgressTitles className={theme}>
            <h3>Max Sale</h3>
            <p className={theme}>
              <Value unitafter="USDC" value={10000} />
            </p>
          </ProgressTitles>
          <ProgressTitles>
            <h3>Progress</h3>
            {poolInfo && (
              <h4 className={theme}>
                <Value
                  unitafter="%"
                  value={
                    Number(
                      (poolInfo?.total_saled / poolInfo?.total_raise) * 100
                    ) < 100
                      ? Number(
                          (poolInfo?.total_saled / poolInfo?.total_raise) * 100
                        )
                      : 100
                  }
                />
              </h4>
            )}
          </ProgressTitles>
          <ProgressBar
            className={theme}
            progress={
              poolInfo?.total_saled && poolInfo?.total_raise
                ? (poolInfo?.total_saled / poolInfo?.total_raise) * 100
                : 0
            }
          ></ProgressBar>
          <TotalRai>
            <Value
              unit="$"
              color={theme === "dark" ? "#fff" : "#000"}
              value={
                poolInfo?.total_saled_usd > poolInfo?.total_raise
                  ? poolInfo?.total_raise
                  : poolInfo?.total_saled_usd
              }
            />{" "}
            <span>
              /<Value unit="$" value={Number(poolInfo?.total_raise) || 0} />
            </span>
          </TotalRai>
          <BoxAsset className={theme}>
            <BoxAssetAvailable>
              <BoxVolume>
                <p>Balance: {convertFixed(Number(balanceToken))}</p>
                {/* <span></span> */}
                <button onClick={handleMax} style={{ cursor: "pointer" }}>
                  MAX
                </button>
              </BoxVolume>
            </BoxAssetAvailable>
            <InputContainer>
              <Input
                className={theme}
                type="number"
                placeholder="0.0"
                min={0}
                value={amount || ""}
                onChange={(e) => {
                  debouncedGetAmountAllow();
                  setAmount(Number(e.target.value));
                }}
              />
            </InputContainer>
          </BoxAsset>
        </BoxDetail>
        <CountDown className={theme}>
          <p>
            {dayjs().isBefore(poolInfo?.start_date)
              ? "Start at"
              : dayjs().isAfter(poolInfo?.end_date)
              ? "Finished"
              : dayjs().isAfter(poolInfo?.start_date)
              ? "End in"
              : ""}
          </p>
          <div>
            <span className={theme}>{formatUnitTime(days)}D</span> <p>:</p>
            <span className={theme}>{formatUnitTime(hours)}H</span> <p>:</p>
            <span className={theme}>{formatUnitTime(minutes)}M</span> <p>:</p>
            <span className={theme}>{formatUnitTime(seconds)}S</span>
          </div>
        </CountDown>

        {address ? (
          <>
            {(Number(amount) < 50 && Number(amount) > 0) ||
            Number(balanceToken) < Number(amount) ||
            (Number(amount) >
              Number(poolInfo?.total_raise) - Number(poolInfo?.total_saled) &&
              !dayjs().isAfter(poolInfo?.end_date)) ? (
              <>
                <ButtonCommon className={theme} disabled>
                  {Number(balanceToken) < Number(amount)
                    ? "Insufficient Balance"
                    : Number(amount) < 50 && Number(amount) > 0
                    ? "Minimum 50 USDC"
                    : "Exceeds Max Buy Limit"}
                </ButtonCommon>
              </>
            ) : (
              <>
                {Number(allowance) < Number(amount) ? (
                  <ButtonCommon
                    className={theme}
                    disabled={isLoading}
                    onClick={approveBuy}
                  >
                    <Loading status={isLoading} content="Approve" />
                  </ButtonCommon>
                ) : dayjs().isAfter(poolInfo?.end_date) ? (
                  <ButtonCommon
                    className={theme}
                    disabled={
                      chainId !== 43114 ||
                      // !poolInfo?.can_claim ||
                      Number(balanceInfo?.total_usd) === 0
                    }
                    onClick={handleShowSchedule}
                  >
                    <Loading status={isLoading} content={"Claim Tokens"} />
                  </ButtonCommon>
                ) : (
                  <ButtonCommon
                    className={theme}
                    disabled={
                      isLoading ||
                      chainId !== 43114 ||
                      Number(amount) < 50 ||
                      dayjs().isBefore(poolInfo?.start_date) ||
                      dayjs().isAfter(poolInfo?.end_date) ||
                      !isUseInWL
                    }
                    onClick={handleBuyIDO}
                  >
                    <Loading
                      status={isLoading}
                      content={
                        !isUseInWL
                          ? "You are not in Whitelist"
                          : Number(amount) === 0
                          ? "Enter amount"
                          : "Join pool"
                      }
                    />
                  </ButtonCommon>
                )}
              </>
            )}
          </>
        ) : (
          <BtnConnectWl
            onClick={() => {
              open();
            }}
            className={theme}
          >
            {"Connect wallet"}
          </BtnConnectWl>
        )}
      </BoxContainer>
      <BlockRightCard>
        <BlockStepsTitle>
          <BlockStepsTitleItem active={stepActive > 0} className={theme}>
            <p>01</p>
          </BlockStepsTitleItem>
          <BlockStepsTitleItem active={stepActive > 1} className={theme}>
            <p>02</p>
          </BlockStepsTitleItem>
          <p
            style={{
              color: theme === "dark" ? "#EEEEF0" : "#B32222",
              whiteSpace: "nowrap",
            }}
          >
            Pool Start
          </p>
          <BlockStepsTitleItem active={stepActive > 3} className={theme}>
            <p>03</p>
          </BlockStepsTitleItem>
          <BlockStepsTitleItem active={stepActive > 3} className={theme}>
            <p>04</p>
          </BlockStepsTitleItem>
        </BlockStepsTitle>
        <BlockSteps>
          {STEPS.map((item: any, index: number) => {
            return (
              <BlockRightRow
                key={index}
                className={index === STEPS.length - 1 ? "hidden-step" : ""}
              >
                <div
                  className={index < stepActive ? "action-dot" : "img-dot"}
                ></div>
                <div
                  className={index < stepActive - 1 ? "action-line" : "line"}
                ></div>
                <div>
                  <h2
                    style={{
                      color: index < stepActive ? "var(--Stake-Title)" : "",
                    }}
                  >
                    {item.title}
                  </h2>

                  <p
                    style={{
                      fontFamily: item.key ? "DMMono-500" : "Syne",
                    }}
                  >
                    {item.key && poolInfo
                      ? poolInfo[item.key]
                        ? dayjs(poolInfo[item.key]).format("DD/MM/YYYY HH:mm")
                        : "TBA (UTC)"
                      : item.description}
                  </p>
                </div>
              </BlockRightRow>
            );
          })}
        </BlockSteps>
        <BlockRightStatus>
          <FairBox>
            <BlockTableRow>
              <h2>Status</h2>
              <h1>
                {stepActive === 1 && "Upcomming"}
                {stepActive === 2 && "Active"}
                {stepActive === 4 && "Ended"}
              </h1>
            </BlockTableRow>
            {/* <BlockTableRow>
              <h2>Sale Type</h2>
              <h1 style={{ color: "#B32222" }}>{poolInfo?.round_name}</h1>
            </BlockTableRow> */}

            <BlockTableRow>
              <h2> Swap Rate </h2>
              <p> 1KIB = {poolInfo?.price_usd} USDC</p>
            </BlockTableRow>
            <BlockTableRow>
              <h2>Total Participants</h2>
              <p>{poolInfo?.contributors || 0}</p>
            </BlockTableRow>
            <BlockTableRow>
              <h2>Your Investment</h2>
              <p>
                <Value unitafter="USDC" value={balanceInfo?.total_usd} />
              </p>
            </BlockTableRow>
          </FairBox>
        </BlockRightStatus>
      </BlockRightCard>
    </BlockRight>
  );
};
export default InputBox;

const BoxContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 322px;
  padding: 16px 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid var(--Neutral-700, #4d4d57);
  background: #1c1c1e;
  height: fit-content;
  &.light {
    background: #fff;
    border: 1px solid #b8b8c1;
    h3 {
      color: #29292d;
    }
  }
  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`;
const Title = styled.h3`
  margin-bottom: 4px;
  color: #fff;
  font-family: Dirtyline;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: lowercase;
`;
const SymbolToken = styled.p`
  color: var(--Neutral-400, #92929e);
  font-family: Syne;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 66.667%;
`;
const BoxDetail = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  background: var(--Neutral-1000, #000);
  &.light {
    background: #fff;
    border: 1px solid #d3d3d3;
    h3 {
      color: #29292d;
    }
  }
`;
const BoxAsset = styled.div`
  width: 100%;
  border-radius: 10.12px;
  background: #28272c;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  &.light {
    background: #fff;
    border: 1px solid #d3d3d3;
  }
`;

const BoxAssetAvailable = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
  h3 {
    color: #fff;
    font-family: Syne;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 15px */
  }
`;

const BoxVolume = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  p {
    color: var(--Neutral-400, #92929e);
    font-family: DMMono-400;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -0.66px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  span {
    display: flex;
    width: 3.114px;
    height: 12.668px;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #fff;
  }
  button {
    color: var(--Red-700, rgba(179, 34, 34, 1));
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    border: none;
    outline: none;
    background: transparent;
    padding: 0px;
    margin: 0px;
  }
`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
`;
const BoxSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
  width: fit-content;
  border-radius: 7.006px;
  border: 0.778px solid var(--Neutral-700, #4d4d57);
  padding: 4px 8px;
  img {
    max-width: 16px;
    border-radius: 50%;
  }
  p {
    color: #fff;
    font-family: DMMono-500;
    font-size: 10.899px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 13.623px */
  }
`;
const Input = styled.input`
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  text-align: right;
  font-family: DMMono-500;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 27.5px */
  width: calc(100%);
  &.light {
    color: #3a3a40;
  }
`;

const CountDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  p {
    color: var(--Neutral-100, #eeeef0);
    font-family: Syne;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 15px */
  }
  div {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
    span {
      display: flex;
      padding: 6.365px 6.474px 6.598px 6.489px;
      justify-content: center;
      align-items: center;

      border-radius: 4.493px;
      border: 0.749px solid var(--Neutral-700, #4d4d57);
      background: var(--Neutral-800, #43424a);
      color: var(--Neutral-100, #eeeef0);
      text-align: center;
      font-family: DMMono-400;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%;
      align-self: stretch;
      aspect-ratio: 1;
      width: unset;
      align-self: stretch;
    }
  }
  &.light {
    p {
      color: #43424a;
    }
    div {
      span {
        &.light {
          background: #eeeef0;
          border: 0.749px solid var(--Neutral-300, #b8b8c1);
          color: #3a3a40;
        }
        background-color: #92929f;
      }
    }
  }
`;

const BtnConnectWl = styled.button`
  width: 100%;
  display: flex;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1.5px solid var(--Neutral-900, #e84142);
  background: var(--Neutral-800, #e84142);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 3px 0px 0px rgba(186, 186, 186, 0.33) inset;
  color: var(--Neutral-White, #fff);
  font-family: Syne;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  cursor: pointer;
  &.light {
    background: #b32222;
    border: none;
    box-shadow: none;
  }
`;
