import styled from "styled-components";

export const BoxPoolDetailWapper = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  &.light {
    .TokenLogo {
      background: #e8e8e8;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
export const CoinInfoWapper = styled.div`
  width: 100%;
  gap: 12px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--Neutral-950, #1c1c1e);
  &.light {
    background: var(--Neutral-950, #fff);
    h3,
    span {
      color: var(--Red-700, #b32222);
    }
    a {
      background: var(--Neutral-200, #92929e);
      box-shadow: 0px 3px 0px 0px rgba(186, 186, 186, 0.33) inset;
    }
  }
`;
export const CoinInfoTitle = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: center;
  .TokenLogo {
    width: 82px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10.273px;
    background: #000;
    padding: 5px;
  }
  img {
    max-width: 72px;
  }
  div {
    h3 {
      color: #fff;
      font-family: Dirtyline;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 96%; /* 23.04px */
      text-transform: lowercase;
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 12px;
      span {
        color: var(--Neutral-400, #92929e);
        font-family: Syne;
        font-size: 15px;
        font-weight: 400;
      }
    }
    p {
      &.light {
        color: var(--Green-800, rgba(48, 105, 89, 1));
        background: var(--Green-100, rgba(208, 246, 227, 1));
      }
      display: flex;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 6px;
      background: var(--Green-900, #1f4c40);
      color: var(--Green-300, #83d3b0);
      text-align: center;
      font-family: Syne;
      font-size: 14px;
      font-weight: 500;
      line-height: 150%; /* 21px */
      width: fit-content;
    }
  }
  @media screen and (max-width: 767px) {
    div {
      h3 {
        span {
          white-space: nowrap;
        }
      }
    }
  }
`;

export const BtnViewProject = styled.div`
  background: none;
  border: none;
  background: transparent;
  cursor: pointer;
  &.dark {
    a {
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }
  &.light {
    background: var(--Neutral-950, #fff);
    span {
      color: var(--Red-700, #43424a);
    }
    a {
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }
`;
export const CoinInfoDes = styled.p`
  color: var(--Neutral-300, #b8b8c1);
  font-family: Syne;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  &.light {
    color: var(--Neutral-500, #6e6e7c);
  }
`;
export const CoinInfoSocial = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  div {
    display: flex;
    gap: 10px;
    align-items: center;
    a {
      display: flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      border: 1px solid var(--Neutral-900, #3a3a40);
      background: var(--Neutral-800, #43424a);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px 3px 0px 0px rgba(186, 186, 186, 0.33) inset;
        &.light {
          border: 0.749px solid var(--Neutral-300, #b8b8c1);
        }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  button {
    display: flex;
    padding: 7px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    opacity: 0.5;
    color: var(--Neutral-White, #fff);
    font-family: Syne;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 17.5px */
    background: transparent;
    border: none;
  }
  @media screen and (max-width: 767px) {
    div {
      gap: 3px;
    }
    button {
      padding: 0px;
      font-size: 12px;
    }
  }
`;

export const Tokenomics = styled.div`
  width: calc(45% - 5px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: var(--Neutral-950, #1c1c1e);
  padding: 20px;
  h1 {
    color: var(--Neutral-50, #f7f7f8);
    font-family: Dirtyline;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 100% */
    text-transform: lowercase;
  }
  &.light {
    background: var(--Neutral-950, #fff);
    h1 {
      color: #43424a;
    }
    .img-chart {
      background: #fff;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
export const PieWapper = styled.div`
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px;
  z-index: 1;
  .img-chart {
    max-width: 200px;
    img {
      width: 100%;
    }
  }
  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    background: var(--Neutral-950, #1c1c1e);
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    z-index: -1;
    img {
      width: 28px;
    }
  }
`;

export const TokenomicsInfor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-radius: 8px;
  width: 100%;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  h3 {
    color: var(--Neutral-White, #6e6e7c);
    font-family: Syne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 25px */
  }
  p {
    &.light {
      color: var(--Neutral-White, #6e6e7c);
      }
    color: var(--Neutral-50, #f7f7f8);
    font-family: DMMono-500;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.857%; /* 142.857% */
  }
`;
export const LockInfo = styled.div`
  width: calc(55% - 7px);
  border-radius: 8px;
  gap: 12px;
  background: var(--Neutral-950, #1c1c1e);
  padding: 20px;
  display: flex;
  flex-direction: column;
  h1 {
    color: var(--Neutral-50, #f7f7f8);
    font-family: Dirtyline;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 100% */
    text-transform: lowercase;
  }
  img {
    width: 100%;
    max-width: 230px;
    margin: 0 auto;
    mix-blend-mode: luminosity;
  }
  &.light {
    background: var(--Neutral-950, #fff);
    h1 {
      color: #43424a;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
