import { useContext, useEffect, useState } from "react";
import { ButtonCommon } from "../../../Layout/styled";
import {
  Des,
  IDOBody,
  IDOImageParent,
  IDOReward,
  IDOTag,
  PriceBox,
  ProgressBar,
  ProgressTitles,
  TextContent,
  TotalRai,
} from "./styled";
import { ContextProviderWrapper } from "../../../components/Context";
import Value from "../../../components/Value";
import { formatUnitTime } from "../../../utils/formatUnitTime";
import dayjs from "dayjs";
import { formatAddress } from "../../../utils/addressUser";

export default function ItemIDO({ data, onClaimReward }: any) {
  const { theme } = useContext(ContextProviderWrapper)!;
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSecond] = useState(0);

  const countDownTime = (time: number) => {
    if (!time) return;
    // Set the date we're counting down to
    const valueDay = dayjs(time).format("MM/DD/YYYY HH:mm");

    const countDownDate = new Date(`${valueDay}`).getTime();

    // Update the count down every 1 second
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();
      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      setDays(
        Math.floor(distance / (1000 * 60 * 60 * 24)) <= 0
          ? 0
          : Math.floor(distance / (1000 * 60 * 60 * 24))
      );
      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinutes(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      );
      setSecond(
        Math.floor((distance % (1000 * 60)) / 1000) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60)) / 1000)
      );

      if (distance < 0) {
        clearInterval(x);
      }
    }, 1000);
  };

  useEffect(() => {
    if (data && data?.start_date && dayjs().isBefore(data?.start_date)) {
      countDownTime(data?.start_date);
    }

    if (data && data?.end_date && dayjs().isAfter(data?.start_date)) {
      countDownTime(data?.end_date);
    }
  }, [data]);

  return (
    <li>
      <div>
        <IDOImageParent>
          <div className="img-tag">
            <img src={data?.banner} alt="img" />
          </div>
          <div
            style={{
              background: dayjs().isAfter(data?.end_date) ? "#000" : "#0a5d37",
            }}
            className="tag-status"
          >
            <h6
              style={{
                color: dayjs().isAfter(data?.end_date) ? "#fff" : "#0ff586",
              }}
            >
              {dayjs().isBefore(data?.start_date)
                ? "Upcomming"
                : dayjs().isAfter(data?.end_date)
                ? "Finished"
                : dayjs().isAfter(data?.start_date)
                ? "Active"
                : ""}
            </h6>
          </div>
        </IDOImageParent>
        <IDOBody className={theme}>
          <h2>
            Kibble Exchange <span>$KIB</span>{" "}
          </h2>
          <IDOTag
            style={{
              position: "relative",
              left: 0,
              top: 0,
            }}
            colorText={data.color}
            colorBg={data.background}
          >
            <p>{data.name}</p>
          </IDOTag>
          <Des style={{ paddingTop: "10px" }}>{data.description}</Des>
        </IDOBody>
        <IDOReward className={theme}>
          <ProgressTitles className={theme}>
            <h3>Progress</h3>
            <h4 className={theme}>
              <Value
                unitafter="%"
                value={Number(
                  (data?.total_saled / data?.total_raise) * 100 < 100
                    ? (data?.total_saled / data?.total_raise) * 100
                    : 100
                )}
              />
            </h4>
          </ProgressTitles>
          <ProgressBar
            progress={
              data.total_raise && data.total_saled
                ? (data.total_saled / data.total_raise) * 100
                : 0
            }
            className={theme}
          ></ProgressBar>
          <TotalRai className={theme}>
            <Value
              unit="$"
              color="#fff"
              value={Number(
                data?.total_saled > data.total_raise
                  ? data.total_raise
                  : data?.total_saled
              )}
            />
            {/* {data.total_saled}  */}
            <span>
              / <Value unit="$" value={Number(data.total_raise || 0)} />
            </span>
          </TotalRai>
          <PriceBox className={theme}>1 KIB = {data.rate} USDC</PriceBox>
        </IDOReward>
        <TextContent>
          <h6>Join Network</h6>
          <p className={theme}>Avalanche C-Chain</p>
        </TextContent>
        <TextContent>
          <h6>Join Token</h6>
          <p className={theme}>USDC</p>
        </TextContent>
        {/* <TextContent>
          <h6>Contract Pool</h6>
          <p className={theme}>{formatAddress(data?.contract, 6, 8) || 'TBA'}</p>
        </TextContent> */}
        <TextContent style={{ marginBottom: "10px" }}>
          <h6>
            {dayjs().isBefore(data?.start_date)
              ? "Start at"
              : dayjs().isAfter(data?.end_date)
              ? "Finished"
              : dayjs().isAfter(data?.start_date)
              ? "End in"
              : ""}
          </h6>
          <div>
            <span className={theme}>{formatUnitTime(days)}D</span> <p>:</p>
            <span className={theme}>{formatUnitTime(hours)}H</span> <p>:</p>
            <span className={theme}>{formatUnitTime(minutes)}M</span> <p>:</p>
            <span className={theme}>{formatUnitTime(seconds)}S</span>
          </div>
        </TextContent>
        <ButtonCommon
          onClick={() => {
            onClaimReward();
          }}
          className={theme}
        >
          View Pool
        </ButtonCommon>
      </div>
    </li>
  );
}
