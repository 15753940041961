import { useContext, useState } from "react";
import { ButtonCommon } from "../../../Layout/styled";
import { ContextProviderWrapper } from "../../../components/Context";
import {
  ClaimContainer,
  ClaimSchedule,
  ClaimScheduleHeader,
  ClaimScheduleRow,
  ItemVesting,
  ItemVestingRow,
  ListVesting,
  WrapClaimSchedule,
  WrapTBClaimSchedule,
  WrapVesting,
} from "./styled";
import { convertFixed, convertToWei } from "../../../utils/convertNumber";
import { AVAX_API } from "../../../services/api/indexV2";
import { useParams } from "react-router-dom";
import web3 from "web3";
import abiClaim from "../../../components/contract/ABI/abiClaim.json";
import { useAppKitAccount } from "@reown/appkit/react";
import { toast } from "react-hot-toast";
import Loading from "../../../components/Loading";
import dayjs from "dayjs";
import { TransactionsDetailStatus } from "../../Swap/History/style";

export default function ClaimModal({
  dataSchedule,
  onClaimed,
  roundData,
  poolInfo,
}: any) {
  const TABLE_HEADER = ["Amount", "Claim Date", "%"];
  const { theme } = useContext(ContextProviderWrapper)!;
  const { id } = useParams();
  const Web3 = new web3(window.ethereum);
  const { address }: any = useAppKitAccount();
  const [isLoading, setIsLoading] = useState(false);
  const [itemClaim, setIemClaim] = useState<any>(null);

  const handleClaim = async (dataClaim: any) => {
    try {
      if (isLoading) return;
      setIemClaim(dataClaim);
      setIsLoading(true);
      const { data } = await AVAX_API.getClaimSignature({
        round_id: id,
      });
      const contract = new Web3.eth.Contract(
        abiClaim,
        poolInfo?.contract_claim
      );
      const valueToWei: any = convertToWei(dataClaim?.claimable, 18);
      const gasPrice = await Web3.eth.getGasPrice();
      const gasEstimate = await contract.methods
        .claimTokens(valueToWei, roundData?.claim_index, data?.signature)
        .estimateGas({
          from: address,
          gasPrice: gasPrice.toString(),
        });
      await contract.methods
        .claimTokens(valueToWei, roundData?.claim_index, data?.signature)
        .send({
          from: address,
          gasPrice: gasPrice.toString(),
          gas: gasEstimate.toString(),
        })
        .then(async (res: any) => {
          let indexCreate = 0;
          const intervalCreateTx = setInterval(async () => {
            indexCreate++;
            try {
              await AVAX_API.createClaim({
                tx_hash: res?.transactionHash,
                round_id: id,
              });
              setIsLoading(false);
              clearInterval(intervalCreateTx);
              onClaimed();
              toast.success("Claim Successfully");
              setIemClaim(null);
            } catch (error: any) {
              if (indexCreate === 5) {
                clearInterval(intervalCreateTx);
                toast.error(
                  error && error.message
                    ? error.message.substring(0, 55) + "..."
                    : "Claim Failed"
                );
                setIsLoading(false);
              }
            }
          }, 2000);
        })
        .catch((error: any) => {
          toast.error(
            error && error.message
              ? error.message.substring(0, 55) + "..."
              : "Claim Failed"
          );

          setIsLoading(false);
        });
    } catch (error: any) {
      toast.error(
        error && error.message
          ? error.message.substring(0, 55) + "..."
          : "Claim Failed"
      );
      setIsLoading(false);
    }
  };

  return (
    <ClaimContainer>
      <WrapTBClaimSchedule className={theme}>
        <ClaimSchedule>
          <ClaimScheduleHeader className={theme}>
            {TABLE_HEADER.map((item: any, index: number) => (
              <p key={index}> {item} </p>
            ))}
          </ClaimScheduleHeader>
          <WrapClaimSchedule>
            {dataSchedule?.schedule && dataSchedule?.schedule.length > 0 ? (
              dataSchedule?.schedule.map((item: any, index: number) => (
                <ClaimScheduleRow key={index} className={theme}>
                  <p>{convertFixed(item.claimable)}</p>
                  <p>{dayjs(item.claimAt).format("MM/DD/YYYY HH:mm")}</p>
                  <p>{item.percent * 100} %</p>
                  {item?.isClaimed ? (
                    <TransactionsDetailStatus
                      style={{
                        background:
                          theme === "dark" ? "#00341d" : "rgb(208 246 227)",
                        width: "120px",
                        margin: "0 auto",
                        padding: "5px 0",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          color:
                            theme === "dark"
                              ? "#0ff586"
                              : "rgba(48, 105, 89, 1)",
                        }}
                      >
                        Claimed
                      </span>
                    </TransactionsDetailStatus>
                  ) : (
                    <ButtonCommon
                      disabled={
                        (isLoading && item?.claimAt === itemClaim?.claimAt) ||
                        dayjs().isBefore(item?.claimAt) ||
                        item?.isClaimed
                      }
                      onClick={() => {
                        handleClaim(item);
                      }}
                      className={theme}
                    >
                      <Loading
                        status={
                          isLoading && item?.claimAt === itemClaim?.claimAt
                        }
                        content={item?.isClaimed ? "Claimed" : "Claim"}
                      />
                    </ButtonCommon>
                  )}
                </ClaimScheduleRow>
              ))
            ) : (
              <div className="img-nodata">
                <img src="/static/img-nodata.gif" alt="nodata" />
              </div>
            )}
          </WrapClaimSchedule>
        </ClaimSchedule>
      </WrapTBClaimSchedule>
      <WrapVesting className={theme}>
        <h1>Your Vesting</h1>
        <ListVesting>
          <ItemVesting className={theme}>
            <ItemVestingRow className={theme}>
              <div className="img-icon-title">
                <img src="/assets/images/ido/img-token-title.png" alt="img" />
              </div>
              <h2>Total Allowcation</h2>
            </ItemVestingRow>
            <ItemVestingRow className={theme}>
              <p>{convertFixed(dataSchedule?.schedule.reduce((item: any, crr: any) => item + crr.claimable , 0 ))}</p>
              <div className="img-icon-token">
                <img src="/assets/images/ido/img-token.png" alt="img" />
              </div>
            </ItemVestingRow>
          </ItemVesting>
          <ItemVesting className={theme}>
            <ItemVestingRow className={theme}>
              <div className="img-icon-title">
                <img src="/assets/images/ido/img-token-title.png" alt="img" />
              </div>
              <h2>Total Claimed</h2>
            </ItemVestingRow>
            <ItemVestingRow className={theme}>
              <p>{convertFixed(dataSchedule?.total_claimed)}</p>
              <div className="img-icon-token">
                <img src="/assets/images/ido/img-token.png" alt="img" />
              </div>
            </ItemVestingRow>
          </ItemVesting>
          <ItemVesting className={theme}>
            <ItemVestingRow className={theme}>
              <div className="img-icon-title">
                <img src="/assets/images/ido/img-token-title.png" alt="img" />
              </div>
              <h2>Claim Available</h2>
            </ItemVestingRow>
            <ItemVestingRow className={theme}>
              <p>{convertFixed(dataSchedule?.claim_avalable)}</p>
              <div className="img-icon-token">
                <img src="/assets/images/ido/img-token.png" alt="img" />
              </div>
            </ItemVestingRow>
          </ItemVesting>
        </ListVesting>
      </WrapVesting>
    </ClaimContainer>
  );
}
