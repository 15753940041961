import { SwapChartContainer, SwapChartEmpty } from "./styled";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import Chart from "react-apexcharts";
import { ChartWapper } from "../../Chart/styled";
import axios from "axios";
import { SwapChartPrice } from "../styled";
import { Skeleton } from "antd";
import { ContextProviderWrapper } from "../../../components/Context";
import EmptyData from "../../../assets/gif/img-nodata.png";
import { formatMiniNumber } from "../../../utils/formatNumber";
const SwapChartTable = ({
  fromAssetKey,
  toAssetKey,
  timeTarget,
  // fromSymbol,
  // toSymbol,
  setValueChanges,
  valueChanges,
}: any) => {
  const { theme } = useContext(ContextProviderWrapper)!;
  const currentDate = new Date();
  const [dataRootTokent, setDataRootTokent] = useState<any>({});
  const [dataSwapTokent, setDataSwapTokent] = useState<any>(null);
  const [dataChart, setDataChart] = useState<any>({ x: [], y: [] });
  const [loading, setLoading] = useState<any>(true);
  const [isReLoad, setIsReLoad] = useState<any>(false);
  const [isNoData, setIsNoData] = useState<any>(false);
  const [currentTimeGetPrice, setCurrentTimeGetPrice] = useState("");
  const [price, setPrice]: any = useState(0);
  const [fromSymbol, setFromSymbol] = useState("");
  const [toSymbol, setToSymbol] = useState("");
  const [isEmptyData, setIsEmptyData] = useState(false);
  const FetchApi = async () => {
    const ResApi = await axios.get(
      `https://app.kibble.exchange/chart?address1=${fromAssetKey}&address2=${toAssetKey}`
    );
    if (ResApi.status === 200) {
      const data = ResApi.data?.data;
      const lastTime = ResApi.data?.data?.[ResApi.data?.data?.length - 1];
      let ArrX: any = [];
      let ArrY: any = [];
      setIsEmptyData(data.length === 0);
      if (data) {
        data.map((item: any) => {
          if (item.time % 3600 === 0 || item.time === lastTime.time) {
            let a = formatTimestamp(item.time);
            ArrX.push(a);
            ArrY.push(item.value);
          }
        });
        setDataChart({ x: ArrX, y: ArrY });
      }
      setValueChanges(ResApi.data?.change);
      setFromSymbol(ResApi.data?.baseToken?.symbol === "ETH" ? "AVAX" : ResApi.data?.baseToken?.symbol);
      setToSymbol(ResApi.data?.quoteToken?.symbol === "ETH" ? "AVAX" : ResApi.data?.quoteToken?.symbol);
      setPrice(lastTime ? Number(lastTime.value) < 0.0001 ? formatMiniNumber(Number(lastTime.value)) : Number(lastTime.value).toFixed(4) : 0.0);
    }
  };
  useEffect(() => {
    FetchApi();
  }, [fromAssetKey, toAssetKey]);

  const options: any = {
    options: {
      chart: {
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: "zoom",
        },
      },
      colors:
        valueChanges < 0
          ? ["#f50f48", "#3232320", "#3232320"]
          : ["#0ff586", "#3232320", "#3232320"],

      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.8,
          gradientToColors: "#3232320",
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      labels: dataChart?.x,
      xaxis: {
        labels: {
          style: {
            colors: theme === "light" ? "#5E5E6B" : "#ffffff80",
          },
        },
      },
      yaxis: {
        // min: 0,
        decimalsInFloat: 2,
        opposite: true,
        labels: {
          style: {
            colors: theme === "light" ? "#5E5E6B" : "#ffffff80",
          },
        },
        // crosshairs: {
        //   show: true,
        // },
      },
      markers: {
        size: 0,
        fillOpacity: 0,
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            xaxis: {
              // labels: {
              //   show: false,
              // },
            },
          },
        },
      ],
    },
    series: [
      {
        name: `${fromSymbol} / ${toSymbol}`,
        data: dataChart.y,
      },
    ],
  };
  return (
    <>
      <SwapChartPrice className={theme}>
        <p style={{ textAlign: "left" }}>{price || "0.00"}</p>
        {/* <span>{currentTimeGetPrice ? `${currentTimeGetPrice} UTC` : '--:--'} </span> */}
      </SwapChartPrice>
      <SwapChartContainer>
        {/*{loading ? (
          <SwapChartEmpty>
            <Skeleton active paragraph={{ rows: 5 }} />
          </SwapChartEmpty>
        ) : dataChart.y <= 0 || isNoData ? (
         
        ) : ( */}
        <>
          {isEmptyData ? (
            <SwapChartEmpty>
              <figure>
                <img src={EmptyData} alt="icon" />
              </figure>
              <p>No data</p>
              <span>
                Data for the chart will not appear until the
                <br /> first swap occurs
              </span>
            </SwapChartEmpty>
          ) : (
            <ChartWapper>
              <Chart
                options={options.options}
                series={options.series}
                type="area"
                height={350}
              />
            </ChartWapper>
          )}
        </>
        {/* )} */}
      </SwapChartContainer>
    </>
  );
};

export default SwapChartTable;

function formatTimestamp(timestamp: any) {
  const date = new Date(timestamp * 1000);
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  // const day = date.getUTCDate().toString().padStart(2, '0')
  // const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  return `${hours}:${minutes}`;
}
