import { useContext, useEffect, useState } from "react";
import {
  BlockTableBox,
  BlockTableRowClaim,
  BlockTableRowClaimImage,
  DetailContainer,
  RowDetail,
} from "../Detail/styled";
import { ContextProviderWrapper } from "../../../components/Context";
import { BackToButton } from "../../Liquidity/Details/styled";
import { useNavigate, useParams } from "react-router-dom";
import arrow from "../../../assets/Dashboard/Common/arrow_gray.svg";
import InputBox from "../IDO/InputBox";
import styled from "styled-components";
import { AVAX_API } from "../../../services/api/indexV2";
import dayjs from "dayjs";
import { useAppKitAccount } from "@reown/appkit/react";
import { useSelector } from "react-redux";

const ViewProject = () => {
  const navigate = useNavigate();
  const { isMobile, theme } = useContext(ContextProviderWrapper)!;

  const [poolInfo, setPoolInfo] = useState<any>(null);
  const [balanceInfo, setBalanceInfo] = useState<any>(null);
  const { id } = useParams();
  const { address } = useAppKitAccount();
  const { tokenLogin }: any = useSelector((state: any) => state.pool);

  const handleGetPool = async () => {
    try {
      const { data } = await AVAX_API.getDetailPool({ id });
      setPoolInfo({
        ...data,
        name: data?.round_name,
        describe: data?.description,
        total_saled: data?.total_saled_usd,
        total_raise: data?.total_raise_usd,
        start_date: dayjs(data?.start_date),
        rate: data?.price_usd,
        end_date: dayjs(data?.end_date),
        id: data?.id,
      });
    } catch (error) {
      console.log("view detai", error);
    }
  };

  const handleBalanceIDO = async () => {
    try {
      if (!tokenLogin) return;
      const { data } = await AVAX_API.getBalancesIDO({ round_id: id });
      setBalanceInfo(data);
    } catch (error) {
      console.log("handleGetPool err", error);
    }
  };

  useEffect(() => {
    handleGetPool();
    const intervalGetPool = setInterval(() => {
      handleGetPool();
    }, 5000);
    return () => {
      clearInterval(intervalGetPool);
    };
  }, [id, address]);

  useEffect(() => {
    handleBalanceIDO();
  }, [tokenLogin]);

  return (
    <DetailContainer>
      <BackToButton
        onClick={() => {
          navigate(-1);
        }}
      >
        <img src={arrow} alt="icon" />
        <p>Back to launchpad</p>
      </BackToButton>
      <RowDetail>
        <BoxWapper>
          <img
            className="banner"
            src="/assets/images/ido/img-title-launch.png?v2"
          />
          <BlockTableBox className={theme}>
            <h1>KIBBLE</h1>
            <BlockTableRowClaim>
              <h2></h2>
              <p>
                😍 Kibble ($KIB) - Kibble Exchange 😍 Fueling the Multichain
                Revolution – Igniting the future of multichain DeFi! 🔥
              </p>
            </BlockTableRowClaim>
            <BlockTableRowClaim>
              <p>
                Kibble is an innovative platform that seamlessly blends
                artificial intelligence (AI) with decentralized finance (DeFi),
                offering users an optimized trading experience. Kibble provides
                an all-in-one solution, featuring trading, yield farming,
                liquidity pooling, and project launches.
              </p>
            </BlockTableRowClaim>
            <BlockTableRowClaim>
              <p>
                Kibbke aggregates multi-blockchain networks, while it enables
                swaps digital assets with the best rates, highest liquidity, and
                transaction speeds - in one click.
              </p>
            </BlockTableRowClaim>
            <BlockTableRowClaimImage>
              <div className="img-info" style={{ width: "100%" }}>
                <img
                  src="/assets/images/ido/img-project-2.png?v2"
                  style={{ width: "100%" }}
                />
              </div>
            </BlockTableRowClaimImage>

            <BlockTableRowClaim>
              <h2>Key Feature</h2>
              <ul>
                <li>
                  <span>Kibble Meta Aggregator: </span>Aggregates liquidity from
                  leading decentralized exchanges (DEXs) across multiple
                  blockchains, ensuring users access the best prices, deep
                  liquidity, and swift transactions within a single interface.
                </li>
                <li>
                  <span>Kibble DeFAI: </span>Utilizes AI to automate trading
                  processes, including intelligent order routing and real-time
                  on-chain data analysis, minimizing slippage and optimizing
                  transaction fees.
                </li>
                <li>
                  <span> Kibble Bot: </span>Offers a variety of
                  community-focused bots and features.
                </li>
                <li>
                  <span>Kibble Cross-Chain: </span>Facilitates seamless asset
                  swaps between blockchains such as AVAX, Ethereum, and BSC,
                  enabling users to conduct cross-chain transactions with low
                  fees and high security.
                </li>
                <li>
                  <span>Kibble Launchpad Multichain: </span>Supports project
                  launches and Initial DEX Offerings (IDOs) across various
                  blockchains, connecting developers with a diverse investor
                  community and fostering the growth of a multichain DeFi
                  ecosystem.
                </li>
              </ul>
            </BlockTableRowClaim>
            <BlockTableRowClaim>
              <h2>Milestones and Achievements</h2>
              <ul>
                <li>
                  <span>Angel Round: </span>Have successfully completed
                  fundraising of $350K with the FDV at $10M
                </li>
                <li>
                  <span> Seed Round: </span>Achieved $1 million in Seed round
                  with the FDV at $17.5M
                </li>
                <li>
                  <span>Public Round: </span>Discussing and reaching an
                  agreement to launch a public round on some launchpads BSCS,
                  Seedify, Poolz.. and open for sale on Kibble Launchpad
                </li>
                <li>
                  <span>Kibble Listing: </span>Reached an agreement to list $KIB
                  in Q1/2025 with MEXC,Coinstore ,.. and is discussing and DD
                  with Bybit, OKX, Bitget..
                </li>
                <li>
                  <span>Users onboard: </span>Kibble now boasts 550K+ users. The
                  Kibble Clicker game attracted 150K+ users within the first day
                  of its launch and converting to DEX users
                </li>
                <li>
                  <span>Community growth: </span>Our community has grown
                  impressive to 110K+ members
                </li>
                <li>
                  <span>Successful Event: </span>Hosted the AVAX Meetup Event
                  Vietnam 2024 with over 350 attendees are representing
                  AVAX-based projects, international VCs, top tier exchanges,
                  developers and builders
                  <a
                    href="https://www.youtube.com/watch?v=2T-JqU_4LRU"
                    target="_blank"
                  >
                    {" "}
                    https://www.youtube.com/watch?v=2T-JqU_4LRU{" "}
                  </a>
                </li>
                <li>
                  <span>Strong Partnerships on going: </span>qiibee Foundation,
                  Xircus, Storm Trade, Parachute, DEW, Gomble, BSCS, Taurus AI,
                  Ankh Labs , and more
                </li>
              </ul>
            </BlockTableRowClaim>
            <BlockTableRowClaim>
              <h2>Team Highlights</h2>
              <ul>
                <li>
                  Team with 15+ years of programming experience and 9+ years of
                  experience working with Blockchain.
                </li>
                <li>
                  Our previously projects got some achievements with FDV $320M
                </li>
                <li>
                  Developed Kibble DEX and liquidity provision features in 2
                  months without external funding.
                </li>
              </ul>
            </BlockTableRowClaim>
          </BlockTableBox>
        </BoxWapper>
        <InputBox
          poolInfo={poolInfo}
          balanceInfo={balanceInfo}
          onRefresh={() => {
            Promise.all([handleGetPool(), handleBalanceIDO()]);
          }}
        />
      </RowDetail>
    </DetailContainer>
  );
};
export default ViewProject;

const BoxWapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  .banner {
    width: 100%;
    border-radius: 8px;
  }
`;
