import { useEffect, useState } from "react";
import { useChainId, useConnect } from "wagmi";
import { BtnWallet } from "./styled";
const ListWalletModal = ({ func }: any) => {
  const { connectors, connect } = useConnect();
  const chainId = useChainId();

  return (
    <>
      {ListWallet.map((item: any, index: number) => {
        let connector: any = connectors.find((obj) => obj.id === item.Id);
        return (
          <>
            <ConnectorButton
              key={`${connector}${index}`}
              connector={connector}
              chainId={chainId}
              name={item.Name}
              icon={item.Icon}
              id={item.Id}
              link={item.Link}
              func={func}
            />
          </>
        );
      })}
    </>
  );
};
export default ListWalletModal;

function ConnectorButton({
  connector,
  icon,
  name,
  id,
  chainId,
  link,
  func,
}: any) {
  const { connect } = useConnect();
  const [ready, setReady] = useState(false);
  useEffect(() => {
    (async () => {
      if (connector) {
        const provider = await connector.getProvider();
        setReady(!!provider);
      } else {
        setReady(false);
      }
    })();
  }, [connector, setReady]);

  return (
    <>
      {/* {!isMobile() ? ( */}
      {ready || id === "Solflare" ? (
        <BtnWallet
          className="button"
          onClick={async () => {
            if (connector) {
              await connect({ connector, chainId });
            }
            func();
          }}
          type="button"
        >
          <span>
            <img src={icon} />
          </span>{" "}
          {name}
        </BtnWallet>
      ) : (
        <a href={link} target="_blank">
          <span>
            <img src={icon} />
          </span>{" "}
          {name}
        </a>
      )}
      {/* ) : (
          <button
            className="button"
            onClick={async () => {
              if (isMetaMaskInstalled()) {
                try {
                  await connect({ connector, chainId });
                  func();
                } catch (error) {
                  alert(`${error}`);
                }
                func();
              } else {
                window.location.href =
                  "https://metamask.app.link/dapp/https://thriving-madeleine-d622d3.netlify.app/";
              }
            }}
            type="button"
          >
            <span>
              <img src={icon} />
            </span>{" "}
            {name}
          </button>
        )} */}
    </>
  );
}

const ListWallet = [
  {
    idNum: 1,
    Name: "MetaMask",
    Icon: "https://app.kibble.exchange/images/icon/Metamask.svg",
    Id: "metaMask",
    Link: "https://metamask.io/download/",
  },
  // {
  //   idNum: 2,
  //   Name: "Safe",
  //   Icon: "/images/icon/safepal.jpeg",
  //   Id: "safe",
  //   Link: "https://www.safepal.com/en/download",
  // },
  // {
  //   idNum: 3,
  //   Name: "MetaMask Sol",
  //   Icon: "/images/metamask_sol.svg",
  //   Id: "metamasksol",
  //   Link: "#",
  // },
  // {
  //   idNum: 4,
  //   Name: "Solflare",
  //   Icon: "/images/icon/SolflareIcon.svg",
  //   Id: "Solflare",
  // },
  {
    idNum: 5,
    Name: "WalletConnect",
    Icon: "https://wiceye.net/images/icon/WalletConnect.png",
    Id: "walletConnect",
  },
];
