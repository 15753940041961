import { useEffect, useState } from "react";

import { DetailContainer, RowDetail } from "./styled";
import arrow from "../../../assets/Dashboard/Common/arrow_gray.svg";

import { useNavigate, useParams } from "react-router-dom";
import { BackToButton } from "../../Liquidity/Details/styled";
import InputBox from "../IDO/InputBox";
import BoxPoolDetail from "./BoxPoolDetail";
import { AVAX_API } from "../../../services/api/indexV2";
import dayjs from "dayjs";
import { useAppKitAccount } from "@reown/appkit/react";
import { useSelector } from "react-redux";

const IDODetail = () => {
  const navigate = useNavigate();
  const [poolInfo, setPoolInfo] = useState<any>(null);
  const [balanceInfo, setBalanceInfo] = useState<any>(null);
  const { id } = useParams();
  const { address } = useAppKitAccount();
  const { tokenLogin }: any = useSelector((state: any) => state.pool);

  const handleGetPool = async () => {
    try {
      const { data } = await AVAX_API.getDetailPool({ id });
      setPoolInfo({
        ...data,
        name: data?.round_name,
        describe: data?.description,
        total_saled: data?.total_saled_usd,
        total_raise: data?.total_raise_usd,
        start_date: dayjs(data?.start_date),
        rate: data?.price_usd,
        end_date: dayjs(data?.end_date),
        id: data?.id,
      });
    } catch (error) {
      console.log("handleGetPool err", error);
    }
  };

  const handleBalanceIDO = async () => {
    try {
      if (!tokenLogin) return;
      const { data } = await AVAX_API.getBalancesIDO({ round_id: id });
      setBalanceInfo(data);
    } catch (error) {
      console.log("handleGetPool err", error);
    }
  };

  useEffect(() => {
    handleGetPool();
    const intervalGetPool = setInterval(() => {
      handleGetPool();
    }, 5000);
    return () => {
      clearInterval(intervalGetPool);
    };
  }, [id, address]);

  useEffect(() => {
    handleBalanceIDO();
  }, [tokenLogin]);

  return (
    <DetailContainer>
      <BackToButton
        onClick={() => {
          navigate(-1);
        }}
      >
        <img src={arrow} alt="icon" />
        <p>Back to launchpad</p>
      </BackToButton>
      <RowDetail>
        <BoxPoolDetail poolInfo={poolInfo} />
        <InputBox
          poolInfo={poolInfo}
          onRefresh={() => {
            Promise.all([handleGetPool(), handleBalanceIDO()]);
          }}
          balanceInfo={balanceInfo}
        />
      </RowDetail>
    </DetailContainer>
  );
};

export default IDODetail;
