import {
  AddBtn,
  BackToButton,
  BoxEarnDetail,
  BtnGroup,
  ContentCaontainer,
  ContentLeft,
  ContentLeftTitle,
  ContentRight,
  EarnBtnGroup,
  EarnBtnRights,
  EarnPrice,
  LiquidityDetailsContainer,
  LiquidityDetailsWrapper,
  PollFarm,
  PollTitle,
  PoolAprBox,
  PoolAprGroups,
  PoolImagePair,
  PoolReserve,
  RatesTabContainer,
  RatesTabSelect,
  RationPriceBox,
  RemoveBtn,
  TabsBtn,
  TitleBox,
  TokentRates,
  TradeBtn,
  ViewContract,
  VolumBox,
  VolumContainer,
} from "./styled";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ButtonCommon } from "../../../Layout/styled";
import { useContext, useEffect, useState } from "react";
import LinkBtn from "../../../assets/liquidity/LinkBtnIcon.svg";
import LinkBtn_light from "../../../assets/liquidity/LinkBtnIcon_light.svg";
import IconLink from "../../../assets/liquidity/IconLink.svg";
import AddIcon from "../../../assets/liquidity/Add-icon.svg";
import InfoCircle from "../../../assets/liquidity/InfoCircle.svg";
import default_token_image from "../../../assets/Dashboard/Common/default-token-image.png";
import { convertFixed } from "../../../utils/convertFormatNumber";
import { LiquidityBg } from "../Add/styled";
import { useTonAddress } from "@tonconnect/ui-react";
import ModalOverlay from "../../../components/Common/ModalOverlay";
import ReviewLiquidityRemove from "../Review/Accordion/RemoveModal";
import arrow from "../../../assets/Dashboard/Common/arrow_gray.svg";
import { LoaderLP } from "../../../components/Loader";
import { ContextProviderWrapper } from "../../../components/Context";
import axios from "axios";
import { TextNamePool } from "../Tabs/Pools";
import { useAppKitAccount } from "@reown/appkit/react";

const LiquidityDetails = () => {
  const { id } = useParams();
  const { address } = useAppKitAccount();
  const [poolDetails, setPoolDetails] = useState<any>(null);
  const { theme }: any = useContext(ContextProviderWrapper);
  const [modalConfirmRemove, setModalConfirmRemove] = useState(false);
  const navigate = useNavigate();
  const [tabActive, setTabActive] = useState(1);
  const [infoNewToken, setInfoNewToken] = useState<any>({});
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [ratio, setRatio] = useState(0);
  const [tabActiveEarn, setTabActiveEarn] = useState(1)
  const handleFechtApi = async () => {
    const Ress = await axios.get(
      `https://app.kibble.exchange/pools/detail/${id}`
    );
    if (Ress.data) {
      setPoolDetails(Ress.data);
    }
  };
  useEffect(() => {
    if (!poolDetails) {
      return;
    }
    setRatio(poolDetails?.tokenX?.priceUsd / poolDetails?.tokenY?.priceUsd);
  }, [poolDetails]);
  useEffect(() => {
    handleFechtApi();
  }, [address, id]);

  return (
    <>
      <LiquidityDetailsContainer>
        <LiquidityBg className={theme}>
          <LiquidityDetailsWrapper className={theme}>
            <BackToButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={arrow} alt="icon" />
              <p>Back to pools</p>
            </BackToButton>
            <TitleBox>
              <h1>liquidity pool</h1>
              <p>Earn rewards by providing liquidity</p>
            </TitleBox>
            {poolDetails ? (
              <>
                <ContentCaontainer>
                  <ContentLeft>
                    <ContentLeftTitle>
                      <PollTitle>
                        <h3>
                          {isLoadingSearch ? (
                            <>
                              <span>?? / ??</span>
                            </>
                          ) : (
                            <>
                              <span>
                                {poolDetails?.tokenX?.symbol} /{" "}
                                {poolDetails?.tokenY?.symbol}
                              </span>
                            </>
                          )}
                        </h3>

                        <PoolImagePair>
                          {isLoadingSearch ? (
                            <>
                              <figure>
                                <img
                                  src={default_token_image}
                                  alt="icon"
                                  onError={(e) =>
                                    (e.currentTarget.src = default_token_image)
                                  }
                                />
                              </figure>
                              <figure>
                                <img
                                  src={default_token_image}
                                  alt="icon"
                                  onError={(e) =>
                                    (e.currentTarget.src = default_token_image)
                                  }
                                />
                              </figure>
                            </>
                          ) : (
                            <>
                              <figure>
                                <img
                                  src={
                                    `https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists-v2/refs/heads/main/logos/43114/${poolDetails?.tokenX?.address}.png` ||
                                    default_token_image
                                  }
                                  onError={(e) =>
                                    (e.currentTarget.src = default_token_image)
                                  }
                                />
                              </figure>
                              <figure>
                                <img
                                  src={
                                    `https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists-v2/refs/heads/main/logos/43114/${poolDetails?.tokenY?.address}.png` ||
                                    default_token_image
                                  }
                                  onError={(e) =>
                                    (e.currentTarget.src = default_token_image)
                                  }
                                />
                              </figure>
                            </>
                          )}
                        </PoolImagePair>
                      </PollTitle>
                      <Link
                        target="_blank"
                        to={`https://snowtrace.io/address/${poolDetails?.pairAddress}`}
                      >
                        <img
                          src={theme === "light" ? LinkBtn_light : LinkBtn}
                          alt="icon"
                        />
                      </Link>
                    </ContentLeftTitle>
                    <PollFarm className={theme}>
                      <div>
                        <h6>
                          LP fee{"  "}
                          <span>
                            {poolDetails?.lbBaseFeePct
                              ? Number(poolDetails?.lbBaseFeePct)
                              : "--"}
                            %
                          </span>
                        </h6>
                      </div>
                    </PollFarm>
                    <TokentRates>
                      <h1>Token rates</h1>
                      <RatesTabContainer>
                        <RatesTabSelect
                          l={Number(
                            poolDetails?.poolTokens?.filter(
                              (item: any) => item.balanceUSD !== "0"
                            ).length
                          )}
                        >
                          <TabsBtn
                            onClick={() => {
                              setTabActive(1);
                            }}
                            active={tabActive === 1}
                          >
                            {isLoadingSearch ? (
                              <>
                                <img
                                  src={default_token_image}
                                  alt="icon"
                                  onError={(e) =>
                                    (e.currentTarget.src = default_token_image)
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    `https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists-v2/refs/heads/main/logos/43114/${poolDetails?.tokenX?.address}.png` ||
                                    default_token_image
                                  }
                                  alt="icon"
                                  onError={(e) =>
                                    (e.currentTarget.src = default_token_image)
                                  }
                                />
                              </>
                            )}
                            <h3>
                              {isLoadingSearch ? (
                                <></>
                              ) : (
                                <>{poolDetails?.tokenX?.symbol}</>
                              )}
                            </h3>
                          </TabsBtn>
                          <TabsBtn
                            onClick={() => {
                              setTabActive(2);
                            }}
                            active={tabActive === 2}
                          >
                            {isLoadingSearch ? (
                              <>
                                <img
                                  src={default_token_image}
                                  alt="icon"
                                  onError={(e) =>
                                    (e.currentTarget.src = default_token_image)
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    `https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists-v2/refs/heads/main/logos/43114/${poolDetails?.tokenY?.address}.png` ||
                                    default_token_image
                                  }
                                  alt="icon"
                                  onError={(e) =>
                                    (e.currentTarget.src = default_token_image)
                                  }
                                />
                              </>
                            )}
                            <h3>
                              {isLoadingSearch ? (
                                <></>
                              ) : (
                                <>{poolDetails?.tokenY?.symbol}</>
                              )}
                            </h3>
                          </TabsBtn>
                        </RatesTabSelect>
                        <RationPriceBox>
                          <p>
                            <span>~{""}</span>
                            {tabActive === 1 && ratio
                              ? Number(ratio).toFixed(2)
                              : Number(1 / ratio).toFixed(2)}
                          </p>
                          {isLoadingSearch ? (
                            <>
                              <img
                                src={default_token_image}
                                alt="icon"
                                onError={(e) =>
                                  (e.currentTarget.src = default_token_image)
                                }
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={
                                  `https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists-v2/refs/heads/main/logos/43114/${
                                    tabActive === 1
                                      ? poolDetails?.tokenY?.address
                                      : poolDetails?.tokenX?.address
                                  }.png` || default_token_image
                                }
                                alt="icon"
                                onError={(e) =>
                                  (e.currentTarget.src = default_token_image)
                                }
                              />
                            </>
                          )}
                        </RationPriceBox>
                        <ViewContract>
                          <Link
                            target="_blank"
                            to={`https://snowtrace.io/token/${
                              tabActive === 1
                                ? poolDetails?.tokenX?.address
                                : poolDetails?.tokenY?.address
                            }`}
                          >
                            <img src={IconLink} alt="icon" /> View contract
                          </Link>
                        </ViewContract>
                      </RatesTabContainer>
                      <VolumContainer>
                        <VolumBox>
                          <h5>TVL</h5>
                          <h4>
                            $
                            {poolDetails?.liquidityUsd &&
                              convertFixed(Number(poolDetails?.liquidityUsd))}
                          </h4>
                        </VolumBox>
                        <VolumBox>
                          <h5>
                            Volume <span className="font-DMMono">24</span>h
                          </h5>
                          <h4>
                            $
                            {poolDetails?.liquidityUsd &&
                              convertFixed(Number(poolDetails?.liquidityUsd))}
                          </h4>
                        </VolumBox>
                      </VolumContainer>
                    </TokentRates>
                    <PoolReserve>
                      <h1>Pool reserve</h1>
                      <h3 className="no-border">
                        {poolDetails?.tokenX?.symbol || ""}
                        <span>
                          {convertFixed(Number(poolDetails?.reserveX))}
                        </span>
                      </h3>
                      <h3 className="no-border">
                        {poolDetails?.tokenY?.symbol || ""}
                        <span>
                          {convertFixed(Number(poolDetails?.reserveY))}
                        </span>
                      </h3>
                    </PoolReserve>
                    <BtnGroup>
                      <ButtonCommon width="30%" background="var(--Btn-LP)">
                        <Link to={`/swap?from=${""}`}>
                          <p style={{ color: "var(--Progress-Text)" }}>Trade</p>
                        </Link>
                      </ButtonCommon>
                      <ButtonCommon width="70%">
                        <Link
                          style={{
                            display: "flex",
                          }}
                          to="/liquidity/provide"
                        >
                          Add liquidity{" "}
                          <img
                            style={{
                              marginLeft: "5px",
                            }}
                            width={22}
                            src={AddIcon}
                            alt="icon"
                          />
                        </Link>
                      </ButtonCommon>
                    </BtnGroup>
                  </ContentLeft>
                  <ContentRight className={theme}>
                    <PollTitle>
                      <h3>MY POOL</h3>
                    </PollTitle>
                    <PoolAprGroups>
                      <PoolAprBox theme={theme}>
                        <p>
                          Pool APR <span className="font-DMMono">24</span>h
                        </p>
                        <h6>
                          {convertFixed(
                            ((poolDetails?.feesUsd * 365) /
                              poolDetails?.liquidityUsd) *
                              100
                          )}
                          %
                        </h6>
                      </PoolAprBox>
                      <PoolAprBox theme={theme}>
                        <p>
                          Pool APR <span className="font-DMMono">7</span>d
                        </p>
                        <h6>
                          {Number(poolDetails?.apy_7d * 100) &&
                            convertFixed(Number(poolDetails?.apy_7d * 100))}
                          %
                        </h6>
                      </PoolAprBox>
                      <PoolAprBox theme={theme}>
                        <p>
                          Pool APR <span className="font-DMMono">30</span>d
                        </p>
                        <h6>
                          {Number(poolDetails?.apy_30d * 100) &&
                            convertFixed(Number(poolDetails?.apy_30d * 100))}
                          %
                        </h6>
                      </PoolAprBox>
                    </PoolAprGroups>
                    <RatesTabContainer>
                      <RatesTabSelect>
                        <TabsBtn
                          onClick={() => {
                            setTabActiveEarn(1);
                          }}
                          active={tabActiveEarn === 1}
                        >
                          <h3>Liquidity Pool</h3>
                        </TabsBtn>
                        <TabsBtn
                        onClick={() => {
                          setTabActiveEarn(2);
                        }}
                        active={tabActiveEarn === 2}
                        >
                          <h3>Earn Farm</h3>
                        </TabsBtn>
                      </RatesTabSelect>
                      <EarnPrice>
                        $
                        {Number(poolDetails?.liquidityUsd || 0) &&
                          convertFixed(Number(poolDetails?.liquidityUsd || 0))}
                      </EarnPrice>
                      <EarnBtnGroup>
                        <p>
                          <img src={InfoCircle} alt="" />{" "}
                          {tabActiveEarn === 1
                            ? "Liquidity that you provided in the pool"
                            : "Liquidity that you deposited in the pool farms"}
                        </p>
                        <EarnBtnRights>
                          {/* {tabActiveEarn === 1 ? (
                            <>
                              {hasPositions && (
                                <ButtonCommon
                                  onClick={() => {
                                    setModalConfirmRemove(true);
                                  }}
                                  background="#43424A"
                                >
                                  Withdraw
                                </ButtonCommon>
                              )}
                              <ButtonCommon>
                                <Link to="/liquidity/provide">Add more</Link>
                              </ButtonCommon>
                            </>
                          ) : (
                            <>
                              <RemoveBtn>
                                <Link to="">
                                  <p>Remove</p>
                                </Link>
                              </RemoveBtn>
                              <TradeBtn>
                                <Link to={``}>
                                  <p>Claim rewards</p>
                                </Link>
                              </TradeBtn>
                              <AddBtn>
                                <Link to="">
                                  <p>Add from LP</p>
                                </Link>
                              </AddBtn>
                            </>
                          )} */}
                        </EarnBtnRights>
                      </EarnBtnGroup>
                    </RatesTabContainer>
                    <BoxEarnDetail>
                      <p>
                        <span>Pool share</span>{" "}
                        <span style={{ color: "var(--detail-Liquidity)" }}>
                          {/* {sharePercent?.toFixed(2)}% */}
                        </span>
                      </p>
                      <p>
                        <span>
                          {/* {Object.keys(infoNewToken).length > 0 &&
                          token0Address === undefined
                            ? infoNewToken?.symbol
                            : token0Address?.symbol}{" "} */}
                          provided
                        </span>{" "}
                        <span>{0}</span>
                      </p>
                      <p>
                        <span>
                          {/* {Object.keys(infoNewToken).length > 0 &&
                          token1Address === undefined
                            ? infoNewToken?.symbol
                            : token1Address?.symbol}{" "} */}
                          provided
                        </span>{" "}
                        <span>{0}</span>
                      </p>
                      <p>
                        <span>LP tokens</span>{" "}
                        <span>
                          {poolDetails?.lp_balance ? (
                            <>{convertFixed(Number(poolDetails?.lp_balance))}</>
                          ) : (
                            "--"
                          )}
                        </span>
                      </p>
                    </BoxEarnDetail>
                  </ContentRight>
                </ContentCaontainer>
              </>
            ) : (
              <>
                <LoaderLP />
              </>
            )}
          </LiquidityDetailsWrapper>
        </LiquidityBg>
      </LiquidityDetailsContainer>
      {/* <ModalOverlay
        component={
          <ReviewLiquidityRemove
            pool={poolDetails}
            assetsInfo={assetsInfo}
            setModalConfirmRemove={setModalConfirmRemove}
            modalConfirmRemove={modalConfirmRemove}
            token0Address={token0Address}
            token1Address={token1Address}
            infoNewToken={Object.keys(infoNewToken).length > 0 && infoNewToken}
            isLoadingSearch={isLoadingSearch}
            ratio0_1={ratio0_1}
            ratio1_0={ratio1_0}
          />
        }
        open={modalConfirmRemove}
        setOpen={setModalConfirmRemove}
        title={"Withdraw liquidity"}
        width="400px"
      /> */}
    </>
  );
};

export default LiquidityDetails;
