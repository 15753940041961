import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Pool, Asset } from "../store/api/dexApiTypes";
import { usePairBalances } from "./usePairBalances";
import { useTonAddress } from "@tonconnect/ui-react";

const TON_ADDRESS: any = process.env.REACT_APP_TON_ADDRESS;
const KIBBLE_ADDRESS: any = process.env.REACT_APP_USDC_ADDRESS;

export interface RouteAssets {
  token0Address: string;
  setToken0Address: (value: string) => void;
  token1Address: string;
  setToken1Address: (value: string) => void;
  token0Balance: any;
  token1Balance: any;
  token0Amount: number;
  setToken0Amount: (value: number) => void;
  token1Amount: number;
  setToken1Amount: (value: number) => void;
  poolByAssetsAddressesHashMap: Map<string, Map<string, Pool>>;
}

export const useRouteAssets = (): RouteAssets => {
  const address: any = useTonAddress();

  const [searchParams] = useSearchParams();
  let t0 = searchParams?.get("t0");
  let t1 = searchParams?.get("t1");

  const getSelectedFromLocal = JSON.parse(
    localStorage.getItem("avax-selectedTokens") || "{}"
  );

  const [token0Address, setToken0Address] = useState(
    t0 || getSelectedFromLocal.from
  );
  const [token1Address, setToken1Address] = useState(
    t1 || getSelectedFromLocal.to
  );
  const [token0Amount, setToken0Amount] = useState(0);
  const [token1Amount, setToken1Amount] = useState(0);

  const { token0Balance, token1Balance } = usePairBalances({
    token0Address: token0Address,
    token1Address: token1Address,
  });

  const poolByAssetsAddressesHashMap = new Map<string, Map<string, Pool>>();

  useEffect(() => {
    t0 = token0Address;
    t1 = token1Address;
    const url = new URL(window.location.toString());
    url.searchParams.set("t0", t0 || TON_ADDRESS);
    url.searchParams.set("t1", t1 || KIBBLE_ADDRESS);
    // window.history.pushState({}, "", url);
  }, [token0Address, token1Address]);

  return {
    token0Address,
    setToken0Address,
    token1Address,
    setToken1Address,
    token0Balance,
    token1Balance,
    token0Amount,
    setToken0Amount,
    token1Amount,
    setToken1Amount,
    poolByAssetsAddressesHashMap,
  };
};
