import { Controller, useForm } from "react-hook-form";
import {
  ChooseTokenContainer,
  ChooseTokenExternalLink,
  ChooseTokenSearch,
  ChooseTokenWrapper,
  FavoriteToken,
  FavoriteWrapper,
  LikeButton,
  LikeRemove,
  TokenAction,
  TokenList,
  TokenNewInfo,
} from "./styled";
import { useContext, useEffect, useRef, useState } from "react";
import { Asset, Pool } from "../../../store/api/dexApiTypes";
import InputCommon from "../../../components/Common/Input";
import search_icon from "../../../assets/Dashboard/Common/search_icon.svg";
import default_token_image from "../../../assets/Dashboard/Common/default-token-image.png";
import { Link } from "react-router-dom";
import external_link_white from "../../../assets/Dashboard/Common/external_link_white.svg";
import clear from "../../../assets/Dashboard/Common/clear.svg";
import { ContextProviderWrapper } from "../../../components/Context";
import _ from "lodash";
import { useSelector } from "react-redux";
import { convertFixed } from "../../../utils/convertFormatNumber";
import { Coins } from "ton3-core";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch } from "../../../store/store";
import { getDataListToken } from "../../../store/features/poolsSlice";
import { AVAX_API } from "../../../services/api/indexV2";
import { useAppKitAccount } from "@reown/appkit/react";

export type TokenModalProps = {
  setCurrentAsset: (x: string) => void;
  otherCurrentAssetKey: string;
  setOtherCurrentAsset?: (x: string) => void;
  isFromModal?: boolean;
  setOpenFromModal?: any;
  setAmountFrom?: any;
  setAmountTo?: any;
  // For liq page
  isLiq?: any;
  isModal?: any;
  // Favorite
  listFavorite?: any;
  setListFavorite?: any;
  // Checking token
  setCheckingToken?: any;
  otherToAssetKey?: string;
};

type FormValuesProps = {
  search: string;
};

const ChooseToken = ({
  isFromModal = false,
  otherCurrentAssetKey,
  setCurrentAsset,
  setOpenFromModal,
  setAmountFrom,
  setAmountTo,
  isModal,
  listFavorite,
  setListFavorite,
  otherToAssetKey,
}: TokenModalProps) => {
  const { theme } = useContext(ContextProviderWrapper)!;
  const { listTokens }: any = useSelector((state: any) => state.pool);
  const defaultValues = {
    search: "",
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
    mode: "onChange",
  });
  const { control, watch, reset } = methods;

  const [displayAssets, setDisplayAssets] = useState<Asset[]>([]);
  const search = useRef("");
  search.current = watch("search", "");
  const dispatch = useDispatch();
  const { address } = useAppKitAccount();

  const handleGetListToken = async (tokens: any[], strSearch: string) => {
    try {
      const { data } = await AVAX_API.listToken({
        chainId: process.env.REACT_APP_CHAIN_ID,
        symbolOrAddress: strSearch?.toLocaleLowerCase(),
      });
      const newListWithBalance = data?.map((item: any) => {
        return {
          ...item,
          balance:
            tokens.find(
              (token: any) =>
                token?.asset?.address === item.address ||
                process.env.REACT_APP_TON_ADDRESS === item.address
            )?.amount?.amount || 0,
          price:
            tokens.find(
              (token: any) =>
                token?.asset?.address === item.address ||
                process.env.REACT_APP_TON_ADDRESS === item.address
            )?.price || 0,
        };
      });
      // sort up to down with balance
      setDisplayAssets(newListWithBalance);
      // dispatch(
      //   getDataListToken(
      //     _.uniqBy(
      //       _.orderBy(newListWithBalance, "balance", "desc").concat(listTokens),
      //       "address"
      //     )
      //   )
      // );
    } catch (error) {
      
      console.log("handleGetListToken err", error);
      
    }
  };

  const handleGetBalanceTokenInWallet = async (strSearch: string) => {
    try {
      if (!address) {
        await handleGetListToken([], strSearch);
        return;
      }
      const params: any = [`AVAX_CCHAIN.${address}`];
      let convertParams = "";
      params.forEach((element: string) => {
        convertParams += `address=${element}&`;
      });
      const { data } = await AVAX_API.getBalanceTokens(convertParams);
      await handleGetListToken(data?.wallets[0]?.balances || [], strSearch);
    } catch (error) {
      
      console.log("handleGetBalanceTokenInWallet err", error);
      
    }
  };

  // Debounce callback
  const debounced = useDebouncedCallback(
    // function
    (value) => {
      handleGetBalanceTokenInWallet(value);
    },
    // delay in ms
    500
  );

  useEffect(() => {
    if (!search.current) {
      setDisplayAssets(listTokens);
    }
  }, [listTokens, search.current, otherCurrentAssetKey, isFromModal]);

  const changeSelected = async (assetContractAddress: string, token?: any) => {
    if (
      assetContractAddress === otherCurrentAssetKey ||
      assetContractAddress === otherToAssetKey
    ) {
      setOpenFromModal(false);
      return;
    }
    setCurrentAsset(assetContractAddress);
    const getSelectedFromLocal = JSON.parse(
      localStorage.getItem("avax-selectedTokens") || "{}"
    );
    const selectedTokens = {
      from: isModal === 1 ? assetContractAddress : getSelectedFromLocal.from,
      to: isModal === 2 ? assetContractAddress : getSelectedFromLocal.to,
    };
    localStorage.setItem("avax-selectedTokens", JSON.stringify(selectedTokens));
    setOpenFromModal(false);
    setAmountFrom(0);
    setAmountTo(0);
  };

  // Handle add new Info Token
  const [infoNewToken, setInfoNewToken] = useState<any>({});

  const [saveSearch, setSaveSearch] = useState("");

  useEffect(() => {
    if (search.current.length > 47) {
      setSaveSearch(search.current);
    } else {
      setInfoNewToken({});
    }
  }, [search.current, saveSearch]);

  // remove same item in listFavorite
  const uniqueFavorites = listFavorite?.filter(
    (item: any, index: any, self: any) =>
      index === self.findIndex((t: any) => t.address === item.address)
  );

  // Handle favorite token
  const handleAddToFavorites = (asset: any) => {
    const updatedFavorites = [
      ...uniqueFavorites,
      { ...asset, is_favorite: true },
    ];
    localStorage.setItem("avax-listFavorite", JSON.stringify(updatedFavorites));
    setListFavorite(updatedFavorites);
  };

  const handleRemoveFromFavorites = (contractAddress: any) => {
    const updatedFavorites = uniqueFavorites.filter(
      (item: any) => item.address !== contractAddress
    );
    localStorage.setItem("avax-listFavorite", JSON.stringify(updatedFavorites));
    setListFavorite(updatedFavorites);
  };

  const [inputValue, setInputValue] = useState("");

  const onChangeValueSearch = (e: any) => {
    setInputValue(e.target.value);
    debounced(e.target.value);
  };

  useEffect(() => {
    const storedFavorites: any = JSON.parse(
      localStorage.getItem("avax-listFavorite") || "[]"
    );
    if (storedFavorites.length > 0 && setListFavorite) {
      setListFavorite(storedFavorites);
    }
  }, []);

  useEffect(() => {
    if (uniqueFavorites?.length > 0) {
      localStorage.setItem("avax-listFavorite", JSON.stringify(listFavorite));
    }
  }, [listFavorite]);

  useEffect(() => {
    if (!isFromModal) {
      setInputValue("");
      debounced("");
    }
  }, [isFromModal]);

  return (
    <ChooseTokenContainer>
      <ChooseTokenWrapper className={theme}>
        <FavoriteWrapper className={theme}>
          <p>Favorite</p>
          {uniqueFavorites?.length < 1 ? (
            <p>You don't have any favorite token</p>
          ) : (
            <FavoriteToken className={theme}>
              {uniqueFavorites.map((asset: any, index: any) => {
                return (
                  <li
                    onClick={() => {
                      changeSelected(asset?.address);
                    }}
                    key={index}
                  >
                    <figure>
                      <img
                        src={asset?.img}
                        alt="icon"
                        onError={(e) =>
                          (e.currentTarget.src = default_token_image)
                        }
                      />
                    </figure>
                    <p>{asset?.symbol}</p>
                    <LikeRemove
                      onClick={(e) => {
                        handleRemoveFromFavorites(asset?.address);
                        e.stopPropagation();
                      }}
                    >
                      -
                    </LikeRemove>
                  </li>
                );
              })}
            </FavoriteToken>
          )}
        </FavoriteWrapper>
        <ChooseTokenSearch className={theme}>
          <Controller
            name="search"
            control={control}
            render={({ field }: any) => (
              <InputCommon
                {...field}
                placeHolder="Search by symbol or name..."
                name="search"
                allowClear={{ clearIcon: <img src={clear} alt="icon" /> }}
                prefix={<img src={search_icon} alt="icon" />}
                onChange={onChangeValueSearch}
                value={inputValue}
              />
            )}
          />
          <p>
            <span>Search token</span>
          </p>
        </ChooseTokenSearch>
        {Object.keys(infoNewToken).length > 0 ? (
          <>
            <TokenNewInfo>
              <div>
                <figure>
                  <img
                    src={infoNewToken.img}
                    alt={infoNewToken.display_name}
                    onError={(e) => (e.currentTarget.src = default_token_image)}
                  />
                </figure>
                <div>
                  <p>{infoNewToken.display_name}</p>
                  <p>{infoNewToken.symbol}</p>
                </div>
              </div>
            </TokenNewInfo>
          </>
        ) : (
          <></>
        )}
        <>
          <TokenList className={theme}>
            {search.current.length === 48 ? (
              <>
                {displayAssets.map((asset: any, index: any) => {
                  return (
                    <li
                      onClick={() => {
                        changeSelected(asset?.address, asset);
                      }}
                      key={index}
                    >
                      <div>
                        <figure>
                          <img
                            src={asset?.img}
                            alt="icon"
                            onError={(e) =>
                              (e.currentTarget.src = default_token_image)
                            }
                          />
                        </figure>
                        <div>
                          <p>{asset?.symbol}</p>
                          <span>{asset?.name}</span>
                        </div>
                      </div>
                      <TokenAction className={theme}>
                        <div>
                          <p>
                            {convertFixed(
                              Number(
                                Coins.fromNano(asset?.balance, asset?.decimals)
                              )
                            )}
                          </p>
                          <p>
                            $
                            {Number(
                              asset?.price *
                                Number(
                                  Coins.fromNano(
                                    asset?.balance,
                                    asset?.decimals
                                  )
                                )
                            ).toFixed(2)}
                          </p>
                        </div>
                      </TokenAction>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                {displayAssets.map((asset: any, index: any) => {
                  return (
                    <li
                      onClick={() => {
                        changeSelected(asset?.address, asset);
                      }}
                      key={index}
                    >
                      <div>
                        <figure>
                          <img
                            src={asset?.img}
                            alt="icon"
                            onError={(e) =>
                              (e.currentTarget.src = default_token_image)
                            }
                          />
                        </figure>
                        <div>
                          <p>
                            {asset?.symbol}
                            <Link
                              target="_blank"
                              to={`https://snowscan.xyz/token/${asset?.address}`}
                            >
                              <img src={external_link_white} alt="icon" />
                            </Link>
                          </p>
                          <ChooseTokenExternalLink
                            onClick={(e: any) => e.stopPropagation()}
                          >
                            <span>{asset?.name}</span>
                          </ChooseTokenExternalLink>
                        </div>
                      </div>
                      <TokenAction className={theme}>
                        <div>
                          <p>
                            {convertFixed(
                              Number(
                                Coins.fromNano(asset?.balance, asset?.decimals)
                              )
                            )}
                          </p>
                          <p>
                            $
                            {Number(
                              asset?.price *
                                Number(
                                  Coins.fromNano(
                                    asset?.balance,
                                    asset?.decimals
                                  )
                                )
                            ).toFixed(2)}
                          </p>
                        </div>
                        <LikeButton
                          className={
                            uniqueFavorites?.some(
                              (item: any) =>
                                item.is_favorite &&
                                item.address === asset?.address
                            )
                              ? `active ${theme}`
                              : theme
                          }
                          onClick={(e) => {
                            if (
                              JSON.parse(
                                localStorage.getItem("avax-listFavorite") || "[]"
                              ).some(
                                (item: any) => item.address === asset?.address
                              )
                            ) {
                              handleRemoveFromFavorites(asset?.address);
                            } else {
                              handleAddToFavorites(asset);
                            }
                            e.stopPropagation();
                          }}
                        />
                      </TokenAction>
                    </li>
                  );
                })}
              </>
            )}
          </TokenList>
        </>
      </ChooseTokenWrapper>
    </ChooseTokenContainer>
  );
};

export default ChooseToken;
