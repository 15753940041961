import { useAppKitAccount } from "@reown/appkit/react";
import { useEffect, useState } from "react";
import { Coins } from "ton3-core";
import { useBalance, useContractRead } from "wagmi";

export interface PairData {
  token0Address?: any;
  token1Address?: any;
  isLoading?: boolean;
  address?: string;
}

export interface usePairBalancesData {
  token0Balance: Coins;
  token1Balance: Coins;
  isLoading?: boolean;
  address?: string;
}

export const usePairBalances = (data: PairData) => {
  const { address }: any = useAppKitAccount();
  const [token0Balance, setToken0Balance] = useState<any>(null);
  const [token1Balance, setToken1Balance] = useState<any>(null);

  const balance0: any = useBalance({
    address: address,
    token:
      data?.token0Address === process.env.REACT_APP_TON_ADDRESS
        ? ""
        : data?.token0Address,
    query: {
      refetchInterval: 2500,
      refetchOnMount: "always",
    },
  });

  const balance1: any = useBalance({
    address: address,
    token:
      data?.token1Address === process.env.REACT_APP_TON_ADDRESS
        ? ""
        : data?.token1Address,
    query: {
      refetchInterval: 2500,
    },
  });

  const handleGetBalance = () => {
    setToken0Balance(balance0);
    setToken1Balance(balance1);
  };

  useEffect(() => {
    handleGetBalance();
  }, [balance0.data, balance1.data, address, data.isLoading]);

  return {
    token0Balance: token0Balance?.data?.formatted || 0,
    token1Balance: token1Balance?.data?.formatted || 0,
  };
};
