import { useContext, useEffect } from "react";
import {
  ModalClose,
  ModalHeader,
  ModalOverlayContainer,
  ModalOverlayInner,
  ModalOverlayWrapper,
  ModalTitle,
} from "./styled";
import { ContextProviderWrapper } from "../../Context";

const ModalOverlay = ({
  component,
  open,
  setOpen,
  title,
  width,
  onClickParent,
  top,
}: any) => {
  const { theme, isMobile } = useContext(ContextProviderWrapper)!;

  useEffect(() => {
    if (open) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [open]);

  return (
    <ModalOverlayContainer open={open} onClick={onClickParent}>
      <ModalOverlayWrapper
        className={open && isMobile ? "active" : ""}
        width={width}
        style={{
          top,
        }}
      >
        <ModalOverlayInner className={theme}>
          <ModalHeader>
            <ModalTitle className={theme}>{title}</ModalTitle>
            <ModalClose
              className={theme}
              onClick={() => {
                setOpen(false);
              }}
            />
          </ModalHeader>
          {component}
        </ModalOverlayInner>
      </ModalOverlayWrapper>
    </ModalOverlayContainer>
  );
};

export default ModalOverlay;
