import styled from "styled-components";

export const HeaderNotification = styled.div`
  svg {
    animation: ringring 2s linear infinite;
    flex-shrink: 0;
  }
  &.light {
    background: linear-gradient(
        90deg,
        rgba(232, 65, 66, 0) 0%,
        rgba(232, 65, 66, 0.05) 100%,
        rgba(232, 65, 66, 0) 100%
      ),
      rgba(217, 217, 217, 0.5);
    p {
      color: #000;
    }
    a {
      color: #b32222;
    }
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 1280px;
    width: 100%;
    padding: 0 15px;
  }
  background: linear-gradient(
      90deg,
      rgba(232, 65, 66, 0) 0%,
      rgba(232, 65, 66, 0.05) 50%,
      rgba(232, 65, 66, 0) 100%
    ),
    rgba(217, 217, 217, 0.1);
  backdrop-filter: blur(12px);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  p {
    font-size: 14px;
    color: #fff;
    span {
      font-weight: bold;
    }
  }
  a {
    color: #f9a8a8;
    text-decoration-line: underline;
    text-underline-offset: 2px;
    font-size: 14px;
    transition: all 0.25s linear;
    &:hover {
      opacity: 0.75;
      text-decoration-line: none;
    }
  }
  @media screen and (max-width: 767px) {
    & > div {
      max-width: unset;
      gap: 10px;
    }
  }
  @keyframes ringring {
    0% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(5deg);
    }
    100% {
      transform: rotate(-10deg);
    }
  }
`;
export const MarqueeItemHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 30px;
`;
