import { useContext, useEffect, useState } from "react";
import {
  Info,
  TabInfo,
  WalletActions,
  WalletAddress,
  WalletAssetItem,
  WalletAssetToken,
  WalletAssets,
  WalletBalance,
  WalletInfoStyle,
  WalletList,
  WalletParent,
} from "./styled";
import { shortenAddress } from "../../../utils/addressUser";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import { CopyOutlined } from "@ant-design/icons";
import { ButtonCommon } from "../../../Layout/styled";
import TextBalance from "../../Common/TextBalance";
import { Link } from "react-router-dom";
import { convertFixed } from "../../../utils/convertFormatNumber";
import { Coins } from "ton3-core";
import Loading from "../../Loading";
import { ContextProviderWrapper } from "../../Context";
import { useBalance } from "wagmi";
import formatNumber from "../../../utils/formatNumber";
import { useAppKitAccount } from "@reown/appkit/react";
import { useDisconnect } from "@reown/appkit/react";
import { getDataTokenLogin } from "../../../store/features/poolsSlice";
import { useDispatch } from "../../../store/store";

export default function WalletInfo({ onCloseModal, balance }: any) {
  const { theme } = useContext(ContextProviderWrapper)!;
  const [tabActive, setTabActive] = useState(0);
  const [balanceOfKibble, setValanceOfKibble] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listAsset, setListAsset] = useState([]);
  const { address }: any = useAppKitAccount();
  const { disconnect } = useDisconnect();
  const result = useBalance({
    address: address,
  });
  const dispatch = useDispatch();

  return (
    <WalletInfoStyle
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      {/* <TabInfo className={theme}>
        {TABFILTER.map((item, index) => {
          return (
            <li
              className={item.value === tabActive ? "active" : ""}
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                setTabActive(item.value);
              }}
            >
              {item.text}
            </li>
          );
        })}
      </TabInfo> */}
      {tabActive === 0 ? (
        <Info>
          <WalletAddress className={theme}>
            <div>
              <h1>
                Address:<span>{shortenAddress(address)}</span>
              </h1>
            </div>
            <CopyToClipboard
              text={`${address}`}
              onCopy={() => {
                toast.success("Copied !!");
              }}
            >
              <div
                style={{
                  marginRight: "14px",
                  color: "#92929E",
                  cursor: "pointer",
                }}
              >
                <span>Copy</span>
                <CopyOutlined />
              </div>
            </CopyToClipboard>
          </WalletAddress>
          <WalletBalance>
            <h2>AVAX balance</h2>
            {/* {balanceOfKibble && (
              <TextBalance
                unit={"KIB"}
                balance={convertFixed(
                  Number(Coins.fromNano(balanceOfKibble, 9))
                )}
              />
            )} */}
            {address && (
              <TextBalance
                balance={`${formatNumber(result?.data?.formatted)} ${
                  result.data?.symbol || ""
                }`}
                isImage
              />
            )}
          </WalletBalance>
          <WalletActions>
            <ButtonCommon
              background="#E94A4A"
              onClick={() => {
                dispatch(getDataTokenLogin(null));
                localStorage.clear();
                disconnect();
                onCloseModal();
              }}
              disabled={loading}
            >
              <Loading status={loading} content="Disconnect" />
            </ButtonCommon>
            <ButtonCommon onClick={onCloseModal}>
              <Link to={"/swap"}>
                <span
                  style={{
                    color: "#fff",
                  }}
                >
                  Go to Swap
                </span>
              </Link>
            </ButtonCommon>
          </WalletActions>
        </Info>
      ) : (
        <WalletAssets>
          <WalletParent>
            <WalletList>
              {listAsset.map((item: any, index: number) => {
                return (
                  <WalletAssetItem key={index} className={theme}>
                    <WalletAssetToken>
                      <h1>{item.symbol}</h1>
                      <h2>
                        {convertFixed(
                          Number(Coins.fromNano(item.balance, item?.decimals))
                        )}
                      </h2>
                    </WalletAssetToken>
                    <h2>
                      ~$
                      {Number(
                        item?.dex_price_usd *
                          Number(Coins.fromNano(item.balance, item?.decimals))
                      ).toFixed(2)}
                    </h2>
                  </WalletAssetItem>
                );
              })}
            </WalletList>
          </WalletParent>
        </WalletAssets>
      )}
    </WalletInfoStyle>
  );
}
