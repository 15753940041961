const formatNumber = (num: any) => {
    if (num) {
      let number = Number(num);
      return new Intl.NumberFormat("en-US").format(number);
    } else {
      return "0";
    }
  };
  
  export const formatMiniNumber = (num: any) => {
    if(!num){
      return
    }
    let decimalStr = num?.toFixed(20);
  
    let dotIndex = decimalStr?.indexOf(".");
  
    let zeroCount = 0;
    for (let i = dotIndex + 1; i < decimalStr?.length; i++) {
      if (decimalStr[i] === "0") {
        zeroCount++;
      } else {
        break;
      }
    }
  
    let firstNonZeroIndex = dotIndex + 1 + zeroCount;
    let significantPart = decimalStr.substring(
      firstNonZeroIndex,
      firstNonZeroIndex + 3
    );
  
    let subscriptZeroCount = (zeroCount) > 0 ? (zeroCount)
      .toString()
      .split("")
      .map((c) => String.fromCharCode(8320 + parseInt(c)))
      .join("") : "";
  
    let integerPart = decimalStr.substring(0, dotIndex);
  
    if (zeroCount > 0 && significantPart) {
      return `${integerPart}.0${subscriptZeroCount}${significantPart}`;
    } else {
      return new Intl.NumberFormat("en-US").format(num);
    }
  };
  
  export const formatNuberMega = (num?: number, noText?: boolean) => {
    if (num) {
      if (noText) {
        if (num >= 1000000000000) {
          let number = Number(num) / 1000000000000;
          return `${new Intl.NumberFormat("en-US").format(number)}`;
        }else if (num >= 1000000000) {
          let number = Number(num) / 1000000000;
          return `${new Intl.NumberFormat("en-US").format(number)}`;
        } else if (num >= 1000000) {
          let number = Number(num) / 1000000;
          return `${new Intl.NumberFormat("en-US").format(number)}`;
        } else if (num >= 1000) {
          let number = Number(num) / 1000;
          return `${new Intl.NumberFormat("en-US").format(number)}`;
        } else {
          return `${new Intl.NumberFormat("en-US").format(num)}`;
        }
      } else {
        if (num >= 1000000000000) {
          let number = Number(num) / 1000000000000;
          return `${new Intl.NumberFormat("en-US").format(number)}T`;
        }else if (num >= 1000000000) {
          let number = Number(num) / 1000000000;
          return `${new Intl.NumberFormat("en-US").format(number)}B`;
        } else if (num >= 1000000) {
          let number = Number(num) / 1000000;
          return `${new Intl.NumberFormat("en-US").format(number)}M`;
        } else if (num >= 1000) {
          let number = Number(num) / 1000;
          return `${new Intl.NumberFormat("en-US").format(number)}K`;
        } else {
          return `${new Intl.NumberFormat("en-US").format(num)}`;
        }
      }
    } else {
      return "0";
    }
  };
  
  
  export default formatNumber;
  