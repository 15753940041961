import Footer from "./components/Footer";
import { AppKitNetwork, avalanche } from "@reown/appkit/networks";
import ViewProject from "./page/Launchpad/ViewProject";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import { createAppKit, modal } from "@reown/appkit/react";
import { WagmiProvider } from "wagmi";
import { AppContainer } from "./styled";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ContextProvider, ContextProviderWrapper } from "./components/Context";
import { Toaster } from "react-hot-toast";
import { useContext, useEffect } from "react";
import LayoutDashboard from "./Layout/Dashboard";
import Swap from "./page/Swap";
import Farm from "./page/Farm";
import Launchpad from "./page/Launchpad";
import Liquidity from "./page/Liquidity";
import Community from "./page/Community";
import { store } from "./store/store";
import { Provider } from "react-redux";
import IDODetail from "./page/Launchpad/Detail";
import AddLiquidity from "./page/Liquidity/Add";
import CreatePoolLiquidity from "./page/Liquidity/CreatePool";
import LiquidityDetails from "./page/Liquidity/Details";
import LayoutLiquidity from "./Layout/Liquidity";
import ChartCommon from "./page/Chart";
import GlobalStyle from "./GlobalStyle";
import Kibble from "./page/Kibble";
import SendNoticePage from "./page/SendNotice";

global.Buffer = Buffer;
global.process = process;

const queryClient = new QueryClient();
const projectId = "c84624b4b35e21d900b3f8f307122687";
const metadata = {
  name: "Kibble Avax",
  description: "Kibble Avax",
  url: "https://app.kibble.exchange/",
  icons: [""],
};

const networks: any = [avalanche as AppKitNetwork];
const wagmiAdapter = new WagmiAdapter({
  networks,
  projectId,
});

export const modalAppkit = createAppKit({
  adapters: [wagmiAdapter],
  networks,
  projectId,
  metadata,
  themeMode: "dark",
  features: {
    swaps: false,
    analytics: false,
    onramp: false,
    socials: false,
    emailShowWallets: false,
    email: false,
  },
});

const App = () => {
  const { pathname } = useLocation();
  const { theme, isMobile } = useContext(ContextProviderWrapper)!;

  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <Provider store={store}>
      <WagmiProvider config={wagmiAdapter.wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <TonConnectUIProvider manifestUrl="https://dev-kibble-avax.netlify.app/static/tonconnect-manifest.json">
            <Toaster
              position="top-right"
              reverseOrder={false}
              toastOptions={{
                style: {
                  fontFamily: "Syne",
                },
              }}
            />
            <GlobalStyle theme={theme} />
            <AppContainer
              className={`${theme} ${
                pathname === "/kibble" && theme === "dark"
                  ? "is-kibble"
                  : pathname === "/kibble" && theme === "light"
                  ? `is-kibble ${theme}`
                  : ""
              }`}
              pathname={pathname === "/"}
            >
              <div
                style={{
                  mixBlendMode:
                    theme === "light" ? "color-burn" : "color-dodge",
                }}
                className="img-k-top-right"
              >
                {theme === "light" ? (
                  <img src="/assets/images/common/img-k-light.png" alt="" />
                ) : (
                  <img src="/assets/images/common/img-k.png" alt="" />
                )}
              </div>
              <div
                style={{
                  mixBlendMode:
                    theme === "light" ? "color-burn" : "color-dodge",
                }}
                className="img-k-bottom-left"
              >
                {theme === "light" ? (
                  <img src="/assets/images/common/img-k-light.png" alt="" />
                ) : (
                  <img src="/assets/images/common/img-k.png" alt="" />
                )}
              </div>
              <Routes>
                <Route element={<LayoutDashboard />}>
                  <Route index path="/swap/avax" element={<Swap />} />
                  <Route index path="/chart/avax" element={<ChartCommon />} />
                  <Route path="/staking/avax" element={<Farm />} />
                  <Route path="/launchpad/avax" element={<Launchpad />} />
                  <Route path="/kibble/avax" element={<Kibble />} />
                  <Route path="/launchpad/avax/:id" element={<IDODetail />} />
                  <Route
                    path="/launchpad/avax/:id/view"
                    element={<ViewProject />}
                  />
                  <Route path="/liquidity/avax" element={<LayoutLiquidity />}>
                    <Route index element={<Liquidity />} />
                    <Route path="provide" element={<AddLiquidity />} />
                    <Route path="details/:id" element={<LiquidityDetails />} />
                    <Route path="init/avax" element={<CreatePoolLiquidity />} />
                  </Route>
                  <Route path="community-tool/avax" element={<Community />} />
                </Route>
                <Route path="/*" element={<Navigate to="/swap/avax" />} />
                {/* <Route path="/1872287300" element={<SendNoticePage />} /> */}
              </Routes>
              {!isMobile && <Footer />}
            </AppContainer>
          </TonConnectUIProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </Provider>
  );
};

export default App;
