import styled from "styled-components";

export const AppContainer = styled.div<{ pathname: any }>`
  min-height: 100vh;
  background: linear-gradient(180deg, #230000 0%, #000 80.61%);
  overflow: hidden;
  position: relative;
  &.light {
    background: #f3f3f3;
  }
  .img-k-top-right {
    position: absolute;
    max-width: 1000px;
    right: -100px;
    top: 0;
    stroke-width: 1px;
    mix-blend-mode: color-dodge;
    pointer-events: none;
    img {
      width: 100%;
    }
  }
  .img-k-bottom-left {
    position: absolute;
    max-width: 1000px;
    left: -600px;
    stroke-width: 1px;
    mix-blend-mode: color-dodge;
    pointer-events: none;
    bottom: 50px;
    img {
      width: 100%;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    min-height: calc(100vh - 100px);
  }
  @media screen and (max-width: 767px) {
    min-height: 100vh;
    &.is-kibble {
      background-color: #1a1a1d;
      &.light {
        background-color: #e84142;
      }
    }
  }
`;
