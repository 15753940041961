import { Dispatch, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface DriverState {
  allPools: any;
  poolsWallet: any;
  listTokens: any;
  listHistory: any;
  tokenLogin: any;
  kibPrice: any
}

const initialState: DriverState = {
  allPools: [],
  poolsWallet: [],
  listTokens: [],
  listHistory: [],
  tokenLogin: null,
  kibPrice: null,
};

const poolSlice = createSlice({
  name: "poolSlice",
  initialState,
  reducers: {
    getAllPools(state, action) {
      state.allPools = action.payload;
    },
    getPoolsWallet(state, action) {
      state.poolsWallet = action.payload;
    },
    getListToken(state, action) {
      state.listTokens = action.payload;
    },
    getListHistory(state, action) {
      state.listHistory = action.payload;
    },
    getTokenLogin(state, action) {
      state.tokenLogin = action.payload;
    },
    getKIBPrice(state, action) {
      state.kibPrice = action.payload;
    },
  },
});

export function getDataAllPools(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(poolSlice.actions.getAllPools(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataListToken(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(poolSlice.actions.getListToken(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataPoolsWallet(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(poolSlice.actions.getPoolsWallet(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataListHistory(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(poolSlice.actions.getListHistory(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataTokenLogin(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(poolSlice.actions.getTokenLogin(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getKIBPrice() {
  return async (dispatch: Dispatch) => {
    try {
      const response: any = await axios.get(
        `https://aggregator.kibble.exchange/api/kib-price`,
        {
          headers: {
            Accept: "*",
            "Content-Type": "application/json",
          },
        }
      );
      const currentPrice = response.data.price;
      dispatch(poolSlice.actions.getKIBPrice(currentPrice));
    } catch (error) {
      console.log(error);
    }
  };
}

const poolReducer = poolSlice.reducer;

export default poolReducer;
