import { MyPoolPosition, TabMyPoolContainer } from "./styled";
import TableCommon from "../../../../components/Common/TableCommon";
import { ContextProviderWrapper } from "../../../../components/Context";
import { useContext, useEffect, useState } from "react";
import {
  NoData,
  PoolInfoTitle,
  PoolsFarmAndFee,
  PoolsImage,
  PoolsInfo,
  PoolsInfoFee,
} from "../Pools/styled";
import default_token_image from "../../../../assets/Dashboard/Common/default-token-image.png";
import Value from "../../../../components/Value";
import { useTonAddress } from "@tonconnect/ui-react";
import EmptyData from "../../../../assets/gif/img-nodata.png";
import { convertFixed } from "../../../../utils/convertFormatNumber";
import axios from "axios";
import { useAppKit, useAppKitAccount } from "@reown/appkit/react";
const TabMyPool = ({ assets }: any) => {
  const { isMobile, isTablet, theme } = useContext(ContextProviderWrapper)!;
  const [data, setData] = useState<any>([]);
  const { address } = useAppKitAccount();
  const [loadingApi, setLoadingApi] = useState(false);
  const handleGetMyPool = async () => {
    if(!address) {
      setData([]);
      return;
    }
    setLoadingApi(true);
    try {
      const response = await axios.get(
        `https://app.kibble.exchange/my-pool?wallet=${address}`
      );
      let arr: any = [];
      Object.values(response.data).map((item: any) => {
        arr.push(item);
      });
      console.log("response", arr);
      setData(arr);
    } catch (error) {
      console.error("error", error);
    }
    setLoadingApi(false);
  };
  useEffect(() => {
    handleGetMyPool();
  }, [address]);

  const headingData = [
    {
      name: `Pool name`,
      dataIndex: "",
      key: "",
      render: ({ tokenX, tokenY, lbBaseFeePct }: any) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <PoolsImage className={theme}>
              <div>
                <figure>
                  <img
                    src={
                      `https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists-v2/refs/heads/main/logos/43114/${tokenX.address}.png` ||
                      default_token_image
                    }
                    alt="icon"
                    onError={(e) => (e.currentTarget.src = default_token_image)}
                  />
                </figure>
              </div>
              <div>
                <figure>
                  <img
                    src={
                      `https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists-v2/refs/heads/main/logos/43114/${tokenY.address}.png` ||
                      default_token_image
                    }
                    alt="icon"
                    onError={(e) => (e.currentTarget.src = default_token_image)}
                  />
                </figure>
              </div>
            </PoolsImage>
            <PoolsInfo className={theme}>
              <p style={{ width: 130 }}>
                {tokenX?.symbol} / {tokenY?.symbol}
              </p>
              <PoolsFarmAndFee>
                <PoolsInfoFee className={theme}>
                  LP Fee <span>{lbBaseFeePct && lbBaseFeePct}</span>%
                </PoolsInfoFee>
              </PoolsFarmAndFee>
            </PoolsInfo>
          </div>
        );
      },
    },
    {
      name: `APR`,
      dataIndex: "",
      key: "",
      render: ({ feesUsd, liquidityUsd }: any) => {
        return (
          <p
            style={{
              width: 150,
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <PoolInfoTitle>APR</PoolInfoTitle>
            {liquidityUsd
              ? convertFixed(((feesUsd * 365) / liquidityUsd) * 100)
              : "0"}
            %
          </p>
        );
      },
    },
    {
      name: `Total value blocked`,
      dataIndex: "",
      key: "",
      render: ({ liquidityUsd }: any) => {
        return (
          <p>
            <PoolInfoTitle>Liquidity</PoolInfoTitle>$
            {convertFixed(Number(liquidityUsd))}
          </p>
        );
      },
    },
    {
      name: `Volume 24h`,
      dataIndex: "",
      key: "",
      render: ({ volumeUsd }: any) => {
        return (
          <p>
            <PoolInfoTitle>
              Volume <span className="font-DMMono">24</span>h
            </PoolInfoTitle>
            ${convertFixed(Number(volumeUsd))}
          </p>
        );
      },
    },
  ];

  const moveToDetail = "/liquidity/details";

  return (
    <TabMyPoolContainer>
      {data?.length > 0 ? (
        <TableCommon data={data} heading={headingData} moveTo={moveToDetail} />
      ) : (
        <NoData className={theme}>
          <img src={EmptyData} alt="No Data" />
          <p className="title-nodata">No data</p>
          <p className="text-nodata">
            You haven't added Liquidity yet. Please add it to your preferred
            token pairs
          </p>
        </NoData>
      )}
    </TabMyPoolContainer>
  );
};

export default TabMyPool;
