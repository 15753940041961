import { Link } from "react-router-dom";
import styled from "styled-components";
import { TitleContainer } from "../Title/styled";

export const KeyvisualCommonContainer = styled.div`
  padding: 0 10px;
  ${TitleContainer} {
    margin-bottom: 5px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }
`;
export const KeyvisualWrapper = styled.div`
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
  padding: 40px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1c1c1e;
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid transparent;
  &.light {
    background-color: #fff;
    border: solid 1px #d9d9de;
    &::before {
      width: 212px;
      height: 425px;
      transform: rotate(-66deg);
      left: -90px;
      mix-blend-mode: unset;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 202px;
    height: 244px;
    background-color: #3f464f;
    mix-blend-mode: plus-lighter;
    filter: blur(173px);
    pointer-events: none;
  }
  & > figure {
    width: 420px;
    height: 196px;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    display: block;
    padding: 20px;
    background-color: transparent;
    overflow: unset;
    min-height: 158px;
    border: 0 !important;
    &.light {
      background-color: transparent;
      &::before {
        left: 0;
        height: 170px;
      }
    }
    &::before {
      width: 253px;
      height: 168px;
      background-color: #5f62cc;
      right: 0;
      left: unset;
      top: 100px;
      filter: blur(127px);
    }
    & > figure {
      width: 100%;
      height: auto;
    }
  }
`;
export const KeyvisualContent = styled.div`
  max-width: 660px;
  z-index: 3;
  position: relative;
  padding: 10px;
  &.light {
    & > p {
      color: #5e5e6b;
    }
  }
  & > p {
    color: #92929e;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.32px;
    white-space: pre-line;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 767px) {
    & > p {
      margin-bottom: 20px;
      letter-spacing: unset;
      font-size: 13px;
    }
  }
`;
export const KeyvisualButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid #3a3a40;
  background: #e84142;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 3px 0px 0px rgba(186, 186, 186, 0.33) inset;
  & > p {
    color: #fff;
    text-shadow: 0px 3.434px 7.181px rgba(255, 255, 255, 0.13);
    font-size: 16px;
    font-weight: 500;
  }
  &.light {
    border: 1px solid transparent;
    background: #b32222;
  }
`;

export const KeyvisualImage = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  .img-icon {
    max-width: 550px;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    display: block;
    .img-icon {
      max-width: 170px;
    }
  }
  @media screen and (max-width: 374px) {
    .img-icon {
      max-width: 120px;
    }
  }
`;
