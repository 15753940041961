import React, { useState, useEffect } from "react";
import { CountUp } from "use-count-up";
import styled from "styled-components";

interface ValueProps {
  value: string | number;
  decimals?: number;
  unit?: string;
  size?: number;
  color?: string;
  unitafter?: string;
  fontweight?: string;
}

const Value: React.FC<ValueProps> = ({
  value,
  decimals,
  unit,
  size,
  color,
  unitafter,
  fontweight,
}) => {
  const [start, updateStart] = useState(0);
  const [end, updateEnd] = useState(0);

  useEffect(() => {
    if (typeof value === "number") {
      updateStart(end);
      updateEnd(value);
    }
  }, [value]);

  const getDecimalPlaces = (val: number) => {
    if (decimals !== undefined) return decimals;
    if (val === 0) return 2;
    if (val >= 1e3) return 0;
    if (val < 1 && val >= 9e-4) return 4;
    if (val < 9e-4 && val >= 9e-7) return 7;
    if (val < 9e-7 && val >= 9e-10) return 10;
    if (val < 9e-10 && val >= 9e-13) return 13;
    if (val < 9e-13 && val >= 9e-16) return 16;
    return 2;
  };

  const formatEndValue = (val: number) => {
    const places = getDecimalPlaces(val);
    return Number(val.toFixed(places));
  };

  return (
    <StyledValue size={size} color={color} fontweight={fontweight}>
      {typeof value === "string" ? (
        value
      ) : (
        <>
          {unit}
          <span>
            <CountUp
              isCounting
              start={start}
              end={formatEndValue(end)}
              duration={1}
              decimalPlaces={getDecimalPlaces(end)}
              formatter={(val) => val.toLocaleString("en-US", { useGrouping: true })}
            />
          </span>
          {unitafter}

        </>
      )}
    </StyledValue>
  );
};

interface StyledValueProps {
  size?: number;
  color?: string;
  fontweight?: string;
}

const StyledValue = styled.span<StyledValueProps>`
  font-size: ${(props) => props.size && `${props.size}px`};
  color: ${(props) => props.color && `${props.color} !important`};
  font-weight: ${(props) => props.fontweight && `${props.fontweight}`};
  span {
    padding-right: 3px;
    color: ${(props) => props.color && `${props.color} !important`};
  }
`;

export default Value;