import moment from "moment";
import {
  HistoryTransactions,
  HistoryTransactionsList,
  TransactionsAction,
  TransactionsButton,
  TransactionsDetail,
  TransactionsDetailCouple,
  TransactionsDetailLine,
  TransactionsDetailStatus,
  TransactionsDetailTitle,
  TransactionsList,
  TransactionsTime,
  TransactionsTitle,
} from "../style";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { shortenAddress } from "../../../../utils/addressUser";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { CopyOutlined } from "@ant-design/icons";
import { ContextProviderWrapper } from "../../../../components/Context";
import { AVAX_API } from "../../../../services/api/indexV2";
import { useAppKitAccount } from "@reown/appkit/react";
import { convertFixed } from "../../../../utils/convertFormatNumber";
import { useDispatch } from "../../../../store/store";
import { getDataListHistory } from "../../../../store/features/poolsSlice";

const HistoryList = () => {
  const { theme } = useContext(ContextProviderWrapper)!;
  const [indexShowDetailTx, setindexShowDetailTx] = useState<any>(-1);
  const [dataHistory, setDataHistory] = useState<any>([]);
  const { listHistory, listTokens }: any = useSelector(
    (state: any) => state.pool
  );
  const dispatch = useDispatch();
  const handleGetHistory = async (listTokens: any[]) => {
    try {
      const { data } = await AVAX_API.getTx();
      const newList = data.data?.map((item: any) => {
        return {
          ...item,
          src_address: listTokens.find(
            (token: any) => token.address === item?.src_address
          )?.src_address,
          src_symbol: listTokens.find(
            (token: any) => token.address === item?.src_address
          )?.symbol,
          src_img: listTokens.find(
            (token: any) => token.address === item?.src_address
          )?.img,
          dest_address: listTokens.find(
            (token: any) => token.address === item?.dest_address
          )?.dest_address,
          dest_symbol: listTokens.find(
            (token: any) => token.address === item?.dest_address
          )?.symbol,
          dest_img: listTokens.find(
            (token: any) => token.address === item?.dest_address
          )?.img,
        };
      });
      dispatch(getDataListHistory(newList));
      setDataHistory(newList);
    } catch (error) {
      console.log("errrr", error);
    }
  };

  useEffect(() => {
    if (listHistory?.length > 0) {
      setDataHistory(listHistory);
    }
  }, [listHistory]);

  return (
    <HistoryTransactionsList>
      {dataHistory.length < 1 ? (
        <p
          style={{
            color: theme === "light" ? "#000" : "#fff",
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          Don't have any transaction
        </p>
      ) : (
        <>
          {dataHistory?.map((item: any, index: number) => {
            return (
              <HistoryTransactions
                key={index}
                style={{
                  height: indexShowDetailTx === index ? "210px" : "42px",
                }}
                className={theme}
              >
                <TransactionsTitle>
                  <TransactionsTime>
                    <span>
                      {moment(item?.createdAt).format("MMMM DD , YYYY")}
                    </span>
                    <h2>
                      +{convertFixed(item?.dest_amount)} {item?.dest_symbol}
                    </h2>
                  </TransactionsTime>
                  <TransactionsAction>
                    {indexShowDetailTx === index ? (
                      <TransactionsButton
                        onClick={() => {
                          setindexShowDetailTx(-1);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <rect
                            x="22"
                            y="22"
                            width="22"
                            height="22"
                            rx="11"
                            transform="rotate(-180 22 22)"
                            fill={theme === "dark" ? "#141518" : "#747481"}
                          />
                          <path
                            d="M6.5481 12.3455L10.4753 8.41822C10.7651 8.12845 11.2349 8.12845 11.5247 8.41822L15.4519 12.3455"
                            stroke={theme === "dark" ? "#F7F7F8" : "#fff"}
                            stroke-width="1.48397"
                            stroke-linecap="round"
                          />
                        </svg>
                      </TransactionsButton>
                    ) : (
                      <TransactionsButton
                        onClick={() => {
                          setindexShowDetailTx(index);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="23"
                          viewBox="0 0 22 23"
                          fill="none"
                        >
                          <rect
                            y="0.200043"
                            width="22"
                            height="22"
                            rx="11"
                            fill={theme === "dark" ? "#141518" : "#747481"}
                          />
                          <path
                            d="M15.4519 9.85461L11.5247 13.7819C11.2349 14.0716 10.7651 14.0716 10.4753 13.7819L6.5481 9.85461"
                            stroke={theme === "dark" ? "#F7F7F8" : "#fff"}
                            stroke-width="1.48397"
                            stroke-linecap="round"
                          />
                        </svg>
                      </TransactionsButton>
                    )}
                  </TransactionsAction>
                </TransactionsTitle>
                <TransactionsList>
                  <TransactionsDetail className={theme} key={index}>
                    <TransactionsDetailTitle>
                      <TransactionsDetailCouple>
                        <div className="icon-1">
                          <img src="" alt="" />
                        </div>
                        <div className="icon-1">
                          <img src="" alt="" />
                        </div>
                        <h1>
                          {item?.src_symbol}/{item?.dest_symbol}
                        </h1>
                      </TransactionsDetailCouple>
                      {item?.status === "Succeed" ? (
                        <TransactionsDetailStatus
                          style={{
                            background:
                              theme === "dark" ? "#00341d" : "rgb(243 244 248)",
                          }}
                        >
                          <span>{item?.status}</span>
                        </TransactionsDetailStatus>
                      ) : (
                        <TransactionsDetailStatus
                          style={{
                            background:
                              theme === "dark"
                                ? "rgba(217, 217, 217, 0.1)"
                                : "rgb(243 244 248)",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(245, 15, 72)",
                            }}
                          >
                            {item?.status}
                          </span>
                        </TransactionsDetailStatus>
                      )}
                    </TransactionsDetailTitle>
                    <TransactionsDetailTitle>
                      <span>
                        <img
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                          src={item?.src_img}
                          alt="icon"
                        />
                        {item?.src_symbol}
                      </span>
                      <h2
                        style={{
                          color: "#f50f48",
                        }}
                      >
                        -{convertFixed(item?.src_amount)}
                      </h2>
                    </TransactionsDetailTitle>
                    <TransactionsDetailTitle>
                      <span>
                        <img
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                          src={item?.dest_img}
                          alt="icon"
                        />
                        {item?.dest_symbol}
                      </span>
                      <h2
                        style={{
                          color: "rgb(15, 245, 134)",
                        }}
                      >
                        +{convertFixed(item?.dest_amount)} {item?.dest_symbol}
                      </h2>
                    </TransactionsDetailTitle>
                    <TransactionsDetailTitle>
                      <span>Transaction hash</span>
                      {item?.tx_hash ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <a
                            style={{
                              color: theme === "light" ? "#141518" : "#fff",
                            }}
                            href={`https://snowscan.xyz/tx/${item?.tx_hash}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {shortenAddress(item?.tx_hash)}
                          </a>
                          <CopyToClipboard
                            text={item?.tx_hash}
                            onCopy={() => {
                              toast.success("Copied !!");
                            }}
                          >
                            <div
                              style={{
                                color: "#92929E",
                                cursor: "pointer",
                              }}
                            >
                              <CopyOutlined />
                            </div>
                          </CopyToClipboard>
                        </div>
                      ) : (
                        <h2>--</h2>
                      )}
                    </TransactionsDetailTitle>
                    <TransactionsDetailLine>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="292"
                        height="2"
                        viewBox="0 0 292 2"
                        fill="none"
                      >
                        <path
                          d="M1 1.20004L291 1.20007"
                          stroke="#43424A"
                          stroke-linecap="round"
                          stroke-dasharray="5 5"
                        />
                      </svg>
                    </TransactionsDetailLine>
                    <TransactionsDetailTitle>
                      <p
                        style={{
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        {moment(item?.createdAt).format("HH:mm:ss")}
                      </p>
                    </TransactionsDetailTitle>
                  </TransactionsDetail>
                </TransactionsList>
              </HistoryTransactions>
            );
          })}
        </>
      )}
    </HistoryTransactionsList>
  );
};

export default HistoryList;
