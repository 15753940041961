import { HeaderNotification, MarqueeItemHeader } from "./styled";
import Bell from "./Bell";
import Marquee from "react-fast-marquee";
import { useContext } from "react";
import { ContextProviderWrapper } from "../../../Context";

const NotificationHead = () => {
  const { theme } = useContext(ContextProviderWrapper)!;
  return (
    <HeaderNotification className={theme}>
      <div>
        <Bell />
        <Marquee>
          <MarqueeItemHeader>
            <p>
              <span>KIB/AVAX</span> and <span>KIB/USDC</span> trading pairs are
              coming soon.
            </p>
            <a
              href="https://x.com/KibbleExchange/status/1904126053435748499"
              target="_blank"
              rel="noreferrer"
            >
              See more
            </a>
            <p>Kibble IDO information</p>
            <a
              href="https://x.com/KibbleExchange/status/1904126053435748499"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </MarqueeItemHeader>
          <MarqueeItemHeader>
            <p>
              <span>KIB/AVAX</span> and <span>KIB/USDC</span> trading pairs are
              coming soon.
            </p>
            <a
              href="https://x.com/KibbleExchange/status/1904126053435748499"
              target="_blank"
              rel="noreferrer"
            >
              See more
            </a>
            <p>Kibble IDO information</p>
            <a
              href="https://x.com/KibbleExchange/status/1904126053435748499"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </MarqueeItemHeader>
          <MarqueeItemHeader>
            <p>
              <span>KIB/AVAX</span> and <span>KIB/USDC</span> trading pairs are
              coming soon.
            </p>
            <a
              href="https://x.com/KibbleExchange/status/1904126053435748499"
              target="_blank"
              rel="noreferrer"
            >
              See more
            </a>
            <p>Kibble IDO information</p>
            <a
              href="https://x.com/KibbleExchange/status/1904126053435748499"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </MarqueeItemHeader>
        </Marquee>
      </div>
    </HeaderNotification>
  );
};

export default NotificationHead;
