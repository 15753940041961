import { useContext } from "react";
import TitleCommon from "../Title";
import {
  KeyvisualButton,
  KeyvisualCommonContainer,
  KeyvisualContent,
  KeyvisualImage,
  KeyvisualWrapper,
} from "./styled";
import { ContextProviderWrapper } from "../../Context";

const KeyvisualCommon = ({
  title,
  image,
  description,
  buttonText,
  buttonLink,
  buttonIcon,
  hide,
}: any) => {
  const { theme } = useContext(ContextProviderWrapper)!;

  return (
    <KeyvisualCommonContainer>
      <KeyvisualWrapper className={theme}>
        <KeyvisualContent className={theme}>
          <TitleCommon text={title} />
          <p>{description}</p>
          {!hide && (
            <KeyvisualButton className={theme} to={buttonLink}>
              {buttonIcon && <img src={buttonIcon} alt="icon" />}
              <p>{buttonText}</p>
            </KeyvisualButton>
          )}
        </KeyvisualContent>
        {image && (
          <KeyvisualImage>
            <div className="img-icon">
              <img src={image} alt="visual" />
            </div>
          </KeyvisualImage>
        )}
      </KeyvisualWrapper>
    </KeyvisualCommonContainer>
  );
};

export default KeyvisualCommon;
