import axios from "axios";
import { instanceV2 } from "../instanceV2";

export const AVAX_API = {
  listToken(params: any) {
    return instanceV2.get(`/api/token-list`, { params });
  },
  getQuote(params: any) {
    return instanceV2.get(`/api/routing/quote`, { params });
  },
  get1Inch(params: any) {
    return instanceV2.get(`/api/routing/quote-1inch`, { params });
  },
  getKyberSwap(params: any) {
    return instanceV2.get(`/api/routing/kyberswap-quote`, { params });
  },
  getOdos(params: any) {
    return instanceV2.get(`/api/routing/odos-quote`, { params });
  },
  createSwap(params: any) {
    return instanceV2.get(`/api/swap/create`, { params });
  },
  getBalanceTokens(params: any) {
    return axios.get(
      `https://multichain-api.kibble.exchange/api/multichain/balance?${params}`,
      {
        headers: {
          apiKey: "a259b891-a9d9-48e6-8a62-ee1f3fba2653",
        },
      }
    );
  },

  loginBot(params: any) {
    return axios.post(
      "https://telegram-service.kibble.exchange/api/telegram/login",
      params,
      {
        headers: {
          Accept: "*",
          "Content-Type": "application/json",
        },
      }
    );
  },

  sendNotice(params: any) {
    return axios.post(
      "https://telegram-service.kibble.exchange/api/telegram/broadcast-photo",
      {
        caption: params.caption,
        fileUrl: params.fileUrl,
        markup: {
          text: params.textBtn,
          url: params.linkBtn,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
  },
  login(params: any) {
    return instanceV2.post(`/api/auth/login`, params);
  },
  verifyTx(params: any) {
    return instanceV2.post(`/api/transactions/verify`, params);
  },
  getTx() {
    return instanceV2.get(`/api/transactions`);
  },
  getPools() {
    return instanceV2.get(`/api/ido/rounds`);
  },
  getDetailPool(params: any) {
    return instanceV2.get(`/api/ido/rounds/detail`, { params });
  },
  createTxJoinPool(params: any) {
    return instanceV2.post(`/api/ido/transactions/create`, params);
  },
  getBalancesIDO(params: any) {
    return instanceV2.get(`/api/ido/balances`, { params });
  },
  getClaimSchedule(params: any) {
    return instanceV2.get(`/api/ido/claim/schedule`, { params });
  },
  getClaimSignature(params: any) {
    return instanceV2.get(`/api/ido/claim/signature`, { params });
  },
  getClaimAvailable() {
    return instanceV2.get(`/api/ido/claim/claim-available`);
  },
  createClaim(params: any) {
    return instanceV2.post(`/api/ido/claim`, params);
  },
  checkUserInWhiteList(params: any) {
    return instanceV2.get(`/api/ido/rounds/is-whitelist`, { params });
  },
};
