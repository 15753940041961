import { Link } from "react-router-dom";
import {
  BoxPoolDetailWapper,
  BtnViewProject,
  CoinInfoDes,
  CoinInfoSocial,
  CoinInfoTitle,
  CoinInfoWapper,
  LockInfo,
  PieWapper,
  Tokenomics,
  TokenomicsInfor,
} from "./styled_BoxPoolDetail";
import React, { useContext, useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useParams } from "react-router-dom";
import { shortenAddress } from "../../../utils/addressUser";
import Value from "../../../components/Value";
import { ContextProviderWrapper } from "../../../components/Context";

ChartJS.register(ArcElement, Tooltip, Legend);
const BoxPoolDetail = ({ poolInfo }: any) => {
  const [dataIDO, setDataIDO] = useState<any>(null);
  const { isMobile, theme } = useContext(ContextProviderWrapper)!;
  const { id } = useParams();

  useEffect(() => {
    if (poolInfo) {
      setDataIDO(poolInfo);
    }
  }, [poolInfo]);

  return (
    <BoxPoolDetailWapper className={theme}>
      <CoinInfoWapper className={theme}>
        <CoinInfoTitle>
          <span className="TokenLogo">
            <img src="../../static/Logo.png" alt="" />
          </span>
          <div>
            <h3>
              Kibble Exchange{" "}
              <span style={{ textTransform: "uppercase" }}>KIB / USDC</span>
            </h3>
            <p
             className={theme}
            >
              {dataIDO?.name}
            </p>
          </div>
        </CoinInfoTitle>
        <CoinInfoDes className={theme}>{dataIDO?.description}</CoinInfoDes>
        <CoinInfoSocial>
          <div>
            <a className={theme} href="https://app.kibble.exchange/" target="_blank" rel="noreferrer">
              <img src="../../static/web.svg" alt="web" />
            </a>
            <a className={theme}
              href="https://t.me/KibbleOfficial"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../../static/telegram.svg" alt="telegram" />
            </a>
            <a className={theme}
              href="https://x.com/kibbleexchange"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../../static/x.svg" alt="x" />
            </a>
            <a className={theme}
              href="https://discord.gg/kibbleai"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../../static/discord.svg" alt="discord" />
            </a>
            <a className={theme}
              href="https://www.youtube.com/@KibbleExchange"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../../static/youtube.svg" alt="youtube" />
            </a>
            <a className={theme}
              href="https://kibble.medium.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../../static/medium.svg" alt="medium" />
            </a>
          </div>
          <BtnViewProject className={theme}>
            <Link to={`/launchpad/avax/${id}/view`}>
              <button>
                <span>View project</span>{" "}
                <img src="../../static/Arrow-Right.png" />{" "}
              </button>
            </Link>
          </BtnViewProject>
        </CoinInfoSocial>
      </CoinInfoWapper>
      <Tokenomics className={theme}>
        <h1>tokenomics</h1>
        <PieWapper>
          {/* <Doughnut data={data} options={options} /> */}
          <div className="img-chart">
            <img src="/static/img-chart.png" alt="img" />
          </div>
          <div className="logo">
            <img src="../../static/Logo.png" />
          </div>
        </PieWapper>
        <TokenomicsInfor>
          <div>
            <h3>Angel Investor</h3>
            <p className={theme}>
              <Value decimals={0} unitafter="%" value={5} />
            </p>
          </div>
          <div>
            <h3>Seed Round</h3>
            <p className={theme}>
              <Value decimals={0} unitafter="%" value={8} />
            </p>
          </div>
          <div>
            <h3>Private Sale</h3>
            <p className={theme}>
              <Value decimals={0} unitafter="%" value={4} />
            </p>
          </div>
          <div>
            <h3>Public Sale</h3>
            <p className={theme}>
              <Value decimals={0} unitafter="%" value={3} />
            </p>
          </div>
          <div>
            <h3>Liquidity</h3>
            <p className={theme}>
              <Value decimals={0} unitafter="%" value={10} />
            </p>
          </div>
          <div>
            <h3>Advisor & Incubator</h3>
            <p className={theme}>
              <Value decimals={0} unitafter="%" value={6} />
            </p>
          </div>
          <div>
            <h3>Marketing</h3>
            <p className={theme}>
              <Value decimals={0} unitafter="%" value={10} />
            </p>
          </div>
          <div>
            <h3>Team</h3>
            <p className={theme}>
              <Value decimals={0} unitafter="%" value={12} />
            </p>
          </div>
          <div>
            <h3>Incentives & Airdrop</h3>
            <p className={theme}>
              <Value decimals={0} unitafter="%" value={42} />
            </p>
          </div>
        </TokenomicsInfor>
      </Tokenomics>
      <LockInfo className={theme}>
        <h1>Token info</h1>
        <img src="../../static/gif-token-info.gif" />
        <TokenomicsInfor>
          <div>
            <h3>Token name</h3>
            <p className={theme}>Kibble Exchange</p>
          </div>
          
          <div>
            <h3>Token address</h3>
            <p>
              <a
                href="https://snowscan.xyz/token/0x00Da149c4E01E4a391Ab86DEdDaAE66E906B6fB7"
                target="_blank"
                style={{
                  color: theme === "dark" ? "#fff" : "#6e6e7c",
                }}
              >
                {shortenAddress("0x00Da149c4E01E4a391Ab86DEdDaAE66E906B6fB7")}
              </a>
            </p>
          </div>
          
          <div>
            <h3>Token symbol</h3>
            <p className={theme}>KIB</p>
          </div>
          
          <div>
            <h3>Decimals</h3>
            <p className={theme}>
              <Value decimals={0} value={18} />
            </p>
          </div>
          <div>
            <h3>Total supply</h3>
            <p className={theme}>
              <Value unitafter="KIB" value={1000000000} />
            </p>
          </div>
          
        </TokenomicsInfor>
      </LockInfo>
    </BoxPoolDetailWapper>
  );
};
export default BoxPoolDetail;
