import {
  MyLiquidityWrapper,
  NoData,
  PoolInfoTitle,
  PoolsFarmAndFee,
  PoolsImage,
  PoolsInfo,
  PoolsInfoFee,
  TabPoolsContainer,
  ToolTipStyle,
} from "./styled";
import TableCommon from "../../../../components/Common/TableCommon";
import default_token_image from "../../../../assets/Dashboard/Common/default-token-image.png";
import { convertFixed } from "../../../../utils/convertFormatNumber";
import EmptyData from "../../../../assets/gif/img-nodata.png";
import { useContext, useEffect, useState } from "react";
import { ContextProviderWrapper } from "../../../../components/Context";
import axios from "axios";
import { Tooltip } from "react-tooltip";
import { Pagination } from "antd";

const TabPools = ({ assetsInfo, filteredPools, searchValue }: any) => {
  const { theme } = useContext(ContextProviderWrapper)!;
  console.log("searchValue" , searchValue);
  
  const headingData = [
    {
      name: `Pool name`,
      dataIndex: "",
      key: "",
      render: ({ tokenX, tokenY, lbBaseFeePct }: any) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <PoolsImage className={theme}>
              <div>
                <figure>
                  <img
                    src={
                      `https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists-v2/refs/heads/main/logos/43114/${tokenX.address}.png` ||
                      default_token_image
                    }
                    alt="icon"
                    onError={(e) => (e.currentTarget.src = default_token_image)}
                  />
                </figure>
              </div>
              <div>
                <figure>
                  <img
                    src={
                      `https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists-v2/refs/heads/main/logos/43114/${tokenY.address}.png` ||
                      default_token_image
                    }
                    alt="icon"
                    onError={(e) => (e.currentTarget.src = default_token_image)}
                  />
                </figure>
              </div>
            </PoolsImage>
            <PoolsInfo className={theme}>
              <p style={{ width: 130 }}>
                {/* <TextNamePool poolTokens={poolTokens} /> */}
                {tokenX?.symbol} / {tokenY?.symbol}
              </p>
              <PoolsFarmAndFee>
                {/* <PoolsInfoFarm> */}
                {/* Farm <span>{lp_fee / 100}</span>% */}
                {/* </PoolsInfoFarm> */}
                <PoolsInfoFee className={theme}>
                  LP Fee <span>{lbBaseFeePct && lbBaseFeePct}</span>%
                </PoolsInfoFee>
              </PoolsFarmAndFee>
            </PoolsInfo>
          </div>
        );
      },
    },
    {
      name: `APR`,
      dataIndex: "",
      key: "",
      render: ({ feesUsd, liquidityUsd }: any) => {
        return (
          <p
            style={{
              width: 150,
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <PoolInfoTitle>APR</PoolInfoTitle>
            {liquidityUsd
              ? convertFixed(((feesUsd * 365) / liquidityUsd) * 100)
              : "0"}
            %
          </p>
        );
      },
    },
    {
      name: `Total value blocked`,
      dataIndex: "",
      key: "",
      render: ({ liquidityUsd }: any) => {
        return (
          <p>
            <PoolInfoTitle>Liquidity</PoolInfoTitle>$
            {convertFixed(Number(liquidityUsd))}
          </p>
        );
      },
    },
    {
      name: `Volume 24h`,
      dataIndex: "",
      key: "",
      render: ({ volumeUsd }: any) => {
        return (
          <p>
            <PoolInfoTitle>
              Volume <span className="font-DMMono">24</span>h
            </PoolInfoTitle>
            ${convertFixed(Number(volumeUsd))}
          </p>
        );
      },
    },
    // {
    //   name: `My liquidity`,
    //   dataIndex: "",
    //   key: "",
    //   render: () => {
    //     return (
    //       <MyLiquidityWrapper className={theme}>
    //         <PoolInfoTitle>My Liquidity</PoolInfoTitle>
    //         <p>$0</p>
    //       </MyLiquidityWrapper>
    //     );
    //   },
    // },
  ];

  const moveToDetail = "liquidity/avax/details";
  const [data, setData] = useState([]);
  const [pageIndex, setPage] = useState(1);
  const [nextData, setNextData] = useState([]);
  const fecthData = async () => {
    const Res = await axios.get(`https://app.kibble.exchange/pools`, {
      params: { page: pageIndex, pageSize: 10 },
    });
    setData(Res.data);
  };
  const fecthDataNextPage = async () => {
    const Res = await axios.get(`https://app.kibble.exchange/pools`, {
      params: { page: pageIndex + 1, pageSize: 10 },
    });
    if (Res.data) {
      setNextData(Res.data);
    } else {
      setNextData([]);
    }
  };
  const handleSearchPools = async () => {
    const Res = await axios.get(`https://app.kibble.exchange/search?key=${searchValue}`, {
      params: { page: pageIndex + 1, pageSize: 10 },
    });
    if(Res?.data?.pools){
      setData(Res.data?.pools);
    }else{
      setData([]);
    }
  }
  useEffect(() => {
    if(!searchValue){
      if (pageIndex === 1) {
        fecthData();
      }
      fecthDataNextPage();
    }else{
      handleSearchPools();
    }
  }, [pageIndex , searchValue]);

  return (
    <TabPoolsContainer>
      {data?.length > 0 ? (
        <>
          <TableCommon
            data={data}
            heading={headingData}
            moveTo={moveToDetail}
            pagination={false}
          />
          {!searchValue && <Pagination
            defaultCurrent={1}
            // pageSize={20}
            total={nextData ? pageIndex * 10 + nextData.length : pageIndex * 10}
            onChange={(page, pageSize) => {
              if (page > pageIndex && nextData) {
                setPage(Number(page));
                setData([...nextData]);
              } else if (page < pageIndex) {
                fecthData();
                setPage(Number(page));
              }
            }}
          />}
          
        </>
      ) : (
        <NoData className={theme}>
          <img src={EmptyData} alt="No Data" />
          <p className="title-nodata">No data</p>
          <p className="text-nodata">
            You haven't added Liquidity yet. Please add it to your preferred
            token pairs
          </p>
        </NoData>
      )}
    </TabPoolsContainer>
  );
};

export default TabPools;

export const TextNamePool = ({ poolTokens }: any) => {
  let text = "";
  poolTokens?.map((item: any, index: number) => {
    if (item.balanceUSD !== "0") {
      text += item.symbol + " / ";
    }
  });
  if (text.endsWith(" / ")) {
    text = text.slice(0, -3);
  }
  return <>{text}</>;
};

