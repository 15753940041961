import TitleCommon from "../../../components/Common/Title";
import { HistoryMain, HistoryStyle, HistoryTitle } from "./style";
import iconClose from "../../../assets/Dashboard/Common/close.svg";
import HistoryList from "./List";
import { useContext } from "react";
import { ContextProviderWrapper } from "../../../components/Context";

const History = ({ onClose }: any) => {
  const { theme } = useContext(ContextProviderWrapper)!;
  return (
    <HistoryStyle>
      <HistoryMain className={theme}>
        <HistoryTitle>
          <div
            style={{
              cursor: "pointer",
              mixBlendMode: theme === "dark" ? "normal" : "hard-light",
            }}
            className="img-close"
            onClick={() => {
              onClose(false);
            }}
          >
            <img src={iconClose} alt="iconClose" />
          </div>
          <TitleCommon text={"Swap History"} />
        </HistoryTitle>
        <HistoryList />
      </HistoryMain>
    </HistoryStyle>
  );
};

export default History;
