import {
  ConfirmSwapButtons,
  ConfirmSwapContainer,
  ConfirmSwapHeader,
} from "./styled";
import default_token_image from "../../../assets/Dashboard/Common/default-token-image.png";
import { ButtonCommon } from "../../../Layout/styled";
import Loading from "../../../components/Loading";
import SwapDetails from "../Tabs/Details";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { ContextProviderWrapper } from "../../../components/Context";
import { AVAX_API } from "../../../services/api/indexV2";
import { useSendTransaction } from "wagmi";
import getTokenInfo from "../../../utils/checkInfoByAccount";
import web3 from "web3";
import { ethers } from "ethers";
import { abiToken } from "../../../components/contract/ABI";
import { useAppKitAccount } from "@reown/appkit/react";
import { useDispatch } from "../../../store/store";
import {
  getDataTokenLogin,
  getDataListHistory,
} from "../../../store/features/poolsSlice";
import { useSelector } from "react-redux";
import { BlockRightRow, BlockSteps } from "../../Launchpad/Detail/styled";

const STEPS = [
  {
    title: "Creating transaction",
    description: "Your transaction is creating",
  },
  {
    title: "Sending transaction",
    description: "Your transaction is sending",
  },
  {
    title: "Verify transaction",
    description: "Your transaction is verify",
  },
];

declare const window: Window & typeof globalThis & { ethereum: any };
const ConfirmSwap = ({
  fromAsset,
  toAsset,
  fromAmount,
  toAmount,
  minReceived,
  slippageTolerance,
  openConfirmModal,
  setOpenConfirmModal,
  simulateState,
  realPrice,
  setFromAmount,
  setToAmount,
  setTextModalTransaction,
  onRefresh,
}: any) => {
  const { theme } = useContext(ContextProviderWrapper)!;
  const { address } = useAppKitAccount();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(true);
  const [allowance, setAllowance] = useState<any>(0);
  const [stepActive, setStepActive] = useState(0);
  const [isVerified, setIsVerified] = useState(true);
  const [isSentTx, setIsSentTx] = useState(true);
  const [isSwap, setIsSwap] = useState(false);
  const { isSuccess, isError, sendTransaction, data } = useSendTransaction();
  const Web3 = new web3(window.ethereum);
  const [txId, setTxId] = useState(null);
  const [reCallFunc, setReCallFunc] = useState(false);
  const dispatch = useDispatch();
  const { listTokens }: any = useSelector((state: any) => state.pool);

  useEffect(() => {
    if (isError) {
      toast.error("Send Transaction Failed");
      setIsSentTx(false);
      setStepActive(2);
    }
    if (isSuccess) {
      setStepActive(2);

      handleVerifyTx({
        transaction_id: txId,
        hash: data,
      });
    }
  }, [isError, isSuccess]);

  const handleGetHistory = async (listTokens: any[]) => {
    try {
      if (!address) return;
      const { data } = await AVAX_API.getTx();
      const newList = data.data?.map((item: any) => {
        return {
          ...item,
          src_address: listTokens.find(
            (token: any) => token.address === item?.src_address
          )?.src_address,
          src_symbol: listTokens.find(
            (token: any) => token.address === item?.src_address
          )?.symbol,
          src_img: listTokens.find(
            (token: any) => token.address === item?.src_address
          )?.img,
          dest_address: listTokens.find(
            (token: any) => token.address === item?.dest_address
          )?.dest_address,
          dest_symbol: listTokens.find(
            (token: any) => token.address === item?.dest_address
          )?.symbol,
          dest_img: listTokens.find(
            (token: any) => token.address === item?.dest_address
          )?.img,
        };
      });
      dispatch(getDataListHistory(newList));
    } catch (error) {
      console.log("errrr", error);
    }
  };

  const handleVerifyTx = async (params: any) => {
    try {
      let countInterver = 0;
      const intervalVerify = setInterval(async () => {
        countInterver++;
        try {
          await AVAX_API.verifyTx(params);
          setStepActive(3);
          clearInterval(intervalVerify);
          setOpenConfirmModal(false);
          setIsLoading(false);
          setIsSwap(false);
          onRefresh();
          toast.success("Swap Successfully");
          setTimeout(() => {
            handleGetHistory(listTokens);
          }, 3000);
        } catch (error) {
          if (countInterver === 5) {
            clearInterval(intervalVerify);
            setStepActive(3);
            setIsVerified(false);
            toast.error("Verify Transaction Failed");
          }
        }
      }, 3000);
    } catch (error: any) {
      
      console.log("handleVerifyTx err", error);
      
    }
  };

  const handleExcuteSwap = async () => {
    try {
      if (isLoading) return;
      const token = localStorage.getItem("TOKEN");
      if (!token) {
        dispatch(getDataTokenLogin("SIGN"));
        setReCallFunc(true);
        return;
      }
      setIsLoading(true);
      setIsSwap(true);
      const params = {
        srcToken: fromAsset?.address,
        destToken: toAsset?.address,
        amount: fromAmount,
        network: process.env.REACT_APP_CHAIN_ID,
      };
      const { data } = await AVAX_API.createSwap(params);
      setStepActive(1);
      setTxId(data?.transaction_id);
      sendTransaction({
        account: data?.swap_data?.from,
        to: data?.swap_data?.to,
        chainId: data?.swap_data?.chainId,
        value: data?.swap_data?.value,
        gasPrice: data?.swap_data?.gasPrice,
        gas: data?.swap_data?.gas,
        data: data?.swap_data?.data,
      });
    } catch (error: any) {
      
      toast.error(
        error && error.message
          ? error.message.substring(0, 55) + "..."
          : "Create Transaction Failed"
      );
      setIsLoading(false);
      setIsCreating(false);
    }
  };

  const getAllowance = async () => {
    try {
      setIsLoading(true);
      const amountAllowance = await getTokenInfo(
        process.env.REACT_APP_ROUTER_PARASWAP,
        fromAsset?.address,
        address
      );
      setIsLoading(false);
      setAllowance(Number(amountAllowance.allowance));
    } catch (error) {
      setAllowance(0);
      console.error("error", error);
      setIsLoading(false);
    }
  };

  const approveBuy = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const gasPrice = await Web3.eth.getGasPrice();
      const contract = new Web3.eth.Contract(abiToken, fromAsset?.address);
      await contract.methods
        .approve(process.env.REACT_APP_ROUTER_PARASWAP, ethers.MaxUint256.toString())
        .send({ from: address, gasPrice: gasPrice.toString() })
        .then(async () => {
          setIsLoading(false);
          await getAllowance();
        })
        .catch((err: any) => {
          console.error("Err approve", err);
          toast.error("User rejected");
          setIsLoading(false);
        });
    } catch (error: any) {
      console.error("Error approve", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (reCallFunc) {
      const intervalCalFunc = setInterval(() => {
        const token = localStorage.getItem("TOKEN");
        if (token) {
          clearInterval(intervalCalFunc);
          handleExcuteSwap();
          setReCallFunc(false);
        }
      }, 1500);
    }
  }, [reCallFunc]);

  useEffect(() => {
    if (address && openConfirmModal) {
      getAllowance();
    }
  }, [address, openConfirmModal]);

  // Reset all when close modal
  useEffect(() => {
    if (!openConfirmModal) {
      setTextModalTransaction("Confirm Swap");
      setFromAmount(0);
      setToAmount(0);
      setIsCreating(true);
      setIsSentTx(true);
      setIsVerified(true);
      setStepActive(0);
      setIsSwap(false);
    }
  }, [openConfirmModal]);

  return (
    <ConfirmSwapContainer>
      <>
        <ConfirmSwapHeader className={theme}>
          <div>
            <div>
              <p>You send</p>
              <div>
                <figure>
                  <img
                    onError={(e) => (e.currentTarget.src = default_token_image)}
                    src={fromAsset?.img}
                    width="48"
                    height="48"
                    alt={fromAsset?.display_name}
                  />
                </figure>
                <p>{`${fromAsset?.symbol}`}</p>
              </div>
            </div>
            <p>{`${fromAmount}`}</p>
          </div>
          <div>
            <div>
              <p>You receive</p>
              <div>
                <figure>
                  <img
                    onError={(e) => (e.currentTarget.src = default_token_image)}
                    src={toAsset?.img}
                    width="48"
                    height="48"
                    alt={toAsset?.display_name}
                  />
                </figure>
                <p>{`${toAsset?.symbol}`}</p>
              </div>
            </div>
            <p>{`${toAmount}`}</p>
          </div>
        </ConfirmSwapHeader>
        <SwapDetails
          showDetail={true}
          realPrice={realPrice}
          fromAsset={fromAsset}
          toAsset={toAsset}
          slippageTolerance={slippageTolerance}
          minReceived={minReceived}
          simulateState={simulateState}
        />
        {isSwap ? (
          <ConfirmSwapButtons>
            <BlockSteps>
              {STEPS.map((item: any, index: number) => {
                return (
                  <BlockRightRow
                    key={index}
                    className={index === STEPS.length - 1 ? "hidden-step" : ""}
                  >
                    <div
                      className={
                        (index === 0 && index < stepActive && !isCreating) ||
                        (index === 1 && index < stepActive && !isSentTx) ||
                        (index === 2 && index < stepActive && !isVerified)
                          ? "action-error"
                          : index < stepActive
                          ? "action-dot"
                          : "img-dot"
                      }
                    ></div>
                    <div
                      className={
                        (index === 0 &&
                          index < stepActive - 1 &&
                          !isCreating) ||
                        (index === 1 && index < stepActive - 1 && !isSentTx)
                          ? "action-line-error"
                          : index < stepActive - 1
                          ? "action-line"
                          : "line"
                      }
                    ></div>
                    <div>
                      <h2
                        style={{
                          color:
                            (index === 0 &&
                              index < stepActive &&
                              !isCreating) ||
                            (index === 1 && index < stepActive && !isSentTx) ||
                            (index === 2 && index < stepActive && !isVerified)
                              ? "#B32222"
                              : index < stepActive
                              ? "#48ab60"
                              : "",
                        }}
                      >
                        {item.title}
                      </h2>
                      <p>{item.description}</p>
                    </div>
                  </BlockRightRow>
                );
              })}
            </BlockSteps>
          </ConfirmSwapButtons>
        ) : (
          <ConfirmSwapButtons>
            <ButtonCommon
              onClick={() => {
                setOpenConfirmModal(false);
              }}
              background={theme === "light" ? "#EEEEF0" : "#32363f"}
              color={theme === "light" ? "#141518" : "#8e8e8f"}
            >
              <p>Cancel</p>
            </ButtonCommon>
            {Number(fromAmount) > Number(allowance) &&
            fromAsset?.address?.toLowerCase() !==
              "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase() ? (
              <ButtonCommon onClick={approveBuy} disabled={isLoading}>
                {isLoading ? (
                  <Loading status={true} content="Waiting" />
                ) : (
                  "Approve"
                )}
              </ButtonCommon>
            ) : (
              <ButtonCommon onClick={handleExcuteSwap} disabled={isLoading}>
                {isLoading ? (
                  <Loading status={true} content="Waiting" />
                ) : (
                  "Confirm & Swap"
                )}
              </ButtonCommon>
            )}
          </ConfirmSwapButtons>
        )}
      </>
    </ConfirmSwapContainer>
  );
};

export default ConfirmSwap;
