import { useState, useEffect } from "react";
import { useSimulateSwapQuery } from "../store/api/dexApiSlice";
import { isErrorResponse } from "../store/api/common";
import { toast } from "react-hot-toast";

export interface SimulateRequestData {
  swapAction: "offer" | "ask";
  offerAddress?: string;
  askAddress?: string;
  fromUnits: string;
  toUnits: string;
  slippageTolerance: number;
}

export interface SimulateSwapState {
  error?: string;
  swapRate: number;
  minAskUnits: string;
  offerUnits: string;
  askUnits: string;
  tonFeeUnits: string;
  priceImpact: number;
  bestRoute: any;
  ortherRoute: any[];
  root: any;
  timestamp: any;
}

const simulateSwapStateInitialData: SimulateSwapState = {
  swapRate: 0,
  minAskUnits: "0",
  offerUnits: "0",
  askUnits: "0",
  tonFeeUnits: "0",
  priceImpact: 0,
  bestRoute: null,
  ortherRoute: [],
  root: null,
  timestamp: 0,
};

export const useSimulateSwap = (data: any) => {
  const [simulateState, setSimulateState] = useState(
    simulateSwapStateInitialData
  );

  const { data: simulateData }: any = useSimulateSwapQuery(
    {
      swapAction: data.swapAction,
      srcToken: data?.srcToken,
      srcDecimals: data?.srcDecimals,
      destToken: data?.destToken,
      destDecimals: data?.destDecimals,
      amount: data.amount,
      side: data.side,
      network: data.network,
    },
    {
      skip: !data.destToken || !data.srcToken,
      pollingInterval: 1000 * 5,
    }
  );

  useEffect(() => {
    if (!simulateData || simulateData?.status_code === 403) {
      return;
    }

    if (isErrorResponse(simulateData) || !simulateData?.status) {
      if (Number(data.amount) !== 0) {
        toast.error(simulateData?.error?.error);
      }
      setSimulateState((prev) => ({
        ...prev,
        timestamp: 0,
      }));
    } else {
      setSimulateState({
        swapRate: simulateData?.data?.priceRoute.destAmount || 0,
        minAskUnits: simulateData?.data?.priceRoute.destAmount || 0,
        offerUnits: simulateData?.data?.priceRoute.srcAmount || 0,
        askUnits: simulateData?.data?.priceRoute.destAmount || 0,
        tonFeeUnits: simulateData?.data?.priceRoute.gasCostUSD || 0,
        priceImpact: 0 || 0,
        bestRoute: simulateData?.data?.priceRoute.bestRoute || null,
        ortherRoute: simulateData?.data?.priceRoute.others || [],
        root: simulateData?.data?.priceRoute || null,
        timestamp: simulateData?.data?.timestamp || 0,
      });
    }
  }, [simulateData]);

  return simulateState;
};
