import { useAppKit, useAppKitAccount } from "@reown/appkit/react";
import {
  ActionConnect,
  ActionSocial,
  ActionTheme,
  HDLogo,
  HeaderAction,
  HeaderDashboardContainer,
  HeaderDashboardWrapper,
  HeaderNavigation,
  ListChainHeader,
  NavigationHamburger,
  NavigationMobile,
  NavigationMobileItem,
  NavigationMobileMain,
  PriceToken,
  ToolTipSwichChain,
} from "./styled";
import header_logo from "../../../assets/Header/logo.svg";
import { matchPath, NavLink, useLocation, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import iconSwap from "../../../assets/Dashboard/Header/icon-swap.svg";
import iconLauch from "../../../assets/Dashboard/Header/icon-lauch.svg";
import iconLiquidity from "../../../assets/Dashboard/Header/icon-liquidity.svg";
import iconStaking from "../../../assets/Dashboard/Header/icon-staking.svg";
import iconKibble from "../../../assets/Dashboard/Header/icon-kibble.svg";
import iconKibble_light from "../../../assets/Dashboard/Header/icon-kibble_02.svg";
import iconSwap_light from "../../../assets/Dashboard/Header/iconSwap_light.svg";
import iconLauch_light from "../../../assets/Dashboard/Header/iconLauch_light.svg";
import iconLiquidity_light from "../../../assets/Dashboard/Header/iconLiquidity_light.svg";
import iconStaking_light from "../../../assets/Dashboard/Header/iconStaking_light.svg";
import balance from "../../../assets/Dashboard/Common/balance_icon.svg";
import balance_light from "../../../assets/Dashboard/Common/balance_icon_light.svg";
import { Link } from "react-router-dom";
import ModalOverlay from "../../Common/ModalOverlay";
import WalletInfo from "../Wallet";
import { ContextProviderWrapper } from "../../Context";
import toast from "react-hot-toast";
import ListWalletModal from "../ListWallet";
import NotificationHead from "./Notification";
import { useMexcPrice } from "./../../../utils/getPriceKIB";
import { useDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { getKIBPrice } from "../../../store/features/poolsSlice";

const HeaderDashboard = () => {
  const { open } = useAppKit();
  const [openHamburger, setOpenHamburger] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [titlePage, setTitlePage] = useState("");
  const [textActive, setTextActive] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { toggleTheme, theme, isMobile } = useContext(ContextProviderWrapper)!;
  const { pathname } = useLocation();
  const { id } = useParams();
  const { address } = useAppKitAccount();

  const dispatch = useDispatch()
  const { kibPrice }: any = useSelector((state: any) => state.pool);

  useEffect(() => {
    dispatch(getKIBPrice())

    const intervalId = setInterval(() => {
      dispatch(getKIBPrice())
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (openHamburger) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [openHamburger]);

  const handleSetRouteName = async () => {
    if (matchPath("/liquidity/avax/details/:id", pathname)) {
      setTitlePage("liquidity/details");
    } else {
      const textInRoute = pathname
        .replaceAll("/avax/", " ")
        .split(" ")
        .filter((item: any) => item !== id && item);

      const title = `${
        textInRoute[0] ? textInRoute[0]?.split("/")[0] || "" : ""
      }`;

      setTextActive(textInRoute[0]);

      setTitlePage(title);
    }
  };

  useEffect(() => {
    handleSetRouteName();
  }, [pathname]);

  const hamburgerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setCollapse(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hamburgerRef]);
  const [openModalConnect, setOpenModalConnect] = useState(false);
  return (
    <>
      <HeaderDashboardContainer
        style={{
          backgroundColor:
            pathname === "/kibble" && theme === "dark"
              ? "#000"
              : pathname === "/kibble" && theme === "light"
              ? "#0375E9"
              : "",
        }}
      >
        {address && openConfirmModal ? (
          <ModalOverlay
            component={
              <WalletInfo
                address={address}
                balance={0}
                onCloseModal={() => {
                  setOpenConfirmModal(false);
                }}
              />
            }
            onClickParent={() => {
              setOpenConfirmModal(false);
            }}
            open={openConfirmModal}
            setOpen={setOpenConfirmModal}
            title={"Your wallet"}
            width="500px"
            top={isMobile ? "40%" : ""}
          />
        ) : (
          <ModalOverlay
            component={
              <ListWalletModal
                func={() => {
                  setOpenModalConnect(false);
                  setOpenConfirmModal(false);
                }}
              ></ListWalletModal>
            }
            onClickParent={() => {
              setOpenModalConnect(false);
            }}
            open={openModalConnect}
            setOpen={setOpenModalConnect}
            title={"Select a wallet to connect"}
            width="500px"
            top={isMobile ? "40%" : ""}
          />
        )}
        <HeaderDashboardWrapper>
          <HDLogo to="https://app.kibble.exchange/">
            <img
              src={theme === "light" ? header_logo : header_logo}
              alt="logo"
            />
          </HDLogo>
          <HeaderAction>
            {kibPrice && (
              <PriceToken className={theme} >
                <figure>
                  <img src="../../assets/images/iconKIB.png" alt="icon" />
                </figure>
                <p style={{
                minWidth: "unset"
              }}>${kibPrice || 0.00}</p>
              </PriceToken>
            )}

            <ActionTheme
              className={theme}
              onClick={() => {
                pathname !== "/kibble"
                  ? toggleTheme()
                  : toast.error("This page doesn't support light mode");
              }}
            />
            <ActionSocial className={theme}>
              <li>
                <img src={"/static/AvaxLogo.png"} alt="icon" />
                <p
                  style={{
                    color: theme === "dark" ? "#fff" : "#000",
                  }}
                >
                  AVAX
                </p>
              </li>
              <ToolTipSwichChain className={theme}>
                <div className="tooltipTitle">Select Chain</div>
                <ListChainHeader>
                  {listChain.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          pathname ===
                          item.href.replace("https://app.kibble.exchange", "")
                            ? "chain_Active"
                            : item.disable
                            ? "chain close"
                            : "chain"
                        }
                      >
                        <a href={item.href}>
                          <h4
                            style={{
                              color: theme === "dark" ? "#fff" : "#000",
                            }}
                          >
                            <img src={item.icon} alt="icon" /> {item.name}
                          </h4>
                          {!item.disable &&
                            pathname ===
                              item.href.replace(
                                "https://app.kibble.exchange",
                                ""
                              ) && (
                              <img src="/static/charm_tick.svg" alt="icon" />
                            )}
                        </a>
                      </li>
                    );
                  })}
                </ListChainHeader>
              </ToolTipSwichChain>
            </ActionSocial>
            <ActionConnect
              className={theme}
              onClick={
                !address
                  ? () => {
                      open();
                    }
                  : () => {
                      setOpenConfirmModal(true);
                    }
              }
            >
              {address ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <figure>
                    <img
                      src={theme === "light" ? balance_light : balance}
                      alt="icon"
                    />
                  </figure>
                  <p>
                    {address.slice(0, 4)}...{address.slice(-4)}
                  </p>
                </div>
              ) : (
                <>
                  <figure>
                    <img
                      src={theme === "light" ? balance_light : balance}
                      alt="icon"
                    />
                  </figure>
                  <p>Connect Wallet</p>
                </>
              )}
            </ActionConnect>
          </HeaderAction>
          <HeaderNavigation className={theme}>
            <p className="title-page">{titlePage}</p>
            <NavigationHamburger
              ref={hamburgerRef}
              onClick={() => {
                setCollapse(!collapse);
              }}
              className={
                collapse && theme === "dark"
                  ? "open"
                  : collapse && theme === "light"
                  ? `open ${theme}`
                  : theme
              }
            >
              <div>
                <span></span>
                <span></span>
              </div>
              <ul>
                {navData.map((item: any, index: number) => {
                  return (
                    <li
                      style={{
                        transform: collapse
                          ? "translate(0)"
                          : "translate(-30px)",
                        opacity: collapse ? "1" : "0",
                        transition: collapse ? "all .15s linear" : "none",
                        transitionDelay: collapse ? item.delay : "unset",
                        display: item.id === 5 && !isMobile ? "none" : "block",
                      }}
                      onClick={() => {
                        setOpenHamburger(false);
                      }}
                      key={index}
                    >
                      <NavLink to={item.href}>
                        <p>{item.title}</p>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </NavigationHamburger>
          </HeaderNavigation>
          <NavigationMobileMain id="footer-mb">
            <NavigationMobile className={theme}>
              {navDataMobile.map((item: any, index: number) => {
                return (
                  <Link to={item.href} key={index}>
                    <NavigationMobileItem className={theme}>
                      <div className="img-icon">
                        <figure>
                          <img
                            style={{
                              filter:
                                `/${textActive}` === item.href
                                  ? "unset"
                                  : " contrast(0.1)",
                            }}
                            src={
                              `/${textActive}` === item.href &&
                              theme === "light"
                                ? item.light
                                : theme === "light"
                                ? item.light
                                : item.icon
                            }
                            alt={item.title}
                          />
                        </figure>
                      </div>
                      <p
                        style={{
                          color:
                            `/${textActive}` === item.href && theme === "dark"
                              ? "#B32222"
                              : `/${textActive}` !== item.href &&
                                theme === "light"
                              ? "#B8B8C1"
                              : `/${textActive}` === item.href &&
                                theme === "light"
                              ? "#E84142"
                              : "#92929e",
                        }}
                      >
                        {item.title}
                      </p>
                    </NavigationMobileItem>
                  </Link>
                );
              })}
            </NavigationMobile>
          </NavigationMobileMain>
        </HeaderDashboardWrapper>
      </HeaderDashboardContainer>
      <NotificationHead />
    </>
  );
};

const navData: any = [
  {
    id: 1,
    title: "Swap",
    href: "/swap/avax",
    delay: "0.1s",
    icon: iconSwap,
    light: iconSwap_light,
  },
  {
    id: 4,
    title: "Liquidity",
    href: "/liquidity/avax",
    delay: "0.2s",
    icon: iconLiquidity,
    light: iconLiquidity_light,
  },
  {
    id: 5,
    title: "Kibble",
    href: "/kibble/avax",
    delay: "0.2s",
    icon: iconKibble,
    light: iconKibble_light,
  },
  {
    id: 2,
    title: "Launchpad",
    href: "/launchpad/avax",
    delay: "0.3s",
    icon: iconLauch,
    light: iconLauch_light,
  },
  {
    id: 3,
    title: "Staking",
    href: "/staking/avax",
    delay: "0.4s",
    icon: iconStaking,
    light: iconStaking_light,
  },
  {
    id: 4,
    title: "Bridge",
    href: "https://bridge.kibble.exchange/bridge",
    delay: "0.5s",
    icon: "./static/Bridge.svg",
    light: iconStaking_light,
  },
];

const navDataMobile: any = [
  {
    id: 1,
    title: "Swap",
    href: "/swap/avax",
    delay: "0.1s",
    icon: iconSwap,
    light: iconSwap_light,
  },
  {
    id: 4,
    title: "Liquidity",
    href: "/liquidity/avax",
    delay: "0.2s",
    icon: iconLiquidity,
    light: iconLiquidity_light,
  },
  // {
  //   id: 5,
  //   title: "Kibble",
  //   href: "/kibble/avax",
  //   delay: "0.2s",
  //   icon: iconKibble,
  //   light: iconKibble_light,
  // },
  {
    id: 2,
    title: "Launchpad",
    href: "/launchpad/avax",
    delay: "0.3s",
    icon: iconLauch,
    light: iconLauch_light,
  },
  {
    id: 3,
    title: "Staking",
    href: "/staking/avax",
    delay: "0.4s",
    icon: iconStaking,
    light: iconStaking_light,
  },
  {
    id: 4,
    title: "Bridge",
    href: "https://bridge.kibble.exchange/bridge",
    delay: "0.5s",
    icon: "/static/Bridge.svg",
    light: "/static/Bridge.svg",
  },
];

const listChain = [
  {
    icon: "/static/AvaxLogo.png",
    href: "https://app.kibble.exchange/swap/avax",
    name: "Avax",
    disable: false,
  },
  {
    icon: "https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c/ee9fb21d17bc8d75c2a5f7b5f5f62d2bacec6b128f58b63cb841e98f7b74c4fc",
    href: "https://app.kibble.exchange/swap/ton",
    name: "Ton",
    disable: false,
  },
  {
    icon: "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    href: "#",
    name: "Ethereum",
    disable: true,
  },
  {
    icon: "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BNB/icon.svg",
    href: "#",
    name: "BNB Chain",
    disable: true,
  },
  {
    icon: "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    href: "#",
    name: "Polygon",
    disable: true,
  },
  {
    icon: "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    href: "#",
    name: "ARBITRUM",
    disable: true,
  },
  {
    icon: "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    href: "#",
    name: "Optimism",
    disable: true,
  },
  {
    icon: "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/FANTOM/icon.svg",
    href: "#",
    name: "FANTOM",
    disable: true,
  },
  {
    icon: "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    href: "#",
    name: "Cronos",
    disable: true,
  },
  {
    icon: "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BNB/icon.svg",
    href: "#",
    name: "OPBNB",
    disable: true,
  },
  {
    icon: "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    href: "#",
    name: "CELO",
    disable: true,
  },
  {
    icon: "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/COSMOS/icon.svg",
    href: "#",
    name: "Cosmos",
    disable: true,
  },
  {
    icon: "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    href: "#",
    name: "BASE",
    disable: true,
  },
  {
    icon: "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    href: "#",
    name: "SOLANA",
    disable: true,
  },
];

export default HeaderDashboard;
