import styled from "styled-components";

export const BtnWallet = styled.button`
    width: 100%;
    display:flex;
    align-items: center;
    gap:16px;
    padding: 10px;
    background: #43424a;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(186, 186, 186, 0.33) inset;
    border-radius: 5px;
    border: 1px solid #141518;
    margin-bottom: 5px;
    /* font-family: DirtyLine;
    text-transform: lowercase; */
    outline: none;
    color: #edfaff;
    font-size: 19px;
    img , span{
        width: 24px;
        height: 24px;
    }
    &:hover{
        color: #fff;
        border: 1px solid rgb(197, 211, 255);
        box-shadow: 0px 3px 3px 0px rgba(255, 255, 255, 0.25), 0px 3px 0px 0px rgba(111, 197, 255, 0.33) inset;
    }
`