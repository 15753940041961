import { Outlet } from "react-router-dom";
import { Suspense, useRef } from "react";
import HeaderDashboard from "../../components/Header/Dashboard";
import { DashboardContainer } from "./styled";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch } from "../../store/store";
import { useEffect, useState } from "react";
import {
  getDataTokenLogin,
  getDataListHistory,
  getDataListToken,
} from "../../store/features/poolsSlice";

import { AVAX_API } from "../../services/api/indexV2";
import { Spin } from "antd";
import _ from "lodash";
import { useAppKitAccount } from "@reown/appkit/react";
import { useSignMessage } from "wagmi";
import { useSelector } from "react-redux";

const TON_ADDRESS: any = process.env.REACT_APP_TON_ADDRESS;
const KIBBLE_ADDRESS: any = process.env.REACT_APP_USDC_ADDRESS;

const LayoutDashboard = () => {
  const { address } = useAppKitAccount();

  useAuth();

  const dispatch = useDispatch();
  const { data: signature, signMessage }: any = useSignMessage();
  const { listTokens, tokenLogin }: any = useSelector(
    (state: any) => state.pool
  );
  const prevAddressRef = useRef<any>(null);

  const handleGetListToken = async (tokens: any[]) => {
    try {
      const { data } = await AVAX_API.listToken({
        chainId: process.env.REACT_APP_CHAIN_ID,
      });
      const newListWithBalance = data?.map((item: any) => {
        return {
          ...item,
          balance:
            tokens.find(
              (token: any) =>
                token?.asset?.address === item.address ||
                process.env.REACT_APP_TON_ADDRESS === item.address
            )?.amount?.amount || 0,
          price:
            tokens.find(
              (token: any) =>
                token?.asset?.address === item.address ||
                process.env.REACT_APP_TON_ADDRESS === item.address
            )?.price || 0,
        };
      });
      // sort up to down with balance
      dispatch(
        getDataListToken(_.orderBy(newListWithBalance, "balance", "desc"))
      );
    } catch (error) {
      
      console.log("handleGetListToken err", error);
      
    }
  };

  const handleGetHistory = async (listTokens: any[]) => {
    try {
      if (!address) return;
      const { data } = await AVAX_API.getTx();
      const newList = data.data?.map((item: any) => {
        return {
          ...item,
          src_address: listTokens.find(
            (token: any) => token.address === item?.src_address
          )?.src_address,
          src_symbol: listTokens.find(
            (token: any) => token.address === item?.src_address
          )?.symbol,
          src_img: listTokens.find(
            (token: any) => token.address === item?.src_address
          )?.img,
          dest_address: listTokens.find(
            (token: any) => token.address === item?.dest_address
          )?.dest_address,
          dest_symbol: listTokens.find(
            (token: any) => token.address === item?.dest_address
          )?.symbol,
          dest_img: listTokens.find(
            (token: any) => token.address === item?.dest_address
          )?.img,
        };
      });
      dispatch(getDataListHistory(newList));
    } catch (error) {
      console.log("errrr", error);
    }
  };

  const handleGetBalanceTokenInWallet = async () => {
    try {
      if (!address) {
        await handleGetListToken([]);
        return;
      }
      const params: any = [`AVAX_CCHAIN.${address}`];
      let convertParams = "";
      params.forEach((element: string) => {
        convertParams += `address=${element}&`;
      });
      const { data } = await AVAX_API.getBalanceTokens(convertParams);
      await handleGetListToken(data?.wallets[0]?.balances || []);
    } catch (error) {
      
      console.log("handleGetBalanceTokenInWallet err", error);
      
    }
  };

  const handleLogin = async (sign: string) => {
    try {
      const params = {
        message: "personal signature",
        signature: sign,
        wallet_name: "",
      };
      const { data } = await AVAX_API.login(params);
      localStorage.setItem("TOKEN", data.data);
      dispatch(getDataTokenLogin(data.data));
      handleGetHistory(listTokens);
    } catch (error) {
      
      console.log("handleLogin err", error);
      
    }
  };

  const handleSignMess = async () => {
    await signMessage({ message: "personal signature" });
  };

  useEffect(() => {
    const token = localStorage.getItem("TOKEN");
    if (prevAddressRef.current && prevAddressRef.current !== address) {
      localStorage.clear();
      dispatch(getDataTokenLogin(null));
      handleSignMess();
    } else if (!token) {
      dispatch(getDataTokenLogin(null));
      handleSignMess();
    } else if (address) {
      handleGetHistory(listTokens);
      dispatch(getDataTokenLogin(token));
    }
    prevAddressRef.current = address;
  }, [address]);

  useEffect(() => {
    if (signature) {
      // login
      handleLogin(signature);
    }
  }, [signature]);

  useEffect(() => {
    handleGetBalanceTokenInWallet();
  }, [address]);

  useEffect(() => {
    const token = localStorage.getItem("TOKEN");
    if (!token && address && tokenLogin && tokenLogin === "SIGN") {
      dispatch(getDataTokenLogin(null));
      handleSignMess();
    }
  }, [tokenLogin]);

  useEffect(() => {
    // Save from and to to localStorage
    const getSelectedFromLocal = JSON.parse(
      localStorage.getItem("avax-selectedTokens") || "{}"
    );
    const selectedTokens = {
      from: getSelectedFromLocal.from || TON_ADDRESS,
      to: getSelectedFromLocal.to || KIBBLE_ADDRESS,
    };
    localStorage.setItem("avax-selectedTokens", JSON.stringify(selectedTokens));
  }, []);

  return (
    <Suspense fallback={<Spin spinning={true} fullscreen />}>
      <HeaderDashboard />
      <DashboardContainer>
        <Outlet />
      </DashboardContainer>
    </Suspense>
  );
};
export default LayoutDashboard;
