export const convertFormatNumber = (number: any) =>
  `${new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })
    .format(number)
    .replace("$", "")}`;
// Convert to 100000 => 100,000.00

export const toFixedWithoutRounding = (number: any, precision: any) => {
  const factor = Math.pow(10, precision);
  const tempNumber = Math.trunc(number * factor);
  return (tempNumber / factor).toFixed(precision);
};
export const convertFixed = (value:any) => {
  if (Number(value) === 0)
    return value ?.toFixed(2) ?.replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
  else if (Number(value) > 1e3) 
    return value ?.toFixed(0) ?.replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
  else if (Number(value) < 1 && Number(value) >= 9e-4) 
    return value?.toFixed(4);
    else if (Number(value) < 9e-4 && Number(value) >= 9e-7) 
    return value?.toFixed(7);
  else return value.toFixed(2);
};

