import styled from "styled-components";
import { ButtonCommon } from "../../../Layout/styled";

export const IDOLaunchContainer = styled.div``;
export const IDOLaunchWrapper = styled.div``;
export const IDOList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;
  & > li {
    width: calc((100% - 72px) / 3);
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--Ido-Cart-Boder);
    background: var(--Stake-Bg);
    transition: all 0.25s linear;
    cursor: pointer;
    &:hover {
      border: 1px solid var(--Ido-Cart-Boder-hover);
    }
  }
  @media screen and (max-width: 1023px) {
    & > li {
      width: calc(50% - 24px);
    }
  }
  @media screen and (max-width: 550px) {
    flex-wrap: wrap;
    // overflow-x: scroll;
    // scrollbar-color: transparent transparent;
    // scrollbar-width: thin;
    // -ms-overflow-style: none;
    /* width */
    & > li {
      width: 100%;
      min-width: 75%;
      &:hover {
        border: 1px solid var(--Ido-Cart-Boder);
      }
    }
  }
  * {
    box-sizing: border-box;
  }
`;
export const IDOImageParent = styled.div`
  position: relative;
  .img-tag {
    border-radius: 8px;
    overflow: hidden;
    img {
      transition: all.3s;
      border-radius: 8px;
      width: 100%;
    }
  }
  .tag-status {
    display: inline-flex;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    background: var(--Spring-Green-900, #0a5d37);
    position: absolute;
    top: 8px;
    right: 10px;
    h6 {
      color: var(--Spring-Green-500, #0ff586);
      text-align: center;
      font-family: Syne;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 16.5px */
    }
  }
`;
export const IDOTag = styled.div<{ colorBg?: any; colorText?: any }>`
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: ${({ colorBg }) => (colorBg ? colorBg : "#1f4c40")};
  p {
    color: ${({ colorText }) =>
      colorText ? `${colorText} !important` : "#83d3b0 !important"};
    text-align: center;
    font-family: Syne;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 16.5px */
  }
`;

export const IDOBody = styled.div`
  position: relative;
  margin-top: 10px;
  &.light {
    h2 {
      color: #43424a;
    }
  }
  h2 {
    color: #fff;
    text-align: center;
    font-family: Dirtyline;
    font-size: 16px;
    font-weight: 400;
    white-space: pre-line;
    width: max-content;
    text-transform: lowercase;
    text-rendering: optimizeLegibility;
    margin: 5px 0;
  }
  p {
    color: var(--Neutral-400, #92929e);
    font-family: Syne;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 15px */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  span {
    color: var(--Neutral-400, #92929e);
    font-family: Syne;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    text-transform: uppercase;
  }
`;

export const Des = styled.p`
  overflow: hidden;
  color: var(--Neutral-400, #92929e);
  text-overflow: ellipsis;
  font-family: Syne;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 13.75px */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2 !important;
  align-self: stretch;
`;

export const IDOReward = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  &.light {
    background: #f6f6f6;
  }
  background: #000;
  padding: 8px;
  margin: 10px 0;
  margin-bottom: 10px;
  flex-direction: column;
  gap: 6px;
  position: relative;
  overflow: hidden;
`;

export const LaunchpadBody = styled.div`
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;
export const ProgressTitles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &.light {
    h3,
    p {
      color: #43424a;
    }
  }
  &.light {
    p {
      color: #fff;
    }
  }
  p {
    color: var(--Neutral-600, #eeeef0);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    font-family: DMMono-500;
  }
  h3 {
    color: var(--Neutral-100, #eeeef0);
    font-family: Syne;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 15px */
  }
  h4 {
    width: fit-content;
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    &.light {
      background: #00ff111a;
      color: #00341d;
    }
    background: var(--Green-900, #1f4c40);
    color: var(--Green-300, #83d3b0);
    text-align: center;
    font-family: DMMono-400;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 16.5px */
  }
`;

export const TextContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  h6 {
    color: var(--Neutral-400, #92929e);
    font-family: Syne;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }
  p {
    &.light {
      color: #43424a;
    }
    color: var(--Neutral-50, #f7f7f8);
    font-family: DMMono-500;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
  }
  &.light {
    p {
      color: #43424a;
    }
  }
  div {
    display: flex;
    align-items: center;
    gap: 4px;
    span {
      display: flex;
      padding: 6.365px 6.474px 4.598px 5.489px;
      justify-content: center;
      align-items: center;
      border-radius: 4.493px;
      border: 0.749px solid var(--Neutral-700, #4d4d57);
      &.light {
        background: #eeeef0;
        border: 0.749px solid var(--Neutral-300, #b8b8c1);
        color: #3a3a40;
      }
      background: var(--Neutral-800, #43424a);
      color: var(--Neutral-100, #eeeef0);
      text-align: center;
      font-family: DMMono-400;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%; /* 12.5px */
      aspect-ratio: 1;
      width: unset;
      align-self: stretch;
    }
  }
`;

export const ProgressBar = styled.div<{ progress?: any }>`
  border-radius: 2.576px;
  background: rgba(105, 105, 105, 0.25);
  height: 7px;
  align-self: stretch;
  width: 100%;
  position: relative;
  &::after {
    content: "";
    height: 100%;
    width: ${({ progress }) => (progress ? progress > 100 ? 100 : progress : 0)}%;
    border-radius: 2.576px;
    background: linear-gradient(90deg, rgba(0, 187, 255, 0) 0%, #e84142 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &::before {
    width: 13.315px;
    height: 13.315px;
    border-radius: 44.382px;
    background: var(--Blue--Pri-700, #b32222);
    filter: blur(10.096933364868164px);
    content: "";
    position: absolute;
    left: ${({ progress }) => (progress ? progress > 100 ? 100 : progress : 0)}%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: ${({ progress }) => (progress ? progress / 100 : 0)};
  }
  &.light {
    background: var(--Neutral-200, rgba(217, 217, 222, 1));
    &::after {
      background: linear-gradient(90deg, rgba(0, 187, 255, 0) 0%, #ff7b7b 100%);
    }
    &::before {
      background: var(--Blue--Pri-700, #f50000);
    }
  }
`;
export const TotalRai = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--Neutral-50, #f7f7f8);
  text-align: right;
  font-family: DMMono-400;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 15px */
  span {
    color: var(--Neutral-500, #6e6e7c);
    font-family: DMMono-400;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
  }
  &.light {
    span {
      color: #43424a !important;
    }
  }
`;
export const PriceBox = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 6px;
  &.light {
    background: #fff;
    color: #43424a;
  }
  background: var(--Neutral-950, #141518);
  color: var(--Neutral-400, #92929e);
  text-align: center;
  font-family: DMMono-400;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 15px */
`;
export const LaunchpadListEmpty = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 50px 30px;
  margin-bottom: 20px;
  & > span {
    margin-bottom: 20px;
    svg {
      width: 100px;
      height: 100px;
    }
  }
  p {
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
  }
`;

export const ClaimContainer = styled.div`
  position: relative;
`;

export const WrapTBClaimSchedule = styled.div`
  position: relative;
  border-radius: 12px;
  border: 1px solid var(--Neutral-800, #43424a);
  background: #1c1c1e;
  overflow-x: scroll;
  &.light {
    background: #f3f4f8;
    border: 1px solid var(--Neutral-800, #bdbcc7);
  }
  @media screen and (max-width: 767px) {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
`;

export const ClaimSchedule = styled.div`
  overflow-y: scroll;
  width: 475px;
`;

export const ClaimScheduleHeader = styled.div`
  position: relative;
  background: #2c2c2c;
  display: flex;
  padding: 22px 24px 12px 24px;
  align-items: center;
  gap: 37px;
  p {
    color: var(--Neutral-50, #f7f7f8);
    font-family: DMMono-500;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
    letter-spacing: -1.08px;
    &:nth-child(2) {
      margin-right: 25px;
    }
    &:nth-child(1) {
      margin-right: 0px;
    }
  }
  &.light {
    background: #d9d9de;
    p {
      color: #43424a;
    }
  }
  @media screen and (max-width: 767px) {
    padding-left: 15px;
  }
`;

export const WrapClaimSchedule = styled.div`
  overflow: scroll;
  max-height: 230px;
  .img-nodata {
    max-width: 200px;
    mix-blend-mode: luminosity;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
`;

export const ClaimScheduleRow = styled.div`
  display: flex;
  padding: 12px 24px;
  align-items: center;
  align-self: stretch;
  p {
    color: var(--Neutral-200, #d9d9de);
    font-family: DMMono-400;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.96px;
    white-space: nowrap;
    &:nth-child(1) {
      margin-right: 25px;
    }
    &:nth-child(2) {
      margin-right: 25px;
    }
    &:nth-child(3) {
      margin-right: 25px;
    }
  }
  &.light {
    p {
      color: #43424a;
    }
  }
  ${ButtonCommon} {
    padding: 5px;
    max-width: 120px;
    margin: 0 auto;
  }
  @media screen and (max-width: 767px) {
    padding-left: 15px;
  }
`;

export const WrapVesting = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: #0b0b0c;
  margin-top: 12px;
  h1 {
    color: #fff;
    font-size: 21px;
    line-height: 21px;
    font-family: DirtyLine;
    text-transform: lowercase;
  }
  &.light {
    background: #d9d9de;
    h1 {
      color: #0b0b0c;
    }
  }
`;

export const ListVesting = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ItemVesting = styled.div`
  position: relative;
  border-radius: 12px;
  background: #24252a;
  height: 94px;
  width: calc(100% - 24px);
  padding: 15px;
  &.light {
    background: #f3f4f8;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 70px;
  }
`;

export const ItemVestingRow = styled.div`
  display: flex;
  align-items: center;
  .img-icon-title {
    max-width: 16px;
    margin-right: 3px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .img-icon-token {
    max-width: 20px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
  h2 {
    color: #fff;
    font-family: Syne;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 96%; /* 11.52px */
    white-space: nowrap;
  }
  p {
    color: #fff;
    font-family: DMMono-400;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 96%; /* 15.36px */
    letter-spacing: -1.28px;
    text-transform: lowercase;
    white-space: nowrap;
  }
  &:nth-child(1) {
    margin-bottom: 20px;
  }
  &.light {
    p,
    h2 {
      color: #0b0b0c;
    }
  }
  @media screen and (max-width: 768px) {
    &:nth-child(1) {
      margin-bottom: 10px;
    }
  }
`;
